import { Button, Input, Modal, Textarea } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useUpdateLocation } from '@hooks/locations';
import { Location, LocationForm } from '@hooks/locations/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export type CreateEditLocationModalProps = {
  location: Location | undefined;
  locationsData: Location[] | [];
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const CreateEditLocationModal: React.FC<
  CreateEditLocationModalProps
> = ({
  location,
  locationsData,
  isModalOpen,
  handleModalClose,
  handleSuccess,
}) => {
  const { addToast } = useUIStore();
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const updateLocation = useUpdateLocation(location?.id);
  const [filteredLocations, setFilteredLocations] = useState<Location[]>([]);

  useEffect(() => {
    setFilteredLocations(locationsData.filter((l) => l?.id !== location?.id));
  }, [location, locationsData]);

  const handleSubmit = async (
    formData: LocationForm,
    actions: FormikHelpers<{ name: string; address: string }>
  ) => {
    if (!location?.id) {
      navigate(`/accounts/locations/assign-people`, {
        state: { formData },
      });
      return;
    }

    try {
      await updateLocation.mutateAsync(formData);
      addToast({
        variant: Variant.Success,
        message: 'We’ve saved your changes',
        closeable: true,
      });
      handleSuccess();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Failed to edit location',
        closeable: true,
      });
    }

    actions.setSubmitting(false);
  };

  const validate = (values: LocationForm) => {
    const errors: Partial<LocationForm> = {};
    const locationExists = filteredLocations.some(
      (l) => l.name.toLowerCase() === values.name?.toLowerCase().trim()
    );

    if (!values.name?.trim()) {
      errors.name = 'Location name is required.';
    }

    if (values.name && values.name.length < 3) {
      errors.name = 'Location name must contain at least three characters.';
    }

    if (locationExists) {
      errors.name =
        'There’s already a location with that name. Try a different name.';
    }

    if (!values.address?.trim()) {
      errors.address = 'Location address is required.';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        name: location?.name || '',
        address: location?.address || '',
      }}
      validate={validate}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {({ errors, dirty, isValid, isSubmitting }) => (
        <Modal
          testId='edit-location-modal'
          headerTxt={location?.id ? 'Edit location' : 'Enter location details'}
          modalClassName={'h-full mt-0'}
          isOpen={isModalOpen}
          size={isTablet || isMobile ? 'small' : 'medium'}
          position={isMobile ? 'bottom' : 'default'}
          showCloseBtn={true}
          onClose={handleModalClose}
          contentClassName="flex !flex-column !items-start !justify-start bg-white"
        >
          <div className="w-full">
            <Form>
              <Field
                className="mb-5"
                label="Location name"
                name="name"
                type="text"
                maxLength="255"
                inputClassName="!text-interfaceColor-100"
                as={Input}
                error={errors.name}
              />
              <Field
                label="Address"
                name="address"
                maxLength="255"
                inputClassName="!text-interfaceColor-100"
                as={Textarea}
                error={ errors.address}
              />
              <div className="mt-5 flex flex-col gap-2 lg:flex-row">
                <Button
                  testId='save-button'
                  type="submit"
                  variant="primary"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  {location?.id ? 'Save changes' : 'Next'}
                </Button>
                <Button
                  testId='cancel-button'
                  type="button"
                  variant="outlineLight"
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
};
