import {
  DEFAULT_PAGE_SIZE,
  LAST_DAYS_SORT_BY,
  SHARE_POINT_USAGE_COLUMNS,
  SHARE_POINT_USAGE_SORT_BY,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { OverlayPage } from '@components/partials/OverlayPage';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';

import { PeopleSortBy } from '@hooks/people/types';
import { useSharePointUsagePreviewQuery } from '@hooks/utilisation';
import { useIsDesktop, useIsMobile } from '@hooks/utils';

import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { SharePointUsageTrendOverTime } from '@pages/Dashboard/tiles';
import { formatDate, useTableData } from '@utils/index';
import { Dropdown } from '@components/ui/Dropdown';
import { TotalSharePointSites } from '@pages/Dashboard/tiles/TotalSharePointSites';
import { ActiveSharePointSites } from '@pages/Dashboard/tiles/ActiveSharePointSites';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;
const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const SharePointUsagePage: React.FC = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const [days, setDays] = useState('30');
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[1].label || ''
  );
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'visits'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const transformedFilterData: FilterData[] = [];
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );

  transformedFilterData.push({
    label: 'Personal Sites',
    name: 'personalSites',
    singleSelect: true,
    options: [{ label: 'Show personal sites', value: 'personalSites' }],
  });

  // table
  const query = useSharePointUsagePreviewQuery({
    days,
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    includePersonalSites: queryParams.filter.includes('personalSites'),
  });

  const getTableData = useTableData(
    query,
    SHARE_POINT_USAGE_COLUMNS,
    createCellValue
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.SHARE_POINT_USAGE_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      days,
      includePersonalSites: queryParams.filter.includes('personalSites'),
    },
    filename: `Clevr360_SharePoint_usage_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
    query.refetch();
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`SharePoint usage`}
    >
      <>
        {!isMobile && (
          <div className="absolute left-[40px] top-[113px]">
            <Dropdown>
              <Dropdown.TextHeader
                classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                label={daysSelectedLabel}
                handleOpen={handleIsDaysDropdownOpen}
              ></Dropdown.TextHeader>
              <Dropdown.List
                className="!z-[999] text-16"
                open={isDaysDropdownOpen}
                align="left"
              >
                {floatingSortDropdownData.map((item, index) => {
                  return (
                    <Dropdown.DefaultItem
                      key={index}
                      item={item}
                      currentIndex={index}
                      selectedIndex={daysSelectedIndex}
                      onChange={() =>
                        handleDropdownDaysOnChange(item.value, index)
                      }
                    ></Dropdown.DefaultItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown>
          </div>
        )}
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <SharePointUsageTrendOverTime
                classNames="col-span-3 lg:col-span-2"
                params={JSON.stringify({ days, dataType: 'sites' })}
              />
              <TotalSharePointSites
                params={JSON.stringify({
                  days,
                  includePersonalSites:
                    queryParams.filter.includes('personalSites'),
                })}
                classNames="col-span-3 lg:col-auto"
              />

              <ActiveSharePointSites
                params={JSON.stringify({
                  days,
                  includePersonalSites:
                    queryParams.filter.includes('personalSites'),
                })}
                classNames="col-span-3 lg:col-auto"
              />
            </>
          )}
        </div>

        <TableWrapper
          testId='share-point-usage-table'
          search={search}
          setSearch={setSearch}
          searchPlaceholder={'Search by site name'}
          columns={SHARE_POINT_USAGE_COLUMNS}
          data={getTableData()}
          sortData={SHARE_POINT_USAGE_SORT_BY}
          filterData={transformedFilterData}
          searchKey="search"
          query={query}
          refetchQuery={query.refetch}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          setPageNumber={setPageNumber}
          isCollapsable={true}
          sliceColumns={2}
          floatingFilterButton={true}
          floatingFilterButtonLeft={!isMobile ? '190px' : ''}
          hasFilterBanner={false}
          isLicensePage={true}
          searchCountStatsLabel="sites"
          filterHeaderText="Filter SharePoint usage"
          isDownloadAvailable={true}
          handleDownload={handleDownloadClick}
        />
      </>
    </OverlayPage>
  );
};

export default SharePointUsagePage;
