import { create } from 'zustand';

type SelectedItemsStore = {
  defaultPreselectedItems: string[];
  newlySelectedItems: string[];
  newlyUnselectedItems: string[];
  allSelectedItems: string[];
  totalItemsPerPage: Record<number, number>;
  selectedCountPerPage: Record<number, number>;
  expandedRows: any[];
  tableDataexpandedRows: React.ReactElement | null;
  expandedRowId: number;
  isVideoFormat: boolean;
  startsAt: Date | null;
  endsAt: Date | null;
  shouldApplyRange: boolean;
  downloadType: string | null;
  isLoadingExportData: boolean;
};

type SelectedItemsStoreActions = {
  setNewlySelectedItems: (items: string[]) => void;
  setNewlyUnselectedItems: (items: string[]) => void;
  setAllSelectedItems: (items: string[]) => void;
  removeAllSelectedItems: (items: string[]) => void;
  setDefaultPreselectedItems: (items: string[]) => void;
  reset: () => void;
  resetAllSelectedItems: () => void;
  removeNewlySelectedItems: (items: string[]) => void;
  removeNewlyUnselectedItems: (items: string[]) => void;
  setTotalItemsPerPage: (currentPage: number, totalItems: number) => void;
  setSelectedCountPerPage: (currentPage: number, selectedNum: number) => void;
  setExpandedRows: (items: any[]) => void;
  setExpandedRowId: (item: number, isVideoFormat: boolean) => void;
  setStartsAt: (value: Date | null) => void;
  setEndsAt: (value: Date | null) => void;
  setShouldApplyRange: (value: boolean) => void;
  setDownloadType: (item: string | null) => void;
  setIsLoadingExportData: (item: boolean) => void;
};

const useTableStore = create<SelectedItemsStore & SelectedItemsStoreActions>(
  (set) => ({
    // Initial state
    startsAt: null,
    endsAt: null,
    expandedRowId: -1,
    isVideoFormat: false,
    shouldApplyRange: false,
    defaultPreselectedItems: [],
    tableDataexpandedRows: null,
    expandedRows: [],
    newlySelectedItems: [],
    newlyUnselectedItems: [],
    allSelectedItems: [],
    totalItemsPerPage: {},
    selectedCountPerPage: {},
    downloadType: null,
    isLoadingExportData: false,

    // Actions with improved logging and state management
    setNewlySelectedItems: (items) =>
      set((state) => {
        const newItems = [
          ...state.newlySelectedItems,
          ...items.filter((i) => !state.newlySelectedItems.includes(i)),
        ];
        
        return {
          newlySelectedItems: newItems
        };
      }),

    setNewlyUnselectedItems: (items) =>
      set((state) => {       
        
        const newItems = [
          ...state.newlyUnselectedItems,
          ...items.filter((i) => !state.newlyUnselectedItems.includes(i)),
        ];
        
        return {
          newlyUnselectedItems: newItems
        };
      }),

    // Update setAllSelectedItems to properly track the state
setAllSelectedItems: (items) =>
  set((state) => {   
    
    // Ensure we don't duplicate items
    const newItems = [
      ...state.allSelectedItems,
      ...items.filter((i) => !state.allSelectedItems.includes(i))
    ];
        
    return {
      allSelectedItems: newItems
    };
  }),
    removeAllSelectedItems: (items) =>
      set((state) => {
                
        return {
          allSelectedItems: state.allSelectedItems.filter((i) => !items.includes(i))
        };
      }),

    removeNewlyUnselectedItems: (items) =>
      set((state) => {
                
        return {
          newlyUnselectedItems: state.newlyUnselectedItems.filter(
            (i) => !items.includes(i)
          )
        };
      }),

    removeNewlySelectedItems: (items) =>
      set((state) => {       
        
        return {
          newlySelectedItems: state.newlySelectedItems.filter(
            (i) => !items.includes(i)
          )
        };
      }),

    setDefaultPreselectedItems: (items) =>
      set(() => {
        return {
          defaultPreselectedItems: [...items]
        };
      }),

    setTotalItemsPerPage: (currentPage, totalItems) =>
      set((state) => ({
        totalItemsPerPage: {
          ...state.totalItemsPerPage,
          [currentPage]: totalItems,
        },
      })),

    setSelectedCountPerPage: (currentPage, selectedNum) =>
      set((state) => ({
        selectedCountPerPage: {
          ...state.selectedCountPerPage,
          [currentPage]: selectedNum,
        },
      })),

      reset: () => {
        set({
          defaultPreselectedItems: [],
          newlySelectedItems: [],
          newlyUnselectedItems: [],
          allSelectedItems: [],
          totalItemsPerPage: {},
          selectedCountPerPage: {},
          expandedRows: [],
          expandedRowId: -1,
          isVideoFormat: false,
          startsAt: null,
          endsAt: null,
          shouldApplyRange: false,
          downloadType: null,
          isLoadingExportData: false,
        });
      },

    setIsLoadingExportData: (value) =>
      set(() => ({
        isLoadingExportData: value,
      })),

    setDownloadType: (value) =>
      set(() => ({
        downloadType: value,
      })),

    setEndsAt: (value) => set({ endsAt: value }),
    setStartsAt: (value) => set({ startsAt: value }),
    resetAllSelectedItems: () => set({ allSelectedItems: [] }),
    setShouldApplyRange: (value) => set({ shouldApplyRange: value }),
    
    setExpandedRows: (items) =>
      set(() => ({
        expandedRows: [...items],
      })),

    setExpandedRowId: (item, isVideoFormat) => {
      set((state) => ({
        expandedRowId: state.expandedRowId === item ? -1 : item,
        isVideoFormat,
      }));
    },
  })
);

export default useTableStore;