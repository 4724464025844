import {
  ACTIVITY_LOCATIONS_TABLE_COLUMNS,
  CALLS_MADE_LOCATIONS_SORT_BY,
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  LAST_DAYS_SORT_BY,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { DashboardIcon } from '@components/ui';
import { Dropdown } from '@components/ui/Dropdown';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { PeopleSortBy } from '@hooks/people/types';
import {
  useActivityLocationsPreviewQuery,
  useActivityPerDayLocationsTrendQuery,
  useTotalActivityLocationsSnapshotQuery,
} from '@hooks/productivity';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import {
  LineChartTickFormat,
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import {
  formatDate,
  formatNumberWithSuffix,
  getTrendData,
  getTrendLabels,
  useTableData,
} from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;
const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const CallsMadeLocationsPage: React.FC = () => {
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const isDesktop = useIsDesktop();
  const [activityPerDay, setActivityPerDay] = useState('allactivities');
  const [totalActivity, setTotalActivity] = useState('allactivities');
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[1].label || ''
  );
  const [days, setDays] = useState('30');
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });
  const isMobile = useIsMobile();

  // line tile
  const {
    data: activityPerDayPeopleTrendData,
    isLoading: isActivityPerDayPeopleLoading,
  } = useActivityPerDayLocationsTrendQuery({
    activity: activityPerDay,
    days,
  });

  const trendData = getTrendData(
    activityPerDayPeopleTrendData?.trend || [],
    +days
  );
  const labels = getTrendLabels(
    activityPerDayPeopleTrendData?.trend || [],
    +days
  );

  // number tile
  const {
    data: totalActivityLocationsDataSnapshot,
    isLoading: isTotalActivityLocationsSnapshotLoading,
  } = useTotalActivityLocationsSnapshotQuery({
    activity: totalActivity,
    days,
  });

  // table
  const query = useActivityLocationsPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    days,
    type: 'locations',
  });
  const getTableData = useTableData(
    query,
    ACTIVITY_LOCATIONS_TABLE_COLUMNS,
    createCellValue
  );

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.ActivityPerDayLocations:
          return {
            activity: activityPerDay,
            days,
          };
        case DashboardTiles.TotalActivityLocations:
          return {
            activity: totalActivity,
            days,
          };
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const activityPerDayMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All activities',
      value: 'allactivities',
      action: () => handleActivityPerDayDropdown('allactivities'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleActivityPerDayDropdown('emails'),
    },
    {
      id: '3',
      label: 'Messages',
      value: 'messages',
      action: () => handleActivityPerDayDropdown('messages'),
    },
    {
      id: '4',
      label: 'Calls',
      value: 'calls',
      action: () => handleActivityPerDayDropdown('calls'),
    },

    {
      id: '5',
      label: 'Meetings',
      value: 'meetings',
      action: () => handleActivityPerDayDropdown('meetings'),
    },
  ];

  const totalActivityMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All activities',
      value: 'allactivities',
      action: () => handleTotalActivityDropdown('allactivities'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleTotalActivityDropdown('emails'),
    },
    {
      id: '3',
      label: 'Messages',
      value: 'messages',
      action: () => handleTotalActivityDropdown('messages'),
    },
    {
      id: '4',
      label: 'Calls',
      value: 'calls',
      action: () => handleTotalActivityDropdown('calls'),
    },

    {
      id: '5',
      label: 'Meetings',
      value: 'meetings',
      action: () => handleTotalActivityDropdown('meetings'),
    },
  ];

  const handleActivityPerDayDropdown = (value: string) => {
    setActivityPerDay(value);
  };

  const handleTotalActivityDropdown = (value: string) => {
    setTotalActivity(value);
  };

  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
  };

  const itemActivitySelected = activityPerDayMenuItems.find(
    (item) => item.value === activityPerDay
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.LOCATIONS_ACTIVITY}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      days,
      type: 'locations',
    },
    filename: `Clevr360_Activity_Locations_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <>
      {!isMobile && (
        <div className="absolute left-[40px] top-[165px]">
          <Dropdown>
            <Dropdown.TextHeader
              classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
              label={daysSelectedLabel}
              handleOpen={handleIsDaysDropdownOpen}
            ></Dropdown.TextHeader>
            <Dropdown.List
              className="!z-[999] text-16"
              open={isDaysDropdownOpen}
              align="left"
            >
              {floatingSortDropdownData.map((item, index) => {
                return (
                  <Dropdown.DefaultItem
                    key={index}
                    item={item}
                    currentIndex={index}
                    selectedIndex={daysSelectedIndex}
                    onChange={() =>
                      handleDropdownDaysOnChange(item.value, index)
                    }
                  ></Dropdown.DefaultItem>
                );
              })}
            </Dropdown.List>
          </Dropdown>
        </div>
      )}
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <LineChartTile
              classNames={styles.lineChartTile}
              sortData={activityPerDayMenuItems}
              initialSelectedItem={itemActivitySelected}
              showCompareDropdown={false}
              legendLabels={[]}
              contextData={createMenuItems(
                DashboardTiles.ActivityPerDayLocations
              )}
              tooltipLabel={[itemActivitySelected?.label || '']}
              headerTitle={'Activity per day'}
              isLoading={isActivityPerDayPeopleLoading}
              labels={labels}
              data={[trendData]}
              compareLabel=""
              buttonLabel=""
              dataSetTypes={[LineChartTypes.Dots]}
              dataSetColors={['#00CF6C']}
              showYTicks={true}
              showXTicks={true}
              isDotted={true}
              chartHeight={150}
              xTickFormat={LineChartTickFormat.Date}
              showDateRange={true}
            />

            <NumberTile
              classNames={'col-span-3 lg:col-auto'}
              sortData={totalActivityMenuItems}
              contextData={createMenuItems(
                DashboardTiles.TotalActivityLocations
              )}
              headerTitle={'Total activity'}
              isLoading={isTotalActivityLocationsSnapshotLoading}
              data={
                totalActivityLocationsDataSnapshot
                  ? [
                      formatNumberWithSuffix(
                        totalActivityLocationsDataSnapshot.value
                      ),
                      totalActivityLocationsDataSnapshot.percentage || 0,
                    ]
                  : []
              }
              percentageLabel=""
              bottomLabel="Emails, messages, calls and meetings"
            />

            <RecommendationCard insightDescription={'activity'} />
          </>
        )}
      </div>
      <TableWrapper
        testId='calls-made-locations-table'
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by location name'}
        columns={ACTIVITY_LOCATIONS_TABLE_COLUMNS}
        data={getTableData()}
        sortData={CALLS_MADE_LOCATIONS_SORT_BY}
        filterData={undefined}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={2}
        floatingFilterButton={false}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="locations"
        filterHeaderText="Filter people activity"
        isDownloadAvailable={true}
        handleDownload={handleDownloadClick}
      />
    </>
  );
};

export default CallsMadeLocationsPage;
