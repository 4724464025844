import { DEFAULT_PAGE_SIZE } from '@common/constants';
import loader from '@assets/gif/loader.gif';

import { Button, Column, Table } from '@components/ui';
import { ArrowRightIcon, DownloadIcon, FilterIcon } from '@components/ui/Icons';

import { Person } from '@hooks/people/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { QueryParams, SetQueryParams } from '@pages/PeopleAndGroups/types';
import { BaseQueryParams, BaseSetQueryParams } from '@store/queryParamsStore';
import useTableStore from '@store/tableStore';
import { formatLastUpdatedDate } from '@utils/index';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  UseQueryResult,
} from 'react-query';
import StickyBox from 'react-sticky-box';
import { FILTER_SELECTED_KEY } from '../../../common/constants';
import { NoResults } from '../NoResults';
import { Filter, FilterData } from './parts/Filter';
import { DownloadModal } from './parts/Modals/DownloadModal';
import { Search } from './parts/Search';
import { Sort, SortData } from './parts/Sort';
import { MobileTableView } from './parts/MobileTableView';
import { DropdownData } from '@components/ui/Dropdown';
import useUIStore from '@store/uiStore';

interface SearchCountStatsProps {
  header: string;
  lastUpdatedDate?: string;
}

const SearchCountStats: React.FC<SearchCountStatsProps> = ({
  header,
  lastUpdatedDate,
}) => {
  return (
    <div className="flex flex-col items-center gap-x-0 gap-y-3 px-4 pt-2 lg:flex-row lg:gap-x-3 xl:flex-row xl:gap-x-6">
      <div>
        <h2 className="text-28 font-normal  text-interfaceColor-100">
          {header}
        </h2>
        {lastUpdatedDate && (
          <span className="mt-[3px] text-16 font-normal text-interfaceColor-80">
            Last updated {formatLastUpdatedDate(lastUpdatedDate)}
          </span>
        )}
      </div>
    </div>
  );
};

interface TableWrapperProps<T extends object> {
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  query: UseQueryResult<any>;
  columns: Column<T>[];
  data: any[];
  sortData?: SortData[];
  filterData?: FilterData[];
  searchKey?: string;
  queryParams?: QueryParams;
  setQueryParams?: SetQueryParams;
  storedQueryParams?: BaseQueryParams;
  setQueryParamsStore?: BaseSetQueryParams;
  searchTag?: string;
  tableHeaderStickyPosition?: number | undefined;
  setPageNumber: (pageNumber: number) => void;
  isCollapsable?: boolean;
  sliceColumns?: number;
  isDownloadAvailable?: boolean;
  showCheckboxes?: boolean;
  showBulkAction?: boolean;
  handleBulkAction?: () => void;
  bulkContent?: React.ReactElement;
  filterByKey?: string;
  filterByData?: string;
  refetchQuery?: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
  floatingFilterButton?: boolean;
  floatingFilterButtonLeft?: string;
  floatingFilterButtonTop?: string;
  hasFilterBanner?: boolean;
  isLicensePage?: boolean;
  searchPlaceholder?: string;
  headerKeyCard?: string;
  searchCountStatsLabel?: string;
  filterHeaderText?: string;
  preselectedFilter?: string[];
  isSimpleTable?: boolean;
  setDropDownTableRow?: (id: string) => void;
  setDropDownAction?: React.Dispatch<React.SetStateAction<string | undefined>>;
  dropDownData?: DropdownData[];
  showDropDownMobile?: boolean;
  isOverlayPage?: boolean;
  customTotalCount?: boolean;
  hasExpandedItems?: boolean;
  isFirstColumnSticky?: boolean;
  hasRangeFilter?: boolean;
  handleDownload?: () => void;
  noResultTitle?: string;
  noResultSubtitle?: string;
  noResultAction?: React.ReactNode;
  subTitle?: string;
  displayAsDropdownFilterItems?: string[];
  disableSearch?: boolean;
  disableCountStats?: boolean;
  displayNoResults?: boolean;
  testId?: string;
}

export const TableWrapper = <T extends object>({
  search,
  setSearch,
  query,
  columns,
  data,
  sortData,
  filterData,
  searchKey,
  queryParams,
  setQueryParams,
  storedQueryParams,
  setQueryParamsStore,
  refetchQuery,
  setPageNumber,
  isCollapsable,
  sliceColumns,
  isDownloadAvailable = true,
  searchTag = '',
  showCheckboxes = false,
  showBulkAction = false,
  customTotalCount = false,
  filterByKey = '',
  filterByData = '',
  handleBulkAction,
  bulkContent,
  floatingFilterButtonLeft,
  floatingFilterButtonTop,
  floatingFilterButton = false,
  hasFilterBanner = true,
  isLicensePage = false,
  searchPlaceholder = 'Search term',
  headerKeyCard = '',
  searchCountStatsLabel = 'people',
  filterHeaderText = 'Filter',
  preselectedFilter = [],
  isSimpleTable = false,
  setDropDownTableRow,
  setDropDownAction,
  dropDownData,
  showDropDownMobile,
  isOverlayPage = true,
  subTitle = '',
  hasExpandedItems = false,
  isFirstColumnSticky = true,
  hasRangeFilter = false,
  noResultTitle = 'No results matching your search',
  noResultSubtitle = 'Try a less specific search.',
  noResultAction,
  handleDownload,
  disableSearch = false,
  disableCountStats = false,
  displayAsDropdownFilterItems = [],
  displayNoResults = false,
  testId = "table"
}: TableWrapperProps<T>) => {
  const lastUpdatedDate = query.data?.lastUpdatedAt;
  const currentPage = query.data?.currentPage || 0;
  const totalPages = query.data?.totalPageCount || 0;
  const startIndex = (currentPage - 1) * DEFAULT_PAGE_SIZE + 1;
  const endIndex = startIndex + (query.data?.items?.length || 0) - 1;
  const totalCount = customTotalCount
    ? endIndex
    : query.data?.totalItemCount || 0;
  const SEARCH_HEIGHT = 50;
  const { overlayHeaderHeight, isAtStickyPositions, headerHeight } =
    useUIStore();

  const isMobile = useIsMobile();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const { setDownloadType } = useTableStore();

  const { startsAt, endsAt } = useTableStore();

  const {
    newlySelectedItems,
    setNewlySelectedItems,
    removeNewlySelectedItems,
    allSelectedItems,
    setAllSelectedItems,
    removeAllSelectedItems,
    defaultPreselectedItems,
    setDefaultPreselectedItems,
    newlyUnselectedItems,
    setNewlyUnselectedItems,
    removeNewlyUnselectedItems,
    selectedCountPerPage,
    setSelectedCountPerPage,
    totalItemsPerPage,
    setTotalItemsPerPage,
  } = useTableStore();

  useEffect(() => {
    if (query.data && filterByKey && filterByData) {
      const filteredData = query.data?.items.filter((item: any) => {
        if (filterByKey === FILTER_SELECTED_KEY.groups) {
          return item.groups?.some((group: any) => group.id === filterByData);
        } else if (filterByKey === FILTER_SELECTED_KEY.location) {
          return item.location?.id === filterByData;
        }
        return false;
      });

      const filteredIds = filteredData?.map((item: any) => item.id);

      if (!totalItemsPerPage[currentPage]) {
        setDefaultPreselectedItems(filteredIds);
        setTotalItemsPerPage(currentPage, query.data.items?.length);
        setAllSelectedItems(filteredIds);
        setSelectedCountPerPage(currentPage, filteredIds.length);
      }
    } else {
      setDefaultPreselectedItems([]);
      setTotalItemsPerPage(currentPage, query?.data?.items?.length || 0);
      setAllSelectedItems([]);
      setSelectedCountPerPage(currentPage, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);



  const handleSelect = (selected: Person | null, all?: boolean) => {
 
    // Handle "Select All" functionality
    if (all) {
      const pageItems = data.map(item => item.id);
      const isAllSelected = pageItems.every(id => allSelectedItems.includes(id));
      
  
      if (isAllSelected) {
        // Unselecting all items
        removeAllSelectedItems(pageItems);
        
        // For preselected items, add to newly unselected
        const preselectedPageItems = pageItems.filter(id => 
          defaultPreselectedItems.includes(id)
        );
        if (preselectedPageItems.length > 0) {
          setNewlyUnselectedItems(preselectedPageItems);
        }
        
        // For newly selected items, remove them
        const newlySelectedPageItems = pageItems.filter(id => 
          newlySelectedItems.includes(id)
        );
        if (newlySelectedPageItems.length > 0) {
          removeNewlySelectedItems(newlySelectedPageItems);
        }
        
        setSelectedCountPerPage(currentPage, 0);
      } else {
        // Selecting all items
        setAllSelectedItems(pageItems);
        
        // For preselected items that were unselected, remove from newly unselected
        const preselectedUnselectedItems = pageItems.filter(id => 
          defaultPreselectedItems.includes(id) && 
          newlyUnselectedItems.includes(id)
        );
        if (preselectedUnselectedItems.length > 0) {
          removeNewlyUnselectedItems(preselectedUnselectedItems);
        }
        
        // For non-preselected items, add to newly selected
        const nonPreselectedItems = pageItems.filter(id => 
          !defaultPreselectedItems.includes(id)
        );
        if (nonPreselectedItems.length > 0) {
          setNewlySelectedItems(nonPreselectedItems);
        }
        
        setSelectedCountPerPage(currentPage, pageItems.length);
      }
      return;
    }
  
    // Handle single item selection
    if (selected) {
      const isPreselected = defaultPreselectedItems.includes(selected.id);
      const isCurrentlySelected = allSelectedItems.includes(selected.id);
  
      // console.log('Single selection:', {
      //   id: selected.id,
      //   isPreselected,
      //   isCurrentlySelected
      // });
  
      if (isCurrentlySelected) {
        // Unselecting an item
        removeAllSelectedItems([selected.id]);
        
        if (isPreselected) {
          setNewlyUnselectedItems([selected.id]);
        } else {
          removeNewlySelectedItems([selected.id]);
        }
        
        setSelectedCountPerPage(currentPage, 
          (selectedCountPerPage[currentPage] || 0) - 1
        );
      } else {
        // Selecting an item
        setAllSelectedItems([selected.id]);
        
        if (isPreselected) {
          removeNewlyUnselectedItems([selected.id]);
        } else {
          setNewlySelectedItems([selected.id]);
        }
        
        setSelectedCountPerPage(currentPage, 
          (selectedCountPerPage[currentPage] || 0) + 1
        );
      }
    }
  };


  const onBulkAction = () => {
    if (handleBulkAction) {
      handleBulkAction();
    }
  };

  const handleDrawerCloseClick = () => {
    setIsDrawerOpen(false);
  };

  const handleDrawerOpenClick = () => {
    setIsDrawerOpen(true);
  };

  const handleDownloadModalOpenClick = () => {
    setIsDownloadModalOpen(true);
  };

  const handleDownloadModalClose = () => {
    setIsDownloadModalOpen(false);
    setDownloadType(null);
  };

  const handleDownloadClick = () => {
    handleDownload && handleDownload();
  };

  const getTableHeaderStickyPosition = () => {
    if (isOverlayPage && overlayHeaderHeight) {
      return overlayHeaderHeight + SEARCH_HEIGHT;
    }
    if (isSimpleTable) {
      return 0;
    }
    if (!isOverlayPage) {
      return isMobile ? headerHeight : SEARCH_HEIGHT;
    }
  };

  const getSearchStickyPosition = () => {
    return overlayHeaderHeight
      ? overlayHeaderHeight
      : isMobile
        ? headerHeight
        : 0;
  };

  const filterCount = queryParams?.filter
    ? queryParams.filter
      .split(',')
      .filter((filter: string) => !preselectedFilter.includes(filter)).length
    : 0;

  const totalFilterCount = filterCount + (startsAt || endsAt ? 1 : 0);

  const getCountStats = (): string => {
    return query.data.items?.length
      ? `${startIndex}-${endIndex} of ${totalCount} ${searchCountStatsLabel}`
      : `${query.data.items.length} of ${totalCount} ${searchCountStatsLabel}`;
  };

  return (
    <>
      <div data-testid={`${testId}-wrapper`}
        className={cn(
          'bg-white md:mx-auto lg:rounded-[4px] lg:shadow-shadow-02',
          { 'mb-10': isAtStickyPositions }
        )}
      >
        {!query.isLoading && !query.isError && !isSimpleTable ? (
          <div className="flex flex-col gap-0">
            <div
              className={cn('flex flex-row items-center justify-between', {
                '!pb-0': subTitle,
              },
                { 'pb-2': !disableCountStats }
              )}
            >
              {!disableCountStats && <SearchCountStats
                lastUpdatedDate={lastUpdatedDate}
                header={getCountStats()}
              ></SearchCountStats>
              }
              {isDownloadAvailable && (
                <Button
                  testId='download-button'
                  variant="default"
                  className="mr-5 mt-2 h-8 pl-2 pr-2"
                  onClick={handleDownloadModalOpenClick}
                >
                  <div className="flex flex-row items-center justify-between">
                    <DownloadIcon classNames={`${!isMobile ? 'mr-1' : ''}`} />
                    <span className="hidden text-16 md:block">
                      Download data
                    </span>
                  </div>
                </Button>
              )}
            </div>

            {subTitle && (
              <span className="pb-4 pl-4 text-16 font-normal text-interfaceColor-100">
                {subTitle}
              </span>
            )}
          </div>
        ) : !query.isLoading &&
          !query.isError &&
          query.data?.items.length &&
          isDownloadAvailable ? (
          <div className="flex justify-end px-0 py-4">
            <Button
              testId='open-download-modal-button'
              variant="default"
              className="mr-5 h-8 pl-2 pr-2"
              onClick={handleDownloadModalOpenClick}
            >
              <div className="flex flex-row items-center justify-between">
                <DownloadIcon classNames={`${!isMobile ? 'mr-1' : ''}`} />
                <span className="hidden text-16 md:block">Download data</span>
              </div>
            </Button>
          </div>
        ) : null}

        {floatingFilterButton && (
          <div
            style={{
              left: floatingFilterButtonLeft
                ? `${floatingFilterButtonLeft}`
                : '',
              top: floatingFilterButtonTop ? `${floatingFilterButtonTop}` : '',
            }}
            className={
              'absolute left-5 top-[115px] text-center md:left-12 md:w-fit md:text-left lg:left-12'
            }
          >
            <Button
              testId='open-drawer-button'
              variant={'dark'}
              className={cn('h-full w-full rounded-3xl border-b')}
              onClick={handleDrawerOpenClick}
            >
              <div className="color-white flex flex-row items-center justify-center">
                <span className="text-16">Filter</span>

                {totalFilterCount > 0 ? (
                  <span className="mx-2 flex h-6 w-6 items-center justify-center rounded-full bg-interfaceColor-80 text-14 text-white">
                    {totalFilterCount}
                  </span>
                ) : null}

                <FilterIcon
                  color={'text-primaryBrandColor-100'}
                  size="4"
                  classNames="ml-2 mt-1"
                />
              </div>
            </Button>
          </div>
        )}

        {!isSimpleTable && (
          <StickyBox
            style={{
              boxShadow:
                !isMobile && isAtStickyPositions && !isOverlayPage
                  ? '0px -20px 7px 7px rgb(248 249 250)'
                  : '',
            }}
            offsetTop={getSearchStickyPosition()}
            className={cn('z-20 bg-white lg:bg-interfaceColor-5', {
              'lg:rounded-tl-[4px]': isAtStickyPositions,
            })}
          >
            <div
              id="sticky-table-actions"
              className={cn('flex flex-wrap md:flex-row md:shadow-none', {
                'lg:rounded-tl-[4px]': isAtStickyPositions,
                'shadow-shadow-06 lg:rounded-tl-[4px]': isAtStickyPositions,
              })}
            >
              {!disableSearch && <div
                className={cn(
                  'w-full rounded-full border-interfaceColor-20 md:w-fit md:flex-1',
                  { 'lg:rounded-tl-[4px]': isAtStickyPositions }
                )}
              >
                <Search
                  search={search || ''}
                  setPageNumber={setPageNumber}
                  setSearch={
                    setSearch ||
                    (undefined as unknown as React.Dispatch<
                      React.SetStateAction<string>
                    >)
                  }
                  queryParams={
                    queryParams || (undefined as unknown as QueryParams)
                  }
                  setQueryParams={
                    setQueryParams || (undefined as unknown as SetQueryParams)
                  }
                  setQueryParamsStore={setQueryParamsStore}
                  searchKey={searchKey || ''}
                  searchTag={searchTag}
                  searchPlaceholder={searchPlaceholder}
                />
              </div>}
              <div
                className={cn(
                  'md:height-[50px] lg:height-[50px]  border-b-[1px] border-r-[1px] text-center md:w-fit md:border-r-0 md:text-left',
                  {
                    'w-full': floatingFilterButton,
                    'w-1/2': !floatingFilterButton,
                    'lg:rounded-tr-[4px]': isAtStickyPositions,
                  }
                )}
              >
                {sortData && queryParams && setQueryParams && (
                  <Sort
                    sortData={sortData}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                    setQueryParamsStore={setQueryParamsStore}
                  />
                )}
              </div>
              {!floatingFilterButton && filterData && (
                <div
                  className={cn('w-1/2 text-center md:w-fit md:text-left', {
                    'absolute top-[90px]': floatingFilterButton,
                  })}
                >
                  <Button
                    testId='handle-open-drawer-button'
                    variant={isMobile ? 'white' : 'dark'}
                    className={cn(
                      'h-full w-full rounded-none border-b border-t-0 md:border-t',
                      {
                        '!rounded-3xl': floatingFilterButton,
                        'lg:rounded-tr-[4px]': isAtStickyPositions,
                      }
                    )}
                    onClick={handleDrawerOpenClick}
                  >
                    <div className="color-white flex flex-row items-center justify-center">
                      <span className="text-16">Filter</span>
                      {totalFilterCount > 0 ? (
                        <span className="mx-2 flex h-6 w-6 items-center justify-center rounded-full bg-interfaceColor-80 text-14 text-white">
                          {totalFilterCount}
                        </span>
                      ) : null}

                      {!floatingFilterButton ? (
                        <ArrowRightIcon
                          color={
                            isMobile ? 'text-interfaceColor-100' : 'text-white'
                          }
                          size="3"
                          classNames="ml-2 mt-1"
                        />
                      ) : (
                        <FilterIcon
                          color={
                            isMobile
                              ? 'text-interfaceColor-100'
                              : 'text-primaryBrandColor-100'
                          }
                          size="4"
                          classNames="ml-2 mt-1"
                        />
                      )}
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </StickyBox>
        )}
        <>
          {query.isLoading && !query.data ? (
            <div className="my-8 flex h-64 flex-col items-center justify-center">
              <img className="h-14 w-14" src={loader} alt="Loading..." />
              <span className="text-interfaceColor-90">Loading</span>
            </div>
          ) : !query.isLoading && (!query.data?.items.length || displayNoResults) ? (
            <NoResults
              headerTxt={noResultTitle}
              contentTxt={noResultSubtitle}
              actionButtons={noResultAction}
            />
          ) : isMobile ? (
            <MobileTableView
              showCheckboxes={showCheckboxes}
              showBulkAction={showBulkAction}
              bulkContent={bulkContent}
              currentPage={currentPage}
              totalPages={totalPages}
              query={query}
              data={data}
              headerKeyCard={headerKeyCard}
              isCollapsable={isCollapsable}
              sliceColumns={sliceColumns}
              setPageNumber={setPageNumber}
              handleSelect={handleSelect}
              handleBulkAction={handleBulkAction}
              setDropDownTableRow={setDropDownTableRow}
              setDropDownAction={setDropDownAction}
              dropDownData={dropDownData}
              showDropDownMobile={showDropDownMobile}
              columns={columns}
            />
          ) : (
            <Table
              testId={testId}
              columns={columns}
              data={data}
              query={query}
              isLoading={query.isLoading}
              startIndex={startIndex}
              endIndex={endIndex}
              totalCount={totalCount}
              currentPage={currentPage}
              totalPages={totalPages}
              setPageNumber={setPageNumber}
              tableHeaderStickyPosition={getTableHeaderStickyPosition()}
              allSelectedItems={allSelectedItems}
              handleSelect={handleSelect}
              showCheckboxes={showCheckboxes}
              handleBulkAction={onBulkAction}
              showBulkAction={showBulkAction}
              bulkContent={bulkContent}
              setDropDownTableRow={setDropDownTableRow}
              setDropDownAction={setDropDownAction}
              dropDownData={dropDownData}
              hasExpandedItems={hasExpandedItems}
              isFirstColumnSticky={isFirstColumnSticky}
            />
          )}
        </>

        {filterData && queryParams && setQueryParams && refetchQuery && (
          <Filter
            testId={`${testId}-filter`}
            storedQueryParams={storedQueryParams?.filter}
            setPageNumber={setPageNumber}
            isDrawerOpen={isDrawerOpen}
            onCloseDrawer={handleDrawerCloseClick}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setQueryParamsStore={setQueryParamsStore}
            filterData={filterData}
            refetchQuery={refetchQuery}
            hasFilterBanner={hasFilterBanner}
            isLicensePage={isLicensePage}
            headerText={filterHeaderText}
            preselectedFilter={preselectedFilter}
            hasRangeFilter={hasRangeFilter}
            displayAsDropdownFilterItems={displayAsDropdownFilterItems}
          />
        )}

        <DownloadModal
          isModalOpen={isDownloadModalOpen}
          handleDownloadModalClose={handleDownloadModalClose}
          handleDownloadClick={handleDownloadClick}
          isSimpleTable={isSimpleTable}
        />
      </div>
    </>
  );
};
