import {
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  LAST_DAYS_SORT_BY,
  LICENSES_USAGE_SORT_BY,
  LICENSES_USAGE_TABLE_COLUMNS,
  VENDORS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { DashboardIcon } from '@components/ui';
import { Dropdown } from '@components/ui/Dropdown';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { useGroupsFilterQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import {
  useLicencesFiltersQuery,
  useLicencesUsageCallTimeQuery,
  useLicencesUsageCorrespondenceQuery,
  useLicencesUsageTrendQuery,
  useLicensesUsagePreviewQuery,
} from '@hooks/licenses';
import {
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { useLocationsQuery } from '@hooks/locations';
import { Location } from '@hooks/locations/types';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import {
  calculateTotalSeconds,
  formatDate,
  formatNumberWithSuffix,
  getFilterValues,
  getTrendData,
  getTrendLabels,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';

const pageSize = DEFAULT_PAGE_SIZE;
const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const ServiceUsagePage: React.FC = () => {
  const [days, setDays] = useState('7');
  const [serviceType, setServiceType] = useState('messages');
  const [trendServiceType, setTrendServiceType] = useState('messaging');
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[0].label || ''
  );
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(0);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, 'desc'),
    filter: withDefault(StringParam, ''),
  });
  const { data: filterData, isFetched: filterDataFetched } =
    useLicencesFiltersQuery(
      'true',
      queryParams.filter.includes('freeLicenses')
    );
  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/adoption');
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();
  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  const propertyLabels: Record<string, string> = {
    licensetype: 'License Type',
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Messaging',
      value: 'messaging',
      action: () => handleDropdown('messaging'),
    },
    {
      id: '2',
      label: 'Email',
      value: 'email',
      action: () => handleDropdown('email'),
    },
    {
      id: '3',
      label: 'Video calls',
      value: 'videocalls',
      action: () => handleDropdown('videocalls'),
    },
    {
      id: '4',
      label: 'Phone calls',
      value: 'phonecalls',
      action: () => handleDropdown('phonecalls'),
    },
    {
      id: '5',
      label: 'File storage',
      value: 'filestorage',
      action: () => handleDropdown('filestorage'),
    },
  ];

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  if (filterDataFetched && locationDataFetched && groupsDataFetched) {
    if (filterData) {
      const visibleProps = ['licenseType'];
      visibleProps.forEach((property) => {
        const filterDataArray: FilterData = {
          label: getPropertyLabel(property),
          name: property.toLowerCase(),
          options: [],
        };

        const propertyData =
          filterData[property as keyof LicensesFiltersResponse];

        const items = propertyData as unknown as LicensesFilterData[];
        items.forEach((item) => {
          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
          };

          filterDataArray.options.push(option);
        });

        filterDataArray.options.forEach((option) => {
          const matchingVendor = filterData.licenseType.find(
            (v) => v.value === option.value
          );

          if (matchingVendor) {
            option.vendor = matchingVendor.vendor;
          }
        });

        transformedFilterData.push(filterDataArray);
      });
    }

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }

    transformedFilterData.push({
      label: 'Free licences',
      name: 'freeLicenses',
      singleSelect: true,
      options: [{ label: 'Show free licenses', value: 'freeLicenses' }],
    });
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  // table
  const query = useLicensesUsagePreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    licenseTypeList: getFilterValues(
      queryParams,
      (filterData?.licenseType?.map((item) => item.value) || []).filter(
        (item): item is string => item !== undefined
      )
    ),
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
  });
  const getTableData = useTableData(
    query,
    LICENSES_USAGE_TABLE_COLUMNS,
    createCellValue
  );

  // line chart
  const {
    data: licencesUsageTrendData,
    isLoading: isLicencesUsageTrendDataLoading,
  } = useLicencesUsageTrendQuery({
    days,
    serviceType: trendServiceType,
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    licenseTypeList: getFilterValues(
      queryParams,
      (filterData?.licenseType?.map((item) => item.value) || []).filter(
        (item): item is string => item !== undefined
      )
    ),
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
  });

  // doughnut chart
  const { data: licencesCallTimeData, isLoading: isLicencesCallTimeLoading } =
    useLicencesUsageCallTimeQuery({
      days,
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      licenseTypeList: getFilterValues(
        queryParams,
        (filterData?.licenseType?.map((item) => item.value) || []).filter(
          (item): item is string => item !== undefined
        )
      ),
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    });

  // number chart
  const {
    data: licencesCorrespondenceData,
    isLoading: isLicencesCorrespondenceLoading,
  } = useLicencesUsageCorrespondenceQuery({
    days,
    serviceType,
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    licenseTypeList: getFilterValues(
      queryParams,
      (filterData?.licenseType?.map((item) => item.value) || []).filter(
        (item): item is string => item !== undefined
      )
    ),
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
  });

  const handleLicenseCorrespondenceDropdown = (value: string) => {
    setServiceType(value);
  };

  const labels = getTrendLabels(licencesUsageTrendData?.trend || [], +days);

  const compareAgainstTrendData = getTrendData(
    licencesUsageTrendData?.compareAgainst || [],
    +days
  );

  const trendData = getTrendData(licencesUsageTrendData?.trend || [], +days);

  const handleDropdown = (value: string) => {
    setTrendServiceType(value);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
  };

  const handleIsDaysDropdowmnOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const commonParameters = {
      days,
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      licenseTypeList: getFilterValues(
        queryParams,
        (filterData?.licenseType?.map((item) => item.value) || []).filter(
          (item): item is string => item !== undefined
        )
      ),
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      serviceType:
        type === DashboardTiles.ServiceUsageMessagesEmailsSent
          ? serviceType
          : trendServiceType,
    };

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(commonParameters),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const createSortEmailsMenuItems = (): MenuItem[] => [
    {
      id: '1',
      label: 'Messages',
      action: () => handleLicenseCorrespondenceDropdown('messages'),
    },
    {
      id: '2',
      label: 'Emails',
      action: () => handleLicenseCorrespondenceDropdown('emails'),
    },
  ];

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.LICENSES_USAGE_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      licenseTypeList: getFilterValues(
        queryParams,
        (filterData?.licenseType?.map((item) => item.value) || []).filter(
          (item): item is string => item !== undefined
        )
      ),
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    },
    filename: `Clevr360_Service_usage_${formatDate(new Date().toString())}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Service usage`}
    >
      <>
        {!isMobile && (
          <div className="absolute left-[40px] top-[113px]">
            <Dropdown>
              <Dropdown.TextHeader
                classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                label={daysSelectedLabel}
                handleOpen={handleIsDaysDropdowmnOpen}
              ></Dropdown.TextHeader>
              <Dropdown.List
                className="!z-[999] text-16"
                open={isDaysDropdownOpen}
                align="left"
              >
                {floatingSortDropdownData.map((item, index) => {
                  return (
                    <Dropdown.DefaultItem
                      key={index}
                      item={item}
                      currentIndex={index}
                      selectedIndex={daysSelectedIndex}
                      onChange={() =>
                        handleDropdownDaysOnChange(item.value, index)
                      }
                    ></Dropdown.DefaultItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown>
          </div>
        )}

        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <LineChartTile
                classNames={styles.lineChartTile}
                sortData={sortMenuItems}
                initialSelectedItem={sortMenuItems.find(
                  (item) => item.value === trendServiceType
                )}
                showCompareDropdown={false}
                legendLabels={['Microsoft']}
                contextData={createMenuItems(
                  DashboardTiles.ServiceUsageTrendOverTimeSnapshot
                )}
                tooltipLabel={['Microsoft']}
                headerTitle={'Service usage: Trend over time'}
                isLoading={isLicencesUsageTrendDataLoading}
                labels={labels}
                data={[trendData, compareAgainstTrendData]}
                compareLabel=""
                buttonLabel=""
                dataSetTypes={[LineChartTypes.Dots]}
                dataSetColors={['#00CF6C']}
                showDateRange={true}
              />

              <DoughnutChartTile
                classNames={styles.doughnutChartTile}
                showBracketLabelValue={true}
                contextData={[]}
                headerTitle={'Service usage: Avg time spent on calls / person'}
                isLoading={isLicencesCallTimeLoading}
                data={
                  licencesCallTimeData
                    ? [
                        calculateTotalSeconds(licencesCallTimeData.phoneCalls),
                        calculateTotalSeconds(licencesCallTimeData.videoCalls),
                      ]
                    : []
                }
                dataLabels={['Phone calls', 'Video calls']}
                dataLabelValues={
                  licencesCallTimeData
                    ? [
                        licencesCallTimeData.phoneCalls,
                        licencesCallTimeData.videoCalls,
                      ]
                    : []
                }
              />

              <NumberTile
                sortData={createSortEmailsMenuItems()}
                contextData={createMenuItems(
                  DashboardTiles.ServiceUsageMessagesEmailsSent
                )}
                headerTitle={'Service usage: Messages / emails sent'}
                isLoading={isLicencesCorrespondenceLoading}
                data={
                  licencesCorrespondenceData
                    ? [
                        formatNumberWithSuffix(
                          licencesCorrespondenceData.count
                        ),
                        licencesCorrespondenceData.percentage,
                      ]
                    : []
                }
                buttonLabel=""
                percentageLabel=""
              />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            testId='service-usage-table'
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by license type, name, email address'}
            columns={LICENSES_USAGE_TABLE_COLUMNS}
            data={getTableData()}
            sortData={LICENSES_USAGE_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={2}
            floatingFilterButton={true}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="licenses"
            filterHeaderText="Filter service usage"
            floatingFilterButtonLeft={!isMobile ? '190px' : ''}
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default ServiceUsagePage;
