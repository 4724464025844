import React, { useEffect, useState } from 'react';
import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useReportPdfUrlPreviewQuery, useReportPreviewQuery } from '@hooks/reports';
import { hasPermission } from '@utils/index';
import useAuthStore from '@store/authStore';
import { UserRoles, Permissions } from '@hooks/users/types';
import { DownloadIcon, Loader } from '@components/ui';
import { OverlayPage } from '@components/partials';
import { ReportChartData } from '@hooks/reports/types';
import axios from 'axios';
import dayjs from 'dayjs';
import useUIStore from '@store/uiStore';
import { Variant } from '@components/ui/Toast';

// Define the types for the recommendation data
interface KeyRecommendationRow {
    Vendor: string;
    RecommendationName: string;
    RecommendationSeverity: string;
    RecommendationArea: string | null;
    AnalystNote: string | null;
    RecommendationSource: string;
    RecommendationType: string;
    Description: string;
    ImplementationProgress: string | null;
    AffectedService: string | null;
    UKCyberEssentialsReference: string | null;
    RelevantAccounts: string;
}

interface KeyRecommendationParameters {
    Rows?: Array<Array<KeyRecommendationRow>>;
}

interface ComponentKeyParameter {
    key: string;
    parameters: KeyRecommendationParameters | Record<string, any>;
}

interface ChartComponent {
    type: number;
    keyParameters: ComponentKeyParameter[];
    dropDowns: null;
}

export const SecurityReportLayout: React.FC = () => {
    const [pdfUrl, setPdfUrl] = useState('');
    const { reportId: id } = useParams<{ reportId: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useReportPreviewQuery({
        reportId: id,
    });

    const { mutateAsync, isLoading: isPdfUrlLoading } =
        useReportPdfUrlPreviewQuery(id);

    let chartData: ReportChartData | null = null;
    let hasKeyRecommendations = false;

    if (query.isFetched && query.data?.jsonReportData) {
        try {
            chartData = JSON.parse(query.data?.jsonReportData);
            
            // Find the Key Recommendations component
            const keyRecommendationsComponent = chartData?.components?.find(
                component => component.type === 97 && 
                component.keyParameters?.some(param => param.key === 'Key Recommendations')
            );

            // Get the recommendations data
            const recommendationsData = keyRecommendationsComponent?.keyParameters?.find(
                param => param.key === 'Key Recommendations'
            )?.parameters as KeyRecommendationParameters;

            hasKeyRecommendations = Boolean(
                recommendationsData?.Rows && 
                recommendationsData.Rows.length > 0
            );

        } catch (error) {
            console.error('Error parsing report data:', error);
        }
    }

    // Effect to handle redirection if on key-recommendations route but no recommendations exist
    useEffect(() => {
        const currentPath = location.pathname;
        const isOnKeyRecommendations = currentPath.endsWith('/key-recommendations');
        
        if (query.isFetched && isOnKeyRecommendations && !hasKeyRecommendations) {
            // Redirect to secure-score tab if no recommendations
            navigate(`/reports/security/${id}/preview/secure-score`, {
                state: { 
                    from: 'key-recommendations',
                    timestamp: new Date().getTime()
                },
                replace: true
            });
        }
    }, [hasKeyRecommendations, location.pathname, query.isFetched, id, navigate]);

    useEffect(() => {
        getPdfUrl();
    }, []);

    const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
    const downloadHasPermission = hasPermission(
        userRole,
        Permissions.ADMINS_SUPPORTUSER
    );

    const { addToast, setSidebar } = useUIStore();

    const getPdfUrl = async () => {
        if (downloadHasPermission) {
            const res = await mutateAsync();
            setPdfUrl(res as unknown as string);
        }
    };

    const downloadFile = async () => {
        try {
            const date = query.data?.name.substring(0, 11);
            const reportName = query.data?.name.substring(12).trim() ?? 'report';

            const name = reportName
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join('');

            const response = await axios({
                url: pdfUrl,
                method: 'GET',
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Clevr360_${name}_${dayjs(date, 'MMM DD YYYY').format(
                    'DD-MMM-YYYY'
                )}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            addToast({
                variant: Variant.Error,
                message: `Failed to download the file: ${error}`,
            });
        }
    };

    const baseTabs: TabType[] = [
        {
            path: `/reports/security/${id}/preview/secure-score`,
            label: 'Microsoft Secure Score',            
        },
        {
            path: `/reports/security/${id}/preview/suspicious-activity`,
            label: 'Suspicious activity',
        },
        {
            path: `/reports/security/${id}/preview/login-security`,
            label: 'Login security',
        },
        {
            path: `/reports/security/${id}/preview/mailboxes`,
            label: 'Mailboxes',
        },
        {
            path: `/reports/security/${id}/preview/browser-os-versions`,
            label: 'Browser and OS versions',
        },
    ];

    // Add Key Recommendations tab only if there are recommendations
    const tabs: TabType[] = hasKeyRecommendations 
        ? [
            {
                path: `/reports/security/${id}/preview/key-recommendations`,
                label: 'Key recommendations',
            },
            ...baseTabs
          ]
        : baseTabs;

    return (
        <OverlayPage
            isFooterVisible={false}
            path="/reports"
            headerTitle={query.data?.name || ''}
            isLoading={query.isLoading}
        >
            {query.isLoading ? (
                <Loader />
            ) : (
                <>
                    {downloadHasPermission && chartData?.hasPdf && 
                        (
                            <button
                                className="inline-flex flex-shrink-0 items-center justify-center rounded-[24px] bg-interfaceColor-90 px-[16px] py-[10px] text-16 font-normal leading-6 tracking-[0.01em] text-white transition duration-300 ease-in-out hover:bg-interfaceColor-150 hover:text-interfaceColor-10 focus:shadow-focus disabled:bg-interfaceColor-20 disabled:text-interfaceColor-80"
                                onClick={() => downloadFile()}
                            >
                                <DownloadIcon
                                    classNames="mr-2"
                                    color="text-primaryBrandColor-150"
                                />
                                Download report
                            </button>
                        )}

                    <div className="mt-[5px]">
                        <Tab tabs={tabs} className={'mb-[0px]'} />
                    </div>
                </>
            )}
        </OverlayPage>
    );
};