import cn from 'classnames';
import React, { ReactNode, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import closeIcon from '@assets/images/close_icon.png';
import { ReactPortal } from '../helpers/Portal';
import './styles.css';

interface ModalProps {
  children: ReactNode;
  headerTxt?: string | React.ReactNode;
  size?: 'large' | 'medium' | 'small';
  position?: 'default' | 'bottom';
  emptyContent?: boolean;
  showCloseBtn?: boolean;
  actionButtons?: React.ReactNode;
  modalClassName?: string;
  contentClassName?: string;
  isOpen: boolean;
  onClose: () => void;
  isTruncatedHeader?: boolean;
  closeOnClickOutside?: boolean;
  headerImageUrl?: string | undefined;
  isFullHeightMobile?: boolean;
  testId?: string;
}

const modalClasses = {
  root: 'modal flex flex-col bg-white z-40',
  small:
    'w-screen max-h-512px md:w-[480px] md:h-auto md:rounded-lg md:shadow-shadow-05 lg:w-[480px]',
  medium:
    'w-full h-full md:w-[640px] md:h-auto mx-auto md:rounded-lg md:shadow-shadow-05 lg:w-[640px]',
  large:
    'w-full h-full md:w-[640px] md:h-auto mx-auto md:rounded-lg md:shadow-shadow-05 md:shadow-shadow-05 lg:w-[912px]',
  positionBottom: 'md:hidden lg:hidden mt-auto',
};

const contentClasses = {
  root: 'mb-5 ml-5 mr-5 mt-0 flex min-h-[100px] flex-grow items-center justify-center rounded bg-interfaceColor-10 lg:ml-10 lg:mr-10',
  small: '',
  medium: '',
  large: '',
};

export const Modal: React.FC<ModalProps> = ({
  children,
  headerTxt,
  size = 'small',
  position = 'default',
  emptyContent = false,
  showCloseBtn = true,
  actionButtons,
  modalClassName,
  contentClassName,
  isOpen,
  onClose,
  isTruncatedHeader = true,
  closeOnClickOutside = true,
  headerImageUrl,
  isFullHeightMobile,
  testId = "modal"
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === 'Escape' ? onClose() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [isOpen, onClose]);

  const modalClassesName = cn(
    modalClasses.root,
    {
      [modalClasses.small]: size === 'small',
      [modalClasses.medium]: size === 'medium',
      [modalClasses.large]: size === 'large',
      [modalClasses.positionBottom]: position === 'bottom',
      'mt-[120px] h-[calc(100%-120px)] !bg-white': isFullHeightMobile,
    },
    modalClassName
  );

  const contentClassesName = cn(
    contentClasses.root,
    {
      [contentClasses.small]: size === 'small',
      [contentClasses.medium]: size === 'medium',
      [contentClasses.large]: size === 'large',
    },
    contentClassName
  );

  useEffect(() => {
    if (!closeOnClickOutside) return;

    if (closeOnClickOutside) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          nodeRef.current &&
          !nodeRef.current.contains(event.target as Node)
        ) {
          onClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [onClose, closeOnClickOutside]);

  return (
    <ReactPortal wrapperId="modal-container" data-testid={testId}>
      <CSSTransition
        role="dialog"
        key="popup"
        in={isOpen}
        timeout={{ enter: 0, exit: 300 }}
        unmountOnExit
        classNames="modal"
        nodeRef={nodeRef}
      >
        <div
          className={`wrapper w-[calc(100vw - (100vw - 100%))] fixed z-[100] flex h-screen w-screen items-center justify-center overflow-hidden md:absolute ${
            position === 'bottom' ? 'pt-0' : 'p-6'
          }`}
        >
          <div ref={nodeRef} className={modalClassesName}>
            <div className={`flex h-full flex-col`}>
              {position === 'bottom' && emptyContent === true ? (
                <div>{children}</div>
              ) : (
                <>
                  <div
                    className={`flex items-start justify-between p-5 md:gap-x-18 lg:pl-10 lg:pr-10 lg:pt-10 ${
                      isTruncatedHeader ? 'truncate-header-modal' : ''
                    }`}
                  >
                    {headerImageUrl && (
                      <img src={headerImageUrl} className="h-[48px]" />
                    )}

                    {headerTxt && (
                      <h3
                        className={`relative w-full text-32 leading-[38px] ${
                          isTruncatedHeader
                            ? 'overflow-hidden whitespace-nowrap'
                            : ''
                        }`}
                      >
                        {headerTxt}
                      </h3>
                    )}
                    {showCloseBtn && (
                      <button onClick={onClose}>
                        <img src={closeIcon} alt="Close Sidebar" />
                      </button>
                    )}
                  </div>
                  <div className={contentClassesName}>{children}</div>

                  {actionButtons && (
                    <div className="mt-auto pb-5 pl-5 pr-5 pt-0 lg:pb-10 lg:pl-10 lg:pr-10">
                      {actionButtons}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};
