import {
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  NO_LICENCES_SORT_BY,
  NO_LICENCES_TABLE_COLUMNS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { MenuItem } from '@components/ui/DropdownMenu';
import { DashboardIcon, LightInfoIcon } from '@components/ui/Icons';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { useGroupsFilterQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { useLocationsQuery } from '@hooks/locations';
import { Location } from '@hooks/locations/types';
import { PeopleSortBy } from '@hooks/people/types';
import {
  useNoLicencesPreviewQuery,
  useNoLicencesSnapshotQuery,
  useNoLicencesTrendQuery,
} from '@hooks/security';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { TextTile } from '@pages/InsightsAndAnalytics/tiles/TextTile';
import {
  formatDate,
  getTrendData,
  getTrendLabels,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';

const pageSize = DEFAULT_PAGE_SIZE;

const SignInEnabledPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/assurance');
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'lastuse'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();

  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  if (locationDataFetched && groupsDataFetched) {
    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  // table
  const query = useNoLicencesPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
  });
  const getTableData = useTableData(
    query,
    NO_LICENCES_TABLE_COLUMNS,
    createCellValue
  );

  // snapshot
  const { data: signInEnabledData, isLoading: isSignInEnabledLoading } =
    useNoLicencesSnapshotQuery({
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
    });

  // trend
  const { data: noLicencesTrendData, isLoading: isNoLicencesTrendDataLoading } =
    useNoLicencesTrendQuery({
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
    });

  const labels = getTrendLabels(noLicencesTrendData?.trend || [], 30);

  const compareAgainstTrendData = getTrendData(
    noLicencesTrendData?.compareAgainst || [],
    30
  );

  const trendData = getTrendData(noLicencesTrendData?.trend || [], 30);

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const commonParameters = {
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
    };

    const parametersData = { ...commonParameters };

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.SIGN_IN_ENABLED_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
    },
    filename: `Clevr360_Users_with_sign-in_enabled_but_no_licenses_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Users with sign-in enabled but no licenses`}
    >
      <>
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <NumberTile
                classNames={'col-span-3 lg:col-auto lg:row-start-1'}
                percentageLabel=""
                contextData={[]}
                headerTitle={'Users with sign-in enabled but no licenses'}
                isLoading={isSignInEnabledLoading}
                data={signInEnabledData ? Object.values(signInEnabledData) : []}
              />

              <LineChartTile
                classNames={styles.lineChartTile}
                contextData={[]}
                sortData={[]}
                showCompareDropdown={false}
                showAgainstDropdown={false}
                legendLabels={['Last 30 days', 'Previous 30 days']}
                tooltipLabel={['Users', 'Users']}
                headerTitle={'Activity by users without licenses: last 30 days'}
                isLoading={isNoLicencesTrendDataLoading}
                labels={labels}
                data={[trendData, compareAgainstTrendData]}
                compareLabel=""
                buttonLabel=""
                dataSetTypes={[LineChartTypes.Dots, LineChartTypes.Line]}
                dataSetColors={['#00CF6C', '#3F454B']}
              />

              <RecommendationCard
                insightDescription={
                  'users with sign-in enabled but no licenses'
                }
              />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            testId='no-licenses-table'
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by name, email address'}
            columns={NO_LICENCES_TABLE_COLUMNS}
            data={getTableData()}
            sortData={NO_LICENCES_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={1}
            floatingFilterButton={true}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="people"
            filterHeaderText="Filter users with sign-in enabled but no licenses"
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default SignInEnabledPage;
