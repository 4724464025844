import { Button, Input, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useUpdateUser } from '@hooks/users';
import { User, UserForm, UserRequest } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';

const emailRegex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export type CreateEditUserModalProps = {
  user: User | undefined;
  usersData: User[] | [];
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
  openAccessLevelModalNewUser?: (value: boolean, user: UserForm) => void;
  editYourDetails?: boolean;
};

export const CreateEditUserModal: React.FC<CreateEditUserModalProps> = ({
  user,
  usersData,
  isModalOpen,
  handleModalClose,
  handleSuccess,
  openAccessLevelModalNewUser,
  editYourDetails = false,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const updateUser = useUpdateUser(user?.id);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  useEffect(() => {
    setFilteredUsers(usersData.filter((u) => u.id !== user?.id));
  }, [user, usersData]);

  const openAccessLevelModal = (values: any) => {
    openAccessLevelModalNewUser && openAccessLevelModalNewUser(true, values);
  };

  const handleSubmit = async (
    formData: UserForm,
    actions: FormikHelpers<UserForm>
  ) => {
    if (user?.id) {
      const data: UserRequest = {
        id: user?.id,
        fullName: `${formData.firstName} ${formData.lastName}`,
        phone: formData.phone,
        emailAddress: formData.emailAddress,
      };

      try {
        await updateUser.mutateAsync(data);
        addToast({
          variant: Variant.Success,
          message: editYourDetails
            ? 'We’ve saved your changes.'
            : `We’ve updated ${user?.fullName}’s details.`,
          closeable: true,
        });
        handleSuccess();
      } catch (error) {
        addToast({
          variant: Variant.Error,
          message: editYourDetails
            ? 'Your changes couldn’t be saved. Please try again.'
            : 'Something went wrong, and your changes haven’t been saved.',
          closeable: true,
        });
      }
    }
    actions.setSubmitting(false);
  };

  const validate = (values: UserForm) => {
    const errors: Partial<UserForm> = {};
    const emailExists = filteredUsers.some(
      (u) =>
        u.emailAddress.toLowerCase() ===
        values.emailAddress?.toLowerCase().trim()
    );

    if (!values.emailAddress?.trim()) {
      errors.emailAddress = 'Email address is required.';
    }

    if (!values.firstName?.trim()) {
      errors.firstName = 'First name is required.';
    }

    if (!values.lastName?.trim()) {
      errors.lastName = 'Last name is required.';
    }

    if (
      values.emailAddress?.trim().length > 0 &&
      !emailRegex.test(values.emailAddress)
    ) {
      errors.emailAddress = 'Email address is not valid.';
    }

    if (emailExists) {
      errors.emailAddress =
        'There’s already a user with that email address. Try a different one.';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        emailAddress: user?.emailAddress || '',
        firstName: user?.fullName ? user.fullName.split(' ')[0] : '',
        lastName: user?.fullName ? user.fullName.split(' ')[1] : '',
        phone: user?.phone || '',
      }}
      validate={validate}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {({
        errors,
        touched,
        dirty,
        isValid,
        isSubmitting,
        resetForm,
        values,
      }) => (
        <Modal
          testId='edit-or-add-user-modal'
          headerTxt={`${
            editYourDetails
              ? 'Edit your details'
              : user?.id
              ? `Update ${user?.fullName}’s details`
              : 'Add a Clevr360 user'
          }`}
          modalClassName={'h-full mt-0'}
          isOpen={isModalOpen}
          size={isTablet || isMobile ? 'small' : 'medium'}
          position={isMobile ? 'bottom' : 'default'}
          showCloseBtn={true}
          onClose={() => {
            handleModalClose();
          }}
          contentClassName="flex !flex-column !items-start !justify-start bg-white"
        >
          <div className="w-full">
            <Form>
              <Field
                className="mb-5"
                label="Email address"
                name="emailAddress"
                type="text"
                disabled={user?.id}
                maxLength="255"
                inputClassName="!text-interfaceColor-100"
                as={Input}
                error={touched.emailAddress && errors.emailAddress}
              />
              <div className="flex">
                <div className="w-[50%] pr-2">
                  <Field
                    className="mb-5"
                    label="First name"
                    name="firstName"
                    type="text"
                    maxLength="255"
                    inputClassName="!text-interfaceColor-100"
                    as={Input}
                    error={touched.firstName && errors.firstName}
                  />
                </div>
                <div className="w-[50%] pl-2">
                  <Field
                    className="mb-5"
                    label="Last name"
                    name="lastName"
                    type="text"
                    maxLength="255"
                    inputClassName="!text-interfaceColor-100"
                    as={Input}
                    error={touched.lastName && errors.lastName}
                  />
                </div>
              </div>
              <Field
                className="mb-5"
                label="Phone number (optional)"
                name="phone"
                type="text"
                maxLength="25"
                inputClassName="!text-interfaceColor-100"
                as={Input}
                error={touched.phone && errors.phone}
              />
              <div className="mt-5 flex flex-col gap-2 lg:flex-row">
                <Button
                  testId='save-button'
                  type="submit"
                  variant="primary"
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={() => {
                    if (!user?.id) {
                      openAccessLevelModal(values);
                    }
                  }}
                >
                  {user?.id ? 'Save changes' : 'Next: Access level'}
                </Button>
                <Button
                  testId='cancel-button'
                  variant="outlineLight"
                  type="button"
                  onClick={() => {
                    resetForm();
                    handleModalClose();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
};
