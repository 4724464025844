import {
  DEFAULT_PAGE_SIZE,
  LAST_DAYS_SORT_BY,
  SERVICE_ADOPTION_SORT_BY,
  SERVICE_ADOPTION_TABLE_COLUMNS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';

import {
  adjustRounding,
  formatServiceAdoptionValue,
  useAdoptionPerServiceSnapshotQuery,
  useServiceAdoptionPreviewQuery,
} from '@hooks/adoption';
import { ServiceAdoptionSnapshotResponse } from '@hooks/adoption/types';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { Dropdown } from '@components/ui/Dropdown';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { Location } from '@hooks/locations/types';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;
const floatingSortDropdownData = LAST_DAYS_SORT_BY;
export enum ServiceType {
  PhoneInternal = 'internalcalls',
  PhoneExternal = 'externalcalls',
  Chat = 'messages',
  Video = 'videocalls',
}

export const getDataLabels = (
  snapshotData: ServiceAdoptionSnapshotResponse | undefined
) => {
  return [
    `${
      snapshotData
        ? formatServiceAdoptionValue(
            adjustRounding(Number(snapshotData['notUsingPercentage']))
          )
        : 0
    }% of users have never used one or more services`,
    `${
      snapshotData
        ? formatServiceAdoptionValue(
            adjustRounding(Number(snapshotData['usingPercentage']))
          )
        : 0
    }% of users have used all services`,
  ];
};

const ServiceAdoptionPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [days, setDays] = useState('30');
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[1].label || ''
  );
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/adoption');
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const FILTERS = [
    'nomessaging',
    'nointernalcalls',
    'noexternalcalls',
    'novideocalls',
  ];

  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  if (locationDataFetched && groupsDataFetched) {
    transformedFilterData.push({
      label: 'Inactive people',
      name: 'inactivePeople',
      options: [
        { label: 'Never used messages', value: FILTERS[0] },
        { label: 'Never made internal calls', value: FILTERS[1] },
        { label: 'Never made external calls', value: FILTERS[2] },
        { label: 'Never used video', value: FILTERS[3] },
      ],
    });

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  // table
  const query = useServiceAdoptionPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    days,
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    serviceAdoptionActivity: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
      : [],
  });
  const getTableData = useTableData(
    query,
    SERVICE_ADOPTION_TABLE_COLUMNS,
    createCellValue
  );

  const dataHooks = {
    chat: useAdoptionPerServiceSnapshotQuery({
      days,
      serviceType: ServiceType.Chat,
    }),
    phoneInternal: useAdoptionPerServiceSnapshotQuery({
      days,
      serviceType: ServiceType.PhoneInternal,
    }),
    phoneExternal: useAdoptionPerServiceSnapshotQuery({
      days,
      serviceType: ServiceType.PhoneExternal,
    }),
    video: useAdoptionPerServiceSnapshotQuery({
      days,
      serviceType: ServiceType.Video,
    }),
  };

  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
    query.refetch();
  };

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.SERVICE_ADOPTION}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      days,
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
      serviceAdoptionActivity: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => FILTERS.includes(filter))
        : [],
    },
    filename: `Clevr360_Service_adoption_by_user_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Service adoption by user`}
    >
      <>
        {!isMobile && (
          <div className="absolute left-[40px] top-[113px]">
            <Dropdown>
              <Dropdown.TextHeader
                classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                label={daysSelectedLabel}
                handleOpen={handleIsDaysDropdownOpen}
              ></Dropdown.TextHeader>
              <Dropdown.List
                className="!z-[999] text-16"
                open={isDaysDropdownOpen}
                align="left"
              >
                {floatingSortDropdownData.map((item, index) => {
                  return (
                    <Dropdown.DefaultItem
                      key={index}
                      item={item}
                      currentIndex={index}
                      selectedIndex={daysSelectedIndex}
                      onChange={() =>
                        handleDropdownDaysOnChange(item.value, index)
                      }
                    ></Dropdown.DefaultItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown>
          </div>
        )}
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <DoughnutChartTile
                classNames={styles.doughnutChartTile}
                contextData={[]}
                headerTitle={'Messaging adoption'}
                isLoading={dataHooks.chat.isLoading}
                data={
                  dataHooks.chat.data ? Object.values(dataHooks.chat.data) : []
                }
                dataLabels={getDataLabels(dataHooks.chat.data)}
                showBracketLabelValue={false}
              />

              <DoughnutChartTile
                classNames={styles.doughnutChartTile}
                contextData={[]}
                headerTitle={'Internal phone adoption'}
                isLoading={dataHooks.phoneInternal.isLoading}
                data={
                  dataHooks.phoneInternal.data
                    ? Object.values(dataHooks.phoneInternal.data)
                    : []
                }
                dataLabels={getDataLabels(dataHooks.phoneInternal.data)}
                showBracketLabelValue={false}
              />

              <DoughnutChartTile
                classNames={styles.doughnutChartTile}
                contextData={[]}
                headerTitle={'External phone adoption'}
                isLoading={dataHooks.phoneExternal.isLoading}
                data={
                  dataHooks.phoneExternal.data
                    ? Object.values(dataHooks.phoneExternal.data)
                    : []
                }
                dataLabels={getDataLabels(dataHooks.phoneExternal.data)}
                showBracketLabelValue={false}
              />

              <DoughnutChartTile
                classNames={styles.doughnutChartTile}
                contextData={[]}
                headerTitle={'Video adoption'}
                isLoading={dataHooks.video.isLoading}
                data={
                  dataHooks.video.data
                    ? Object.values(dataHooks.video.data)
                    : []
                }
                dataLabels={getDataLabels(dataHooks.video.data)}
                showBracketLabelValue={false}
              />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            testId='service-adoption-by-user-table'
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by name'}
            columns={SERVICE_ADOPTION_TABLE_COLUMNS}
            data={getTableData()}
            sortData={SERVICE_ADOPTION_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={2}
            floatingFilterButton={true}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="people"
            filterHeaderText="Filter service adoption"
            floatingFilterButtonLeft={!isMobile ? '190px' : ''}
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default ServiceAdoptionPage;
