import { Card, Loader } from '@components/ui';
import {
  HamburgerIcon,
  LineChartIcon,
  LineChartIconUp,
} from '@components/ui/Icons';
import styles from './styles.module.css';
import { DropdownMenu, MenuItem } from '@components/ui/DropdownMenu';
import cn from 'classnames';
import { TileFooter } from '../partials/TileFooter';

export type NumberTileProps = {
  sortData?: MenuItem[];
  compareData?: MenuItem[];
  contextData?: MenuItem[];
  headerTitle: string;
  isLoading: boolean;
  data: (number | string)[];
  buttonLabel?: string;
  percentageLabel?: string;
  handleButtonClick?: () => void;
  classNames?: string;
  dropdownMenuItemClasses?: string;
  initialSelectedItem?: MenuItem;
  initialCompareSelectedItem?: MenuItem;
  bottomLabel?: string;
  isPercentIncreased?: boolean | undefined;
  isPercentage?: boolean;
  isArrowColorReversed?: boolean;
  linkPath?: string;
};

export const NumberTile: React.FC<NumberTileProps> = ({
  sortData,
  compareData,
  headerTitle,
  isLoading = false,
  data = [0],
  contextData,
  percentageLabel = '(last 30 days)',
  buttonLabel = '',
  handleButtonClick,
  classNames,
  dropdownMenuItemClasses,
  initialSelectedItem,
  initialCompareSelectedItem,
  bottomLabel,
  isPercentIncreased = undefined,
  isPercentage = true,
  isArrowColorReversed = false,
  linkPath,
}) => {
  if (isPercentIncreased === undefined && data[1]) {
    isPercentIncreased = +data[1] > 0;
  }

  return (
    <Card classNames={`!w-auto ${classNames}`} data-testid="number-tile">
      <Card.Header classNames={styles.cardHeader}>
        <div className={styles.cardHeaderContainer}>
          <span className="mb-2">{headerTitle}</span>

          {!!contextData?.length && (
            <DropdownMenu
              items={contextData}
              dropdownMenuClasses={dropdownMenuItemClasses}
              buttonProps={{ icon: <HamburgerIcon></HamburgerIcon> }}
            ></DropdownMenu>
          )}
        </div>

        {!!sortData?.length && (
          <DropdownMenu
            items={sortData}
            showSelectedItem={true}
            buttonProps={{
              label: sortData[0]?.label || '',
            }}
            initialSelectedItem={initialSelectedItem || sortData[0]}
          ></DropdownMenu>
        )}
        {compareData && (
          <span className="ml-4">
            <DropdownMenu
              items={compareData}
              showSelectedItem={true}
              buttonProps={{
                label: compareData[0].label,
              }}
              dropdownMenuClasses={'!w-[220px]'}
              initialSelectedItem={initialCompareSelectedItem || compareData[0]}
            ></DropdownMenu>
          </span>
        )}
      </Card.Header>

      <Card.Content classNames={styles.cardContent}>
        {isLoading ? (
          <div className="flex w-full justify-center">
            <Loader />
          </div>
        ) : (
          <div className={styles.cardContainer}>
            <div className={styles.cardDetails}>
              <p className={styles.cardDetailsNumber}>{data[0]}</p>
              {data[1] !== undefined && (
                <>
                  {Number(data[1]) === 0 ? (
                    <p className={styles.cardDetailsPercentageZero}>
                      <span className={styles.zeroLine}></span> 0%{' '}
                      {percentageLabel}
                    </p>
                  ) : (
                    <div className={styles.cardDetailsPercentage}>
                      {isPercentage ? (
                        <>
                          {isPercentIncreased ? (
                            <div className="flex flex-row items-center">
                              <LineChartIconUp
                                color={
                                  isArrowColorReversed
                                    ? 'text-errorColor-100'
                                    : 'text-successColor-100'
                                }
                                size="5"
                              />
                              <span
                                data-testid='number-tile-value'
                                className={cn(
                                  'ml-2',
                                  isArrowColorReversed
                                    ? 'text-errorColor-100'
                                    : 'text-successColor-100'
                                )}
                              >
                                {Math.round(+data[1])}%
                              </span>
                              <span
                                className={cn(
                                  'ml-1',
                                  isArrowColorReversed
                                    ? 'text-errorColor-100'
                                    : 'text-successColor-100'
                                )}>
                                {percentageLabel}
                              </span>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center">
                              <LineChartIcon
                                size="4"
                                color={
                                  isArrowColorReversed
                                    ? 'text-successColor-100'
                                    : 'text-errorColor-100'
                                }
                              />

                              <span
                                className={cn(
                                  'ml-2',
                                  isArrowColorReversed
                                    ? 'text-successColor-100'
                                    : 'text-errorColor-100'
                                )}
                              >
                                {data[1]
                                  ? Math.round(
                                    +data[1].toString().replace('-', '')
                                  )
                                  : 0}
                                %
                                <span className={cn(
                                  isArrowColorReversed
                                    ? 'text-successColor-100'
                                    : 'text-errorColor-100'
                                )}>
                                   {percentageLabel}
                                </span>
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="flex flex-row items-center text-interfaceColor-100">
                          <span className="mx-1">{Math.round(+data[1])}</span>
                          {percentageLabel}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {bottomLabel && (
              <div className={styles.bottomLabelContainer}>
                <p>{bottomLabel}</p>
              </div>
            )}
          </div>
        )}
      </Card.Content>
      <TileFooter
        linkPath={linkPath}
        buttonLabel={buttonLabel}
        handleButtonClick={handleButtonClick}
      />
    </Card>
  );
};
