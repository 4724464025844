import { Button, CheckCircleIcon, Modal, SelectItem } from '@components/ui';
import { DropdownMenu, MenuItem } from '@components/ui/DropdownMenu';
import { Variant } from '@components/ui/Toast';
import { useUpdateLocationMembers } from '@hooks/locations';
import { useIsMobile, useIsTablet } from '@hooks/utils';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import { isSingularOrPluralPeopleText } from '@utils/index';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export type MoveLocationModalProps = {
  items: SelectItem[];
  isModalOpen: boolean;
  handleLocationModalClose: () => void;
  headerText?: string;
  isListView?: boolean;
  selectedLocation?: SelectItem;
  memberId?: string;
  onSubmit?: (value: boolean) => void;
};

export const MoveLocationModal: React.FC<MoveLocationModalProps> = ({
  items,
  isModalOpen,
  headerText,
  isListView = false,
  handleLocationModalClose,
  selectedLocation = null,
  memberId,
  onSubmit,
}) => {
  const { addToast } = useUIStore();
  const { locationId } = useParams();
  const { allSelectedItems } = useTableStore();
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [newLocationId, setNewLocationId] = useState<string | undefined>(
    undefined
  );
  const { reset } = useTableStore();

  useEffect(() => {
    if (!isModalOpen) {
      setNewLocationId(undefined);
    }
  }, [isModalOpen]);

  const onSelectedLocation = (id: string) => {
    setNewLocationId(id);
  };

  const moveMembersToAnotheLocationMutation =
    useUpdateLocationMembers(newLocationId);

  const handleUpdateMembers = async () => {
    try {
      await moveMembersToAnotheLocationMutation.mutateAsync({
        addedMemberIds: isListView ? [memberId as string] : allSelectedItems,
        removedMemberIds: [],
      });
      addToast({
        variant: Variant.Success,
        message: 'We’ve saved your changes',
        closeable: true,
      });
      onSubmit && onSubmit(true);
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Failed to update members',
        closeable: true,
      });
    }

    reset();
    if (!isListView) {
      navigate('/accounts/locations');
    }
  };

  const sortDataDropdown: MenuItem[] = [];

  const handleSortData = (value: SelectItem) => {
    onSelectedLocation(value.id!);
  };

  items.map((x, i) => {
    sortDataDropdown.push({
      id: `${i + 1}`,
      label: x.name || '',
      value: x.value,
      action: () => handleSortData(x),
    });
  });

  return (
    <Modal
      testId='select-location-to-move-modal'
      headerTxt={
        headerText ||
        `Select a location to move ${isSingularOrPluralPeopleText(
          allSelectedItems.length
        )} to`
      }
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      isTruncatedHeader={!isListView}
      onClose={handleLocationModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 md:flex-row">
          <Button
            testId='move-people-button'
            variant="primary"
            disabled={!newLocationId || newLocationId === locationId}
            onClick={handleUpdateMembers}
          >
            {isListView ? 'Move' : `Move ${allSelectedItems.length} people`}
          </Button>
          <Button variant="outlineLight" onClick={handleLocationModalClose} testId='cancel-move-people-button'>
            Cancel
          </Button>
        </div>
      }
      contentClassName="flex !flex-column !items-start !justify-start bg-white"
    >
      {isListView && (
        <div className="flex w-full flex-col">
          <span className="mb-2 text-16 font-semibold text-interfaceColor-100">
            Location
          </span>
          <DropdownMenu
            isFullWidth={true}
            dropdownMenuClasses="!w-full !bg-white h-[100px] overflow-y-auto"
            items={sortDataDropdown}
            showSelectedItem={true}
            buttonProps={{
              label: selectedLocation?.name || sortDataDropdown[0]?.label || '',
              className:
                'w-full !rounded-[4px] justify-between !bg-white border border-interfaceColor-40 py-2',
            }}
            initialSelectedItem={
              sortDataDropdown.find(
                (item) => item.label === selectedLocation?.name
              ) || sortDataDropdown[0]
            }
          ></DropdownMenu>
        </div>
      )}
      {!isListView && (
        <div className="flex max-h-[400px] w-full flex-col overflow-y-scroll rounded border border-interfaceColor-40 p-2 md:max-h-[300px]">
          {items.map((location) => (
            <div className="flex" key={location.id}>
              <p
                onClick={() => {
                  onSelectedLocation(location.id ?? '');
                }}
                className={`mb-1 mt-1 flex cursor-pointer items-center rounded-sm bg-interfaceColor-10 pb-1 pl-2 pr-2 pt-1 
            ${
              location.id === newLocationId ||
              (location.id === locationId && !newLocationId)
                ? 'bg-interfaceColor-100 text-white'
                : ''
            }`}
              >
                {location.name}
                {location.id === newLocationId ||
                (location.id === locationId && !newLocationId) ? (
                  <CheckCircleIcon
                    color="text-primaryBrandColor-150"
                    classNames="h-6 w-6 ml-1 p-1"
                  />
                ) : null}
              </p>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
};
