import {
  EditIcon,
  HubSettingsUserIcon,
  Loader,
  ResetPasswordIcon,
} from '@components/ui';
import { Button } from '@components/ui/Button';
import { Variant } from '@components/ui/Toast';
import { useCurrentUserQuery, useResetPasswordQuery } from '@hooks/users';
import { UserRoles } from '@hooks/users/types';
import { CreateEditUserModal } from '@pages/HubSettings/Users/Modals/CreateEditUserModal';
import useAuthStore from '@store/authStore';
import useUIStore from '@store/uiStore';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const YourDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useUIStore();
  const [isCreateEditUserModalOpen, setIsCreateEditUserModalOpen] =
    useState(false);
  const { data: currentUserData, isLoading } = useCurrentUserQuery();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading: isLoadingPasswordReset } =
    useResetPasswordQuery();

  const handleResetPassword = async () => {
    try {
      await mutateAsync();
      addToast({
        variant: Variant.Success,
        message: `We’ve sent a password reset email to ${currentUserData?.emailAddress}`,
        closeable: true,
      });
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Something went wrong, please try again later.',
        closeable: true,
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mb-6 bg-white p-6 lg:rounded-[4px] lg:shadow-shadow-02">
        <h2 className="mb-3 text-20 leading-7">Personal info</h2>
        <p className="font-bold">Email address</p>
        <p className="mb-2">{currentUserData?.emailAddress}</p>
        <p className="font-bold">Name</p>
        <p className="mb-2">{currentUserData?.fullName}</p>
        <p className="font-bold">Phone number</p>
        <p className="mb-2">
          {currentUserData?.phone ? currentUserData?.phone : 'Not set'}
        </p>

        <Button
          testId='edit-details-button'
          variant="dark"
          size="medium"
          className="mt-4 w-full md:w-auto"
          onClick={() => {
            setIsCreateEditUserModalOpen(true);
          }}
        >
          <EditIcon
            size="5"
            color={'text-primaryBrandColor-100'}
            classNames="mr-2"
          />
          Edit your details
        </Button>
      </div>

      <div className="mb-6 bg-white p-6 lg:rounded-[4px] lg:shadow-shadow-02">
        <h2 className="mb-3 text-20 leading-7">
          Need to change your password?
        </h2>
        <p>
          Click the button to reset your password. We’ll send an email to{' '}
          {currentUserData?.emailAddress} with instructions on what to do next.
        </p>
        <Button
          testId='reset-password-button'
          variant="dark"
          size="medium"
          disabled={isLoadingPasswordReset}
          className="mt-4 w-full md:w-auto"
          onClick={handleResetPassword}
        >
          <ResetPasswordIcon
            size="5"
            color={'text-primaryBrandColor-100'}
            classNames="mr-2"
          />
          Reset your password
        </Button>
      </div>

      {userRole !== UserRoles.STANDARD && (
        <div className="mb-6 bg-white p-6 lg:rounded-[4px] lg:shadow-shadow-02">
          <h2 className="mb-3 text-20 leading-7">Clevr360 access</h2>
          <p>
            Your Clevr360 access level is{' '}
            <span className="font-bold">{userRole}</span>. You can add, edit and
            remove other Clevr360 users, unlock blocked accounts, initiate password
            resets,{' '}
            {userRole === UserRoles.SUPERADMIN && (
              <span>change access level</span>
            )}{' '}
            and create and edit groups and locations.
          </p>
          {userRole === UserRoles.ADMIN && (
            <p className="mt-2">
              To change your access level, contact a super admin user.
            </p>
          )}
          <Button
            testId='view-users-button'
            variant="dark"
            size="medium"
            className="mt-4 w-full md:w-auto"
            onClick={() => {
              navigate('/settings/manage-users');
            }}
          >
            <HubSettingsUserIcon
              size="5"
              color={'text-primaryBrandColor-100'}
              classNames="mr-2"
            />
            View Clevr360 users
          </Button>
        </div>
      )}

      <CreateEditUserModal
        user={currentUserData}
        usersData={[]}
        isModalOpen={isCreateEditUserModalOpen}
        editYourDetails={true}
        handleModalClose={() => {
          setIsCreateEditUserModalOpen(false);
        }}
        handleSuccess={() => {
          setIsCreateEditUserModalOpen(false);
          queryClient.invalidateQueries('currentUser');
        }}
      />
    </>
  );
};

export default YourDetailsPage;
