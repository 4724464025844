import {
    DEFAULT_PAGE_SIZE,
    DELEGATE_MAILBOXES_TABLE_COLUMNS,
    DELEGATED_MAILBOX_SORT_BY,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';

import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import {

    formatDate, useTableData
} from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useLocation } from 'react-router-dom';
import { Modal } from '@components/ui';
import useModalStore from '@store/modal';
import { createCellValue } from './utils';
import { useDelegatedMailboxDataPreviewQuery } from '@hooks/security';

const pageSize = DEFAULT_PAGE_SIZE;

const DelegatedMailboxesPage: React.FC = () => {
    const days = new URLSearchParams(useLocation().search).get("days") || "30";
    const {
        setIsDetailsModalOpen,
        setDataDetailsModal,
        isDetailsModalOpen,
        dataDetailsModal,
    } = useModalStore();

    const [search, setSearch] = useState('');
    const { pageNumber, setPageNumber } = usePagination();

    const [queryParams, setQueryParams] = useQueryParams({
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'delegation'),
        order: withDefault(StringParam, 'desc'),
        filter: withDefault(StringParam, ''),
    });

    // table
    const query = useDelegatedMailboxDataPreviewQuery({
        pageSize,
        pageNumber,
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        days,
    });

    const getTableData = useTableData(
        query,
        DELEGATE_MAILBOXES_TABLE_COLUMNS,
        createCellValue
    );

    const [shouldDownload, setShouldDownload] = useState(false);
    const { refetch: downloadCsv } = useDownloadCsv({
        shouldDownload,
        endpoint: `${API_ENDPOINTS.DELEGATED_MAILBOX_EXPORT}`,
        params: {
            pageSize,
            pageNumber,
            searchTerm: search,
            sortBy: queryParams.sortBy as PeopleSortBy,
            order: queryParams.order as OrderBy,
            days,
        },
        filename: `Clevr360_Delegated_mailbox_data_${formatDate(
            new Date().toString()
        )}.csv`,
    });

    const handleDownloadClick = () => {
        setShouldDownload(true);
        downloadCsv();
        setShouldDownload(false);
    };

    return (<>
        <div className="">
            <TableWrapper
                testId='delegate-mailboxes-table'
                search={search}
                setSearch={setSearch}
                searchPlaceholder={'Search by mailbox ID, mailbox owner'}
                columns={DELEGATE_MAILBOXES_TABLE_COLUMNS}
                data={getTableData()}
                sortData={DELEGATED_MAILBOX_SORT_BY}
                searchKey="search"
                query={query}
                refetchQuery={query.refetch}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                setPageNumber={setPageNumber}
                isCollapsable={false}
                hasFilterBanner={false}
                isLicensePage={true}
                sliceColumns={2}
                searchCountStatsLabel="mailboxes"
                isDownloadAvailable={true}
                handleDownload={handleDownloadClick}
            />
        </div>
        <Modal 
            testId='delegated-mailboxes-modal'
            headerTxt={dataDetailsModal.header}
            isOpen={isDetailsModalOpen}
            size={'medium'}
            showCloseBtn={true}
            onClose={() => {
                setIsDetailsModalOpen(false);
                setDataDetailsModal({ body: null, header: null });
            }}
            contentClassName="bg-white"
            actionButtons={<></>}
        >
            <div className="w-full">{dataDetailsModal.body}</div>
        </Modal></>
    );
};

export default DelegatedMailboxesPage;
