import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSecurityReportPreviewQuery } from '@hooks/reports';
import { SecurityV2ReportSecureScoreResponse } from '@hooks/reports/types';
import styles from '@pages/InsightsAndAnalytics/Security/MsSecureScore/styles.module.css';
import { createCellValue } from '@pages/InsightsAndAnalytics/Security/MsSecureScore/utils';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles/HorizontalChartTile';
import {
  LineChartTile,
  LineChartTypes,
  LineChartTickFormat,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import {
  Table,
  LineChartIconUp,
  LineChartIcon,
  MinusIcon,
} from '@components/ui';
import { DashboardTiles, SECURE_SCORE_TABLE_COLUMNS } from '@common/constants';
import { formatDate, formatNumber, useTableData } from '@utils/index';

type TrendDirection = 'higher' | 'lower';
type TrendChange = 'up' | 'down' | 'stayed the same';

export const ReportSecureScoresPage: React.FC = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const location = useLocation();
  const selectedTab = location.pathname.split('/').pop() || 'secure-score';

  const query = useSecurityReportPreviewQuery<SecurityV2ReportSecureScoreResponse>({
    reportId,
    selectedTab
  });

  // Force refetch when redirected from key-recommendations
  useEffect(() => {
    if (location.state?.from === 'key-recommendations') {
      query.refetch();
    }
  }, [location.state, query]);

  // Trigger initial fetch when component mounts
  useEffect(() => {
    query.refetch();
  }, []);
    
  const items = query?.data?.items || [];
  
  const changesOverTime = items.find(
    (item: SecurityV2ReportSecureScoreResponse) =>
      item.type === DashboardTiles.SecureScoreChangesOverTime
  )?.parameters;
  const comparison = items.find(
    (item: SecurityV2ReportSecureScoreResponse) =>
      item.type === DashboardTiles.SecureScoreComparison
  )?.parameters;
  const identity = items.find(
    (item: SecurityV2ReportSecureScoreResponse) =>
      item.type === DashboardTiles.SecureScoreIdentity
  )?.parameters;
  const device = items.find(
    (item: SecurityV2ReportSecureScoreResponse) =>
      item.type === DashboardTiles.SecureScoreDevice
  )?.parameters;
  const apps = items.find(
    (item: SecurityV2ReportSecureScoreResponse) =>
      item.type === DashboardTiles.SecureScoreApps
  )?.parameters;
  const infra = items.find(
    (item: SecurityV2ReportSecureScoreResponse) =>
      item.type === DashboardTiles.SecureScoreInfrastructure
  )?.parameters;
  const improveYourScore = items.find(
    (item: SecurityV2ReportSecureScoreResponse) =>
      item.type === DashboardTiles.Table
  )?.parameters;

  const filteredData = improveYourScore?.data?.filter((item: any) => item.isCompleted === false);  
  const firstSecureScoreOfThePeriod = changesOverTime?.data[0][0];
  const lastSecureScoreOfThePeriod =
    changesOverTime?.data[0][changesOverTime?.data[0]?.length - 1];

  const trendChange: TrendChange = (
    firstSecureScoreOfThePeriod < lastSecureScoreOfThePeriod
      ? 'up'
      : firstSecureScoreOfThePeriod > lastSecureScoreOfThePeriod
        ? 'down'
        : 'stayed the same'
  ) as TrendChange;

  const labels = changesOverTime?.labels.map((item: string) =>
    formatDate(item)
  );

  const getScoreMessage = (yourScore: number, comparisonScore: number) => {
    if (yourScore === comparisonScore) return 'the same as';
    if (yourScore > comparisonScore) return 'higher than average';
    if (yourScore < comparisonScore) return 'lower than average';
  };

  const message = trendChange === 'up' ? 'Nice work!' : '';

  return (
    <>
      <div className="mt-[20px] flex flex-col items-start gap-4">
        <div className="flex flex-row items-start text-24 md:text-28">
          <p>Secure Score</p>
          <div className="ml-4 mt-2 flex flex-row items-start">
            {trendChange === 'up' && (
              <LineChartIconUp color="text-successColor-100" size="4" />
            )}
            {trendChange === 'down' && (
              <LineChartIcon size="4" color="text-errorColor-100" />
            )}
            {trendChange === 'stayed the same' && (
              <MinusIcon
                size="4"
                color="text-interfaceColor-100"
                withoutCircle={true}
                classNames="mt-[2px]"
              />
            )}

            <span
              className={`ml-2 flex items-center text-xs ${trendChange === 'up'
                ? 'text-successColor-100'
                : trendChange === 'down'
                  ? 'text-errorColor-100'
                  : 'text-interfaceColor-80'
                }`}
            >
              {trendChange === 'up'
                ? 'More secure'
                : trendChange === 'down'
                  ? 'Less secure'
                  : 'The same'}
            </span>
          </div>
        </div>
        <div className="mb-[5px] w-[70%] text-16 md:text-18">
          <p>
            {`Your Microsoft Secure Score trended ${trendChange === 'stayed the same' ? 'the same' : trendChange
              } this month. Your overall score is tracking 
            ${getScoreMessage(
                comparison?.yourScore,
                comparison?.comparisonScore
              )} organisations like yours. ${message}`}
          </p>
        </div>
      </div>
      <div className={styles.pageGrid}>
        {changesOverTime != null && (
          <LineChartTile
            classNames={'col-span-6 lg:col-span-2 xl:col-span-2'}
            sortData={[]}
            tooltipLabel={'secure score'}
            showCompareDropdown={false}
            showAgainstDropdown={false}
            contextData={[]}
            headerTitle={'Secure Score: Changes over time'}
            isLoading={false}
            labels={labels || undefined}
            data={changesOverTime?.data}
            compareLabel=""
            dropdownMenuItemClasses={'!w-[300px]'}
            dataSetTypes={[LineChartTypes.Dots]}
            dataSetColors={['#00CF6C']}
            showYTicks={true}
            showDateRange={true}
            showXTicks={true}
            isDotted={true}
            chartHeight={150}
            xTickFormat={LineChartTickFormat.Date}
            yTickFormat={LineChartTickFormat.Percentage}
          />
        )}
        {comparison != null && (
          <HorizontalBarChartTile
            contextData={[]}
            classNames={'col-span-6 lg:col-span-2 xl:col-span-2 flex-column'}
            sortData={undefined}
            dropdownMenuItemClasses={'!w-[300px]'}
            headerTitle={'Secure Score Comparison'}
            isLoading={false}
            data={
              comparison
                ? [
                  parseFloat(comparison?.yourScore?.toFixed(1) || '0'),
                  parseFloat(comparison?.comparisonScore?.toFixed(1) || '0'),
                ]
                : []
            }
            dataLabels={[
              `Your score: ${formatNumber(comparison?.yourScore?.toFixed(1) || 0)}`,
              `Organisations like yours: ${formatNumber(comparison?.comparisonScore?.toFixed(1) || 0)
              }`,
            ]}
            barBackgroundColors={['#00CF6C', '#B65AD7']}
          />
        )}
        {identity != null && (
          <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            classNames={'col-span-6 md:col-span-3 xl:col-span-1'}
            key={undefined}
            sortData={undefined}
            contextData={[]}
            headerTitle={'Secure Score: Identity'}
            isLoading={false}
            data={[formatNumber(identity?.scorePercentage)]}
            percentageLabel=""
          />
        )}
        {device != null && (
          <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            classNames={'col-span-6 md:col-span-3 xl:col-span-1'}
            key={undefined}
            sortData={undefined}
            contextData={[]}
            headerTitle={'Secure Score: Device'}
            isLoading={false}
            data={[formatNumber(device?.scorePercentage)]}
            percentageLabel=""
          />
        )}
        {apps != null && (
          <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            classNames={'col-span-6 md:col-span-3 xl:col-span-1'}
            key={undefined}
            sortData={undefined}
            contextData={[]}
            headerTitle={'Secure Score: Apps'}
            isLoading={false}
            data={ [formatNumber(apps?.scorePercentage)]}
            percentageLabel=""
          />
        )}
        {infra != null && (
          <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            classNames={'col-span-6 md:col-span-3 xl:col-span-1'}
            key={undefined}
            sortData={undefined}
            contextData={[]}
            headerTitle={'Secure Score: Infrastructure'}
            isLoading={false}
            data={[formatNumber(infra?.scorePercentage)]}
            percentageLabel=""
          />
        )}
      </div>
      <div className="mt-2">
        <div className="bg-white md:mx-auto lg:rounded-[4px] lg:shadow-shadow-02">
          <div className="flex flex-col gap-0">
            <div className="flex flex-row items-center justify-between pb-2">
              <div className="mb-2 flex flex-col px-4 pt-2">
                <div className="mt-[3px] text-20 font-normal">
                  Improve your score
                </div>
                <div className="mt-[3px] text-16 font-normal">
                  There’s no such thing as too secure. We’ve highlighted the 10
                  highest-impact changes you could make to improve your score.
                </div>
              </div>
            </div>
          </div>
          
          <Table
            columns={SECURE_SCORE_TABLE_COLUMNS}
            data={useTableData(
              { isFetched: true, data: { items: filteredData } },
              SECURE_SCORE_TABLE_COLUMNS,
              createCellValue,
              true
            )()}
            query={undefined}
            isLoading={false}
            totalCount={10}
            currentPage={1}
            totalPages={1}
            setPageNumber={() => 1}
            handleSelect={() => null}
            isFirstColumnSticky={false}
            showPagination={false}
            isGrayHeader={true}
          />
        </div>
      </div>
    </>
  );
};