import { Banner, Button, Modal, RadioButton } from '@components/ui';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import styles from './styles.module.css';
import useTableStore from '@store/tableStore';
import { useEffect } from 'react';

export type DownloadModalProps = {
  isModalOpen: boolean;
  handleDownloadModalClose: () => void;
  handleDownloadClick: () => void;
  isSimpleTable?: boolean;
};

export const DownloadModal: React.FC<DownloadModalProps> = ({
  isModalOpen,
  handleDownloadModalClose,
  handleDownloadClick,
  isSimpleTable,
}) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const { downloadType, setDownloadType, isLoadingExportData } =
    useTableStore();

  useEffect(() => {
    if (isSimpleTable) {
      setDownloadType('all');
    } else {
      setDownloadType(null);
    }
  }, [isSimpleTable]);

  return (
    <Modal
      testId='download-modal'
      headerTxt="Download data"
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleDownloadModalClose}
      actionButtons={
        <>
          <Button
            testId='download-data-button'
            loading={isLoadingExportData}
            disabled={!downloadType}
            variant="primary"
            className={styles.downloadBtn}
            onClick={handleDownloadClick}
          >
            Download data
          </Button>
          <Button
            testId='cancel-download-button'
            variant="outlineLight"
            className={styles.closeBtn}
            onClick={handleDownloadModalClose}
          >
            Cancel
          </Button>
        </>
      }
      contentClassName={styles.contentClasses}
    >
      <div className="w-full">
        <Banner
          variant="info"
          message="Your data will be downloaded as a .CSV."
          className="mb-5 flex-grow"
        />
        {!isSimpleTable && (
          <>
            <p className={styles.headerText}>
              What would you like to download?
            </p>{' '}
            <div className="mt-2">
              <RadioButton
                name="filters"
                value="filters"
                label=" Only download data matching my search and filters"
                checked={downloadType === 'filters'}
                onChange={() => setDownloadType('filters')}
              ></RadioButton>
              <span className={styles.labelForFilterData}>
                Downloaded data will be filtered to match your current view.
              </span>
            </div>
            <div className="mb-2 mt-4">
              <RadioButton
                name="all"
                value="all"
                label="Download all data"
                checked={downloadType === 'all'}
                onChange={() => setDownloadType('all')}
              ></RadioButton>
              <span className={styles.labelForAllData}>
                We&apos;ll remove any filters you have applied so you get all
                the data.
              </span>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
