import { OverlayPage } from '@components/partials/OverlayPage';
import {
  useLicencesAssignmentQuery,
  useLicencesFiltersQuery,
  useLicencesTrendQuery,
  useLicensesAssignmentPreviewQuery,
} from '@hooks/licenses';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  UNASSIGNED_LICENSES_SORT_BY,
  UNASSIGNED_LICENSES_TABLE_COLUMNS,
  VENDORS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { DashboardIcon, LightInfoIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useConnectedServicesQuery } from '@hooks/connectedServices';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import {
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { AvailableServices } from '@pages/HubSettings/ConnectedServices';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { TextTile } from '@pages/InsightsAndAnalytics/tiles/TextTile';
import {
  formatDate,
  formatLicensesFilter,
  getTrendData,
  getTrendLabels,
  useTableData,
} from '@utils/index';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;

const UnassignedLicensePage: React.FC = () => {
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const isDesktop = useIsDesktop();
  const [days, setDays] = useState('30');
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdown('30'),
    },
    {
      id: '2',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdown('60'),
    },
    {
      id: '3',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdown('90'),
    },
  ];

  const handleDropdown = (value: string) => {
    setDays(value);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'licensetype'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const {
    data: licencesAssignmentData,
    isLoading: isLicencesAssignmentLoading,
  } = useLicencesAssignmentQuery({
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : VENDORS,
  });

  const { data: licencesTrendData, isLoading: isLicencesUtilisationLoading } =
    useLicencesTrendQuery({
      days,
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      licenseTypeList: formatLicensesFilter(queryParams.filter),
    });

  const labels = getTrendLabels(licencesTrendData?.trend || [], +days);

  const trendData = getTrendData(licencesTrendData?.trend || [], +days);

  const { data: connectedServicesData } = useConnectedServicesQuery();

  const query = useLicensesAssignmentPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    licenseTypeList: formatLicensesFilter(queryParams.filter),
  });
  const getTableData = useTableData(
    query,
    UNASSIGNED_LICENSES_TABLE_COLUMNS,
    createCellValue
  );

  const { data: filterData } = useLicencesFiltersQuery(
    'true',
    queryParams.filter.includes('freeLicenses')
  );

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
    licensetype: 'License Type',
  };

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  const transformedFilterData: FilterData[] = [];
  if (filterData) {
    Object.keys(filterData).forEach((property) => {
      const filterDataArray: FilterData = {
        label: getPropertyLabel(property),
        name: property.toLowerCase(),
        options: [],
        singleSelect: !!(property === 'vendor'),
      };

      const propertyData =
        filterData[property as keyof LicensesFiltersResponse];

      if ('value' in propertyData) {
        const item = propertyData as LicensesFilterData;

        const option = {
          label: item.value || item.vendor,
          value: item.value || item.vendor,
          count: item.count,
        };

        filterDataArray.options.push(option);
      } else {
        const items = propertyData as unknown as LicensesFilterData[];

        items.forEach((item) => {
          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
          };

          filterDataArray.options.push(option);
        });
      }

      filterDataArray.options.forEach((option) => {
        const matchingVendor = filterData.licenseType.find(
          (v) => v.value === option.value
        );

        if (matchingVendor) {
          option.vendor = matchingVendor.vendor;
        }
      });

      transformedFilterData.push(filterDataArray);
    });

    transformedFilterData.push({
      label: 'Free licences',
      name: 'freeLicenses',
      singleSelect: true,
      options: [{ label: 'Show free licenses', value: 'freeLicenses' }],
    });
  }

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.UnassignedLicensesTrendOverTime:
          return {
            days,
            vendorList: queryParams.filter
              ? queryParams.filter
                  .split(',')
                  .filter((filter) => VENDORS.includes(filter as any))
              : [],
            licenseTypeList: formatLicensesFilter(queryParams.filter),
          };
        case DashboardTiles.UnassignedLicensesSnapshot:
          return {
            vendorList: queryParams.filter
              ? queryParams.filter
                  .split(',')
                  .filter((filter) => VENDORS.includes(filter as any))
              : VENDORS,
          };
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const showLinkForService = (service: number) => {
    return (
      connectedServicesData &&
      connectedServicesData.some((obj) => obj.vendor === service)
    );
  };

  const selectedSortItem = sortMenuItems.find((item) => item.value === days);

  const legendLabels = [(selectedSortItem && selectedSortItem.label) || ''];

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.LICENSES_ASSIGNMENT_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      licenseTypeList: formatLicensesFilter(queryParams.filter),
    },
    filename: `Clevr360_Unassigned_licenses_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Unassigned licenses`}
    >
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <LineChartTile
              classNames={styles.lineChartTile}
              initialSelectedItem={selectedSortItem}
              legendLabels={legendLabels}
              dataSetColors={['#00CF6C']}
              sortData={sortMenuItems}
              compareData={undefined}
              compareLabel=""
              tooltipLabel={'unassigned licenses'}
              headerTitle={'Unassigned licenses: Trend over time'}
              contextData={createMenuItems(
                DashboardTiles.UnassignedLicensesTrendOverTime
              )}
              isLoading={isLicencesUtilisationLoading}
              labels={labels}
              data={[trendData]}
              dataSetTypes={[LineChartTypes.Dots]}
              showDateRange={true}
            />

            <DoughnutChartTile
              classNames={styles.doughnutChartTile}
              headerTitle={'Unassigned licenses: Snapshot'}
              contextData={[]}
              isLoading={isLicencesAssignmentLoading}
              data={
                licencesAssignmentData
                  ? Object.values(licencesAssignmentData)
                  : []
              }
              dataLabels={['Unassigned licenses', 'Assigned licenses']}
              showBracketLabelValue={true}
            />
          </>
        )}

        <TextTile classNames={styles.textTile} headerTitle={'Recommendations'}>
          <div className={styles.textTileContainer}>
            <div className={styles.textTileItem}>
              <LightInfoIcon
                color={styles.textTileItemIcon}
                classNames="w-4 h-4"
              />
              <span>
                Save money by using up unassigned licenses before you buy any
                new licenses
              </span>
            </div>
            <div className={styles.textTileItem}>
              <LightInfoIcon
                color={styles.textTileItemIcon}
                classNames="w-4 h-4"
              />
              <span>
                You may be able to get a refund for unassigned licenses
              </span>
            </div>
            <div className={styles.textTileLinkItem}>
              {showLinkForService(AvailableServices.MICROSOFT) && (
                <Link
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://learn.microsoft.com/en-us/microsoft-365/commerce/subscriptions/what-if-my-subscription-expires?view=o365-worldwide"
                >
                  Find out more for Microsoft
                </Link>
              )}
              {showLinkForService(AvailableServices.RINGCENTRAL) && (
                <Link
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://support.ringcentral.com/article-v2/Managing-licenses-in-RingCentral-account.html?brand=RC_US&product=RingCentral_MVP&language=en_US"
                >
                  Find out more for RingCentral
                </Link>
              )}
            </div>
          </div>
        </TextTile>
      </div>
      <TableWrapper
        testId='unassigned-licenses-table'
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by license type'}
        columns={UNASSIGNED_LICENSES_TABLE_COLUMNS}
        data={getTableData()}
        sortData={UNASSIGNED_LICENSES_SORT_BY}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={2}
        floatingFilterButton={true}
        isDownloadAvailable={true}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="license types"
        filterHeaderText="Filter unassigned licenses"
        handleDownload={handleDownloadClick}
      />
    </OverlayPage>
  );
};

export default UnassignedLicensePage;
