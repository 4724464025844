import React, { useState } from 'react';
import { useIsDesktop } from '@hooks/utils';
import { OverlayPage, TableWrapper } from '@components/partials';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { DEFAULT_PAGE_SIZE, CONFIGURED_TRUNKS_SORT_BY, CONFIGURED_TRUNKS_TABLE_COLUMNS } from '@common/constants';
import styles from './styles.module.css';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { ConfiguredTrunks } from '@pages/Dashboard/tiles/ConfiguredTrunks';
import { usePagination } from '@hooks/utils/pagination';
import { useConfiguredTrunksFilterQuery, useConfiguredTrunksPreviewQuery } from '@hooks/security';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { formatDate, getVendorName, useTableData } from '@utils/index';
import { addSelectedDataToFilter, isValueIncludedInFilter } from './helper';
import { PeopleSortBy } from '@hooks/people/types';
import { OrderBy } from '@common/types';
import { createCellValue } from './utils';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import useServicesStore from '@store/servicesStore';
import { Banner } from '@components/ui';

const pageSize = DEFAULT_PAGE_SIZE;

const ConfiguredTrunksPage: React.FC = () => {
    const redirectPath = useNavSourceRedirect(
        '/insights-and-analytics/utilisation'
    );
    const isDesktop = useIsDesktop();

    const [search, setSearch] = useState('');
    const { pageNumber, setPageNumber } = usePagination();

    const [queryParams, setQueryParams] = useQueryParams({
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'trunkname'),
        order: withDefault(StringParam, 'asc'),
        filter: withDefault(StringParam, ''),
    });

    const transformedFilterData: FilterData[] = [];
    const { serviceIntegrations } = useServicesStore();
    const serviceIntegration = serviceIntegrations?.find((integration) =>
        (integration.vendor === 3 || integration.vendor === 4) && integration.status === 0);

    //filters
    const { data } = useConfiguredTrunksFilterQuery();

    if (data) {
        addSelectedDataToFilter(data, transformedFilterData);
    }
    // table
    const query = useConfiguredTrunksPreviewQuery({
        pageSize,
        pageNumber,
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        TrunkNameList: queryParams.filter
            ? queryParams.filter
                .split(',')
                .filter((filter) => isValueIncludedInFilter(filter, data?.trunkName))
            : [],
        TrunkTypeList: queryParams.filter
            ? queryParams.filter
                .split(',')
                .filter((filter) => isValueIncludedInFilter(filter, data?.trunkType))
            : [],
    });

    const getTableData = useTableData(
        query,
        CONFIGURED_TRUNKS_TABLE_COLUMNS,
        createCellValue
    );

    const [shouldDownload, setShouldDownload] = useState(false);
    const { refetch: downloadCsv } = useDownloadCsv({
        shouldDownload,
        endpoint: `${API_ENDPOINTS.GENERATED_TRUNKS_EXPORT}`,
        params: {
            pageSize,
            pageNumber,
            searchTerm: search,
            sortBy: queryParams.sortBy as PeopleSortBy,
            order: queryParams.order as OrderBy,
            TrunkNameList: queryParams.filter
                ? queryParams.filter
                    .split(',')
                    .filter((filter) => isValueIncludedInFilter(filter, data?.trunkName))
                : [],
            TrunkTypeList: queryParams.filter
                ? queryParams.filter
                    .split(',')
                    .filter((filter) => isValueIncludedInFilter(filter, data?.trunkType))
                : [],
        },
        filename: `Clevr360_Configured_trunks_data_${formatDate(
            new Date().toString()
        )}.csv`,
    });

    const handleDownloadClick = () => {
        setShouldDownload(true);
        downloadCsv();
        setShouldDownload(false);
    };


    return (
        <OverlayPage
            isFooterVisible={false}
            contentClassNames={''}
            path={redirectPath}
            headerTitle={`Trunk data`}
        >
            <div className={styles.pageGrid}>
                {isDesktop && (
                    <>
                        <ConfiguredTrunks
                            isSnapshot={true}
                            classNames="col-span-1"
                            params={JSON.stringify({})}
                        />

                    </>
                )}
            </div>
            <>
                {query.isFetched && !query.data?.items.length && !search &&
                    <Banner
                        closeable={true}
                        message={`No call queue data available within the selected data set. If changes have been made within your
                            ${serviceIntegration?.vendor ? getVendorName(serviceIntegration?.vendor) : ''} system${query.data?.lastUpdatedAt ?
                                ` after ${query.data?.lastUpdatedAt}` : ''}, please request updated data via the CloudClevr support team.`}
                        className="mb-4 w-full md:w-[534px] lg:w-[704px]"
                    />
                }
                <div className="mt-2">
                    <TableWrapper
                        testId='configured-trunks-table'
                        search={search}
                        setSearch={setSearch}
                        searchPlaceholder={'Search by trunk group'}
                        columns={CONFIGURED_TRUNKS_TABLE_COLUMNS}
                        data={getTableData()}
                        sortData={CONFIGURED_TRUNKS_SORT_BY}
                        searchKey="search"
                        query={query}
                        filterData={transformedFilterData}
                        refetchQuery={query.refetch}
                        queryParams={queryParams}
                        setQueryParams={setQueryParams}
                        setPageNumber={setPageNumber}
                        isCollapsable={true}
                        hasFilterBanner={false}
                        isLicensePage={true}
                        sliceColumns={2}
                        searchCountStatsLabel="trunks"
                        isDownloadAvailable={true}
                        handleDownload={handleDownloadClick}
                        filterHeaderText="Filter trunks"
                        displayAsDropdownFilterItems={["Trunk group"]}
                    />
                </div>
            </>
        </OverlayPage>
    );
};

export default ConfiguredTrunksPage;
