import React, { useState, useEffect, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Checkbox } from '../Checkbox';
import { Input } from '../Input';
import { Button } from '../Button';
import { ArrowDownIcon, TimesCircleIcon } from '../Icons';

export type Option = {
  value: string;
  label: string;
};

type MultiSelectDropdownProps = {
  options: Option[];
  selectedOptions: Option[];
  setSelectedOptions: (options: Option[]) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  infoText?: string;
  dropdownMenuClasses?: string;
};

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  selectedOptions,
  setSelectedOptions,
  placeholder,
  searchPlaceholder,
  infoText,
  dropdownMenuClasses,
}) => {
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (search.length >= 3) {
      setFilteredOptions(
        options.filter((option) =>
          option.label.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredOptions(options);
    }
  }, [search, options]);

  const handleSelectOption = (option: Option) => {
    if (selectedOptions.find((selected) => selected.value === option.value)) {
      setSelectedOptions(
        selectedOptions.filter((selected) => selected.value !== option.value)
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleClearSelection = () => {
    setSelectedOptions([]);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const displayText = selectedOptions.map((option) => option.label).join(', ');

  return (
    <div ref={dropdownRef} className="relative w-full">
      <Menu>
        {() => (
          <div className="relative">
            <Menu.Button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="w-full select-none truncate rounded border bg-white py-2 pl-4 pr-8 text-left shadow-sm"
            >
              {displayText || placeholder || 'Select options'}
              <ArrowDownIcon classNames="h-4 w-4 pl-1 mt-1 absolute right-3 -translate-y-1/2 transform top-1/2" />
            </Menu.Button>
            {infoText && (
              <span className="absolute -bottom-7 left-0 text-sm font-normal text-gray-500">
                {infoText}
              </span>
            )}
            <Transition
              unmount={false}
              show={isDropdownOpen}
              as={'div'}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              className={`absolute  w-full ${
                dropdownMenuClasses ? dropdownMenuClasses : ''
              }`}
            >
              <div className="absolute top-1 z-20 w-full border-b bg-white p-3">
                <Input
                  name="search"
                  placeholder={searchPlaceholder || 'Search options'}
                  className="w-full"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div
                className={`absolute bottom-16 top-16 w-full overflow-auto bg-white ${
                  dropdownMenuClasses ? dropdownMenuClasses : ''
                }`}
              >
                <Menu.Items className={`w-full bg-white`}>
                  {filteredOptions.map((option, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <div
                          className={`cursor-pointer px-2 py-1 ${
                            active ? 'bg-white' : ''
                          }`}
                        >
                          <Checkbox
                            labelClassName="w-full h-10"
                            className="w-full"
                            label={option.label}
                            name={option.value}
                            checked={selectedOptions.some(
                              (selected) => selected.value === option.value
                            )}
                            onChange={() => handleSelectOption(option)}
                          />
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </div>
              <div className="fixed -bottom-[8.5rem] flex w-full items-center justify-between border-t bg-white p-5 shadow-sm">
                <Button
                  testId='select-dropdown-done-button'
                  variant="primary"
                  size="small"
                  onClick={() => setIsDropdownOpen(false)}
                >
                  Done
                </Button>
                {selectedOptions.length > 0 && (
                  <span
                    onClick={handleClearSelection}
                    className="flex cursor-pointer items-center gap-x-1 text-interfaceColor-100"
                  >
                    <TimesCircleIcon color="text-interfaceColor-100" size="4" />
                    <span className="border-b border-interfaceColor-40">
                      Clear selection
                    </span>
                  </span>
                )}
              </div>
            </Transition>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default MultiSelectDropdown;
