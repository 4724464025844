import React, { useEffect, useState } from 'react';
import { Button, ArrowDownIcon } from '@components/ui';
import { IOption } from '@common/types';
import { SelectDropdown } from '@components/ui/SelectDropdown';
import { FilterData } from '.';
import { SelectedItem } from '@components/ui/SelectDropdown/SelectedItem';
import styles from './styles.module.css';

type FilterDropdownProps<T> = {
    handleCheckboxChange: (option: IOption, filter: FilterData) => void;
    item: FilterData;
    selectedFilters: string[];
};

export const FilterDropdown: <T>(props: FilterDropdownProps<T>) => React.JSX.Element = ({
    handleCheckboxChange,
    item,
    selectedFilters
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const handleChange = (item: FilterData, id: string) => {
        const option = item.options.find(option => option.value === id);
        if (option) handleCheckboxChange(option, item);
    }

    return (
        <SelectDropdown
            header={
                selectedFilters.length > 0 ?
                    (<div className={styles.filterSelectedItemsWrapper}>
                        <div className={styles.filterSelectedItemsBox}>
                            {selectedFilters.slice(0, 3).map(id =>
                                <SelectedItem
                                    key={id}
                                    label={item.options.find(option =>
                                        option.value === id)?.label || ""}
                                    onClick={() => handleChange(item, id)}
                                />
                            )}
                        </div>
                        <div className={styles.expandDropdownWrapper} onClick={() => {
                            setIsDropdownOpen(!isDropdownOpen)
                        }}>
                            <p>
                                {selectedFilters.length > 3 &&
                                    `+${selectedFilters.length - 3}`
                                }
                            </p>
                            <ArrowDownIcon size="3" classNames='mt-[6px]' />
                        </div>
                    </div>)
                    :
                    (<Button
                        testId='filter-dropdown-select-button'
                        onClick={() => {
                            if (!selectedFilters.length)
                                setIsDropdownOpen(!isDropdownOpen)
                        }}
                        size="small"
                        type="button"
                        variant="inactiveOutline"
                        className={styles.filterButton}
                        contentClassname={styles.buttonContent}
                    >
                        <p>Select</p>
                        <ArrowDownIcon size="3" />
                    </Button >)
            }
            isSingleSelect={false}
            classNames={'!w-[305px]'}
            isOpen={isDropdownOpen}
            setIsOpen={(isOpen: boolean) => {
                setIsDropdownOpen(isOpen)
            }}
            items={
                item.options.map(item => ({
                    id: item.value, name: item.label, value: item.value,
                    checked: selectedFilters.includes(item.value)
                }))
            }
            onSelect={(ids: string[], selectedItem) => {
                if (selectedItem?.value)
                    handleChange(item, selectedItem?.value)
            }}
        />
    )
};
