import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { ArrowDownIcon, CheckCircleIcon } from '../Icons';
import { useIsMobile } from '@hooks/utils';
import styles from './styles.module.css';
import { Modal } from '../Modal';

type DropdownButtonProps = {
  label?: string;
  icon?: JSX.Element;
  className?: string;
};

export type MenuItem = {
  id: string | number;
  label: string;
  icon?: JSX.Element;
  action?: () => void;
  value?: string;
  classNames?: string;
  disabled?: boolean;
};

type DropdownMenuProps = {
  items: MenuItem[];
  buttonProps?: DropdownButtonProps;
  showSelectedItem?: boolean;
  initialSelectedItem?: MenuItem;
  dropdownMenuClasses?: string;
  hasComparison?: boolean;
  adjustedPosition?: React.CSSProperties;
  isFullWidth?: boolean;
  closeOnClickOutside?: boolean;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  buttonProps: { icon, label, className } = {},
  showSelectedItem = false,
  initialSelectedItem,
  dropdownMenuClasses,
  hasComparison = false,
  adjustedPosition,
  isFullWidth = false,
  closeOnClickOutside = false,
}) => {
  const [menuItemsStyle, setMenuItemsStyle] = useState<{ top: string, bottom: string }>
  ({ top: '100%', bottom: 'auto' });
  const isMobile = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(
    initialSelectedItem || null
  );
  const getButtonClass = classNames(
    'flex h-8 w-8 items-center justify-center rounded-full bg-interfaceColor-30',
    className
  );

  const getSelectedItemHeaderClass = classNames(
    'inline-flex items-center px-4 py-1 flex-row border-l-[1px] justify-center border-t-[1px] rounded-[24px] !bg-interfaceColor-10 text-16',
    className
  );

  const getdropdownMenuItemsClass = classNames(
    'absolute right-0 z-50 mt-2 w-[180px] origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
    dropdownMenuClasses
  );

  const getItemClass = (active: boolean, item: MenuItem) =>
    classNames(
      active ? 'bg-interfaceColor-30 cursor-pointer' : 'bg-white',
      item.disabled ? 'text-interfaceColor-40' : 'text-interfaceColor-100',
      'group flex gap-x-1 w-full items-center px-2 py-2 text-16',
      'white-space-nowrap overflow-hidden text-overflow-ellipsis'
    );

  return (
    <>
      {(!isMobile || isFullWidth) && (
        <Menu
          as="div"
          className={`relative inline-block text-left ${
            isFullWidth ? 'w-full' : ''
          }`}
        >
        {selectedItem && showSelectedItem ? (
            <Menu.Button className={getSelectedItemHeaderClass}>
              {selectedItem.icon}
              <span>
                {hasComparison
                  ? initialSelectedItem?.label
                  : selectedItem.label}
              </span>
              <ArrowDownIcon classNames="h-4 w-4 pl-1 mt-1" />
            </Menu.Button>
          ) : (
          <Menu.Button className={getButtonClass} 
            onClick={(e: any) => {
              const elementBottom = e.target.getBoundingClientRect().bottom;
              const remainingBottomSpace = window.innerHeight - elementBottom;
              setMenuItemsStyle(remainingBottomSpace < 300 ? 
                { top: 'auto', bottom: '100%' } : { top: '100%', bottom: 'auto' })
            }}>
              {icon}
              {label}
            </Menu.Button>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={getdropdownMenuItemsClass}
              style={adjustedPosition || menuItemsStyle}
            >
              <div className="px-1 py-1">
                {items.map((item) => (
                  <Menu.Item key={item.id} disabled={item.disabled}>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          item.action && item.action();
                          setSelectedItem(item);
                        }}
                        className={getItemClass(active, item)}
                      >
                        {item.icon}
                        {item.label}
                        {showSelectedItem &&
                          hasComparison &&
                          item.label === initialSelectedItem?.label && (
                            <CheckCircleIcon
                              color="text-primaryBrandColor-150 absolute right-4"
                              size="4"
                            />
                          )}

                        {showSelectedItem &&
                          !hasComparison &&
                          item.label === selectedItem?.label && (
                            <CheckCircleIcon
                              color="text-primaryBrandColor-150 absolute right-4"
                              size="4"
                            />
                          )}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}

      {isMobile && !isFullWidth && (
        <div>
          <Menu
            as="div"
            className={`relative inline-block !overflow-x-auto text-left ${
              isFullWidth ? 'w-full' : ''
            }`}
          >
            {selectedItem && showSelectedItem ? (
              <Menu.Button
                onClick={() => setIsModalOpen(true)}
                className={getSelectedItemHeaderClass}
              >
                {selectedItem.icon}
                <span>
                  {hasComparison
                    ? initialSelectedItem?.label
                    : selectedItem.label}
                </span>
                <ArrowDownIcon classNames="h-4 w-4 pl-1 mt-1" />
              </Menu.Button>
            ) : (
              <Menu.Button
                onClick={() => setIsModalOpen(true)}
                className={getButtonClass}
              >
                {icon}
                {label}
              </Menu.Button>
            )}
            <Modal
              testId='dropdown-menu-modal'
              key="dropdown"
              isOpen={isModalOpen}
              closeOnClickOutside={closeOnClickOutside}
              position="bottom"
              size="small"
              emptyContent={true}
              showCloseBtn={false}
              onClose={() => {
                setIsModalOpen(false);
              }}
              contentClassName={'bg-white'}
            >
              <ul className={styles.listModal}>
                {items.map((item) => (
                  <Menu.Item
                    as={'li'}
                    className={styles.listItemModal}
                    key={item.id}
                  >
                    {({ active }) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          item.action && item.action();
                          setSelectedItem(item);
                          setIsModalOpen(false);
                        }}
                        className={getItemClass(active, item)}
                      >
                        {item.icon}
                        {item.label}
                        {showSelectedItem &&
                          hasComparison &&
                          item.label === initialSelectedItem?.label && (
                            <CheckCircleIcon
                              color="text-primaryBrandColor-150 absolute right-4"
                              size="4"
                            />
                          )}

                        {showSelectedItem &&
                          !hasComparison &&
                          item.label === selectedItem?.label && (
                            <CheckCircleIcon
                              color="text-primaryBrandColor-150 absolute right-4"
                              size="4"
                            />
                          )}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </ul>
            </Modal>
          </Menu>
        </div>
      )}
    </>
  );
};
