import {
    DEFAULT_PAGE_SIZE,
    FAILED_LOGIN_TYPES_SORT_BY,
    FAILED_LOGIN_TYPES_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';

import { PeopleSortBy } from '@hooks/people/types';
import { useFailedLoginTypesPreviewQuery } from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';

import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { createCellValue } from '../utils';
import { useLocation } from "react-router-dom";

const pageSize = DEFAULT_PAGE_SIZE;

const FailedLoginTypesPage: React.FC = () => {
    const days = new URLSearchParams(useLocation().search).get("days")|| "30";
    const [search, setSearch] = useState('');
    const { pageNumber, setPageNumber } = usePagination();
    const [queryParams, setQueryParams] = useQueryParams({
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'failedlogins'),
        order: withDefault(StringParam, 'desc'),
    });

    // table
    const query = useFailedLoginTypesPreviewQuery({
        pageSize,
        pageNumber,
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        days,
    });

    const getTableData = useTableData(
        query,
        FAILED_LOGIN_TYPES_TABLE_COLUMNS,
        createCellValue
    );

    const [shouldDownload, setShouldDownload] = useState(false);
    const { refetch: downloadCsv } = useDownloadCsv({
        shouldDownload,
        endpoint: `${API_ENDPOINTS.FAILED_LOGIN_TYPES_EXPORT}`,
        params: {
            pageSize,
            pageNumber,
            searchTerm: search,
            sortBy: queryParams.sortBy as PeopleSortBy,
            order: queryParams.order as OrderBy,
            days,
        },
        filename: `Clevr360_Faled_login_types_data_${formatDate(
            new Date().toString()
        )}.csv`,
    });

    const handleDownloadClick = () => {
        setShouldDownload(true);
        downloadCsv();
        setShouldDownload(false);
    };

    return (
        <div className="mt-2">
            <TableWrapper
                testId='failed-login-types-table'
                search={search}
                setSearch={setSearch}
                searchPlaceholder={'Search by login type'}
                columns={FAILED_LOGIN_TYPES_TABLE_COLUMNS}
                data={getTableData()}
                sortData={FAILED_LOGIN_TYPES_SORT_BY}
                searchKey="search"
                query={query}
                refetchQuery={query.refetch}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                setPageNumber={setPageNumber}
                isCollapsable={true}
                sliceColumns={1}
                floatingFilterButton={false}
                hasFilterBanner={false}
                isLicensePage={true}
                searchCountStatsLabel="login types"
                isDownloadAvailable={true}
                handleDownload={handleDownloadClick}
                noResultTitle={search ? 'No results matching your search' 
                    : 'There are no accounts with failed logins identified on your Microsoft 365 service'}
                  noResultSubtitle={search ? 'Try a less specific search.' : ''}
            />
        </div>
    );
};

export default FailedLoginTypesPage;
