import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export enum LineChartTypesPdf {
  Dots = 'dots',
  Lines = 'lines',
}

export enum LineChartTickFormatPdf {
  Date = 'date',
  Percentage = 'percentage',
  Number = 'number',
}

interface LineChartTilePdfProps {
  tooltipLabel?: string;
  headerTitle: string;
  labels: string[];
  data: number[][];
  dataSetTypes: LineChartTypesPdf[];
  dataSetColors: string[];
  showYTicks?: boolean;
  showXTicks?: boolean;
  isDotted?: boolean;
  chartHeight?: number;
  xTickFormat?: LineChartTickFormatPdf;
  yTickFormat?: LineChartTickFormatPdf;
}

export const LineChartTilePdf: React.FC<LineChartTilePdfProps> = ({
    tooltipLabel = '',
    headerTitle,
    labels = [],
    data = [[]],
    dataSetTypes,
    dataSetColors,
    showYTicks = true,
    showXTicks = true,
    isDotted = false,
    chartHeight = 200,
    xTickFormat = LineChartTickFormatPdf.Date,
    yTickFormat = LineChartTickFormatPdf.Number,
  }) => {


    // Add function to handle monthly intervals
  const getFormattedLabels = (labels: string[]) => {
    try {
      if (xTickFormat === LineChartTickFormatPdf.Date) {
        const isMonthlyReport = labels.length > 15;
        
        return labels.map((label, index) => {
          const date = new Date(label);
          const month = date.toLocaleString('en-US', { month: 'short' });
          const day = date.getDate();

          if (isMonthlyReport) {
            // Only show labels for every 10th day
            return day === 1 || day === 11 || day === 21 || day === 31 
              ? `${month} ${day}`
              : '';
          }
          return `${month} ${day}`;
        });
      }
      return labels.map(label => String(label));
    } catch {
      return labels;
    }
  };

  const sanitizedLabels = getFormattedLabels(labels);  

    const sanitizedData = data.map(dataset => 
      dataset?.map(value => Number(value) || 0) || []
    );
  
        
    const options: ChartOptions<'line'> = {
      responsive: true,
      maintainAspectRatio: false,
      devicePixelRatio: 2,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: false,
          },
          border: {
            display: true,
            width: 2, // Thicker main x-axis line
            color: 'rgba(235, 235, 235, 0.9)',
          },
          ticks: {
            font: {
              size: 11,
              family: 'Roobert, Arial, sans-serif',
            },
            padding: 8, // Increased padding for x-axis labels
            color: '#6B7280',
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
            callback: function(value, index, values) {
              return sanitizedLabels[index] || '';
            }
          },
          offset: true,
          afterFit(scaleInstance) { // Add extra padding for dots
            scaleInstance.paddingBottom = 15;
            scaleInstance.paddingTop = 15;
          }
        },
        y: {
          display: true,
          min: 0, 
          max: 100,
          grid: {
            display: true,
            color: 'rgba(235, 235, 235, 0.8)',
            lineWidth: 1,
            drawTicks: false,          
            
          },
          border: {
            display: true,
            width: 2, // Thicker main y-axis line
            color: 'rgba(235, 235, 235, 0.9)',
            dash: [5, 5], // Moved dashed line pattern here          
          },
          ticks: {
            font: {
              size: 11,
              family: 'Roobert, Arial, sans-serif',
            },
            padding: 10,
            color: '#6B7280',
            callback: function(tickValue: string | number) {
              const value = Number(tickValue);
              if ([0, 25, 50, 75, 100].includes(value)) {
                return `${value}%`;
              }
              return '';
            },
            stepSize: 25,
            maxTicksLimit: 5,
            autoSkip: false,
            
          },
          offset: true, // Move offset to scale level
          //grace: '1%', // Add grace for padding
          beginAtZero: true,
        },
      },
      elements: {
        point: {
          radius: 3,
          borderWidth: 0,
          backgroundColor: '#00CF6C',
          hitRadius: 8,
          hoverRadius: 4,
        },
        line: {
          tension: 0,
          borderWidth: 1.5,
          borderColor: '#00CF6C',
        }
      },
      layout: {
        padding: {
          left: 0, // Added padding for y-axis start
          right: 0,
          top: 0, // Added padding for top dots
          bottom: 0, // Added padding for bottom dots
        }
      }
    };
    
    const chartData: ChartData<'line'> = {
      labels: sanitizedLabels,
      datasets: sanitizedData.map((dataset, index) => ({
          data: dataset,
          borderColor: dataSetColors[index] || '#00CF6C',
          backgroundColor: '#00CF6C',
          pointStyle: 'circle' as const,
          pointRadius: 2.5, // Slightly smaller dots to match web version exactly
          borderWidth: 1.5,
          tension: 0,
          fill: false,
      })),
  };
    
      return (
        <div className="w-full bg-white">
          <div>            
            <h3 className="text-lg font-medium text-gray-900 mb-4">{headerTitle}</h3>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div style={{ 
                height: chartHeight, 
                position: 'relative',
                borderRadius: '8px',
                padding: '5px 0',
              }}>
                <Line 
                  data={chartData} 
                  options={options}
                  style={{
                    paddingBottom: '5px' // Extra padding for bottom dots
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };