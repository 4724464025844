import { Button, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useDeleteLocation } from '@hooks/locations';
import { Location } from '@hooks/locations/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';

export type DeleteLocationModalProps = {
  location: Location | undefined;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const DeleteLocationModal: React.FC<DeleteLocationModalProps> = ({
  location,
  isModalOpen,
  handleModalClose,
  handleSuccess,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const { mutateAsync, isLoading } = useDeleteLocation();

  const handleDeleteLocation = async () => {
    try {
      await mutateAsync(location?.id ?? '0');
      addToast({
        variant: Variant.Success,
        message: 'Your location was deleted',
        closeable: true,
      });
      handleSuccess();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Failed to delete location',
        closeable: true,
      });
    }
  };

  return (
    <Modal
      testId='delete-location-modal'
      headerTxt={`Delete ${location?.name ?? ''}`}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
            testId='confirm-delete-location-button'
            variant="danger"
            disabled={isLoading}
            onClick={handleDeleteLocation}
          >
            Confirm: delete location
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose} testId='cancel-delete-location-button'>
            Cancel: keep location
          </Button>
        </div>
      }
      contentClassName="flex !flex-column !items-start !justify-start bg-white"
    >
      <p>
        Are you sure you want to delete this location? All people assigned to
        this location will be unassigned, and you’ll no longer see insights and
        analytics related to it. This can’t be undone.
      </p>
    </Modal>
  );
};
