import React, { useEffect, useState } from 'react';
import { Button, Banner, Checkbox, Drawer } from '@components/ui';
import { QueryParams, SetQueryParams } from '@pages/PeopleAndGroups/types';
import { IOption } from '@common/types';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import styles from './styles.module.css';
import { BaseSetQueryParams } from '@store/queryParamsStore';
import { VENDORS } from '@common/constants';
import cn from 'classnames';
import { Item, TreeViewCheckbox } from '@components/ui/TreeViewCheckbox';
import DatePickerInput from '@components/ui/DatePickerInput';
import useTableStore from '@store/tableStore';
import dayjs from 'dayjs';
import { FilterDropdown } from './FilterDropdown';

export type FilterData = {
  label: string;
  name: string;
  singleSelect?: boolean;
  options: IOption[];
};

type FilterProps<T> = {
  setPageNumber: (pageNumber: number) => void;
  queryParams: QueryParams;
  setQueryParams: SetQueryParams;
  isDrawerOpen: boolean;
  onCloseDrawer: () => void;
  setQueryParamsStore?: BaseSetQueryParams;
  storedQueryParams?: string;
  filterData: FilterData[];
  refetchQuery: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<T, unknown>>;
  hasFilterBanner: boolean;
  headerText: string;
  isLicensePage?: boolean;
  preselectedFilter?: string[];
  hasRangeFilter?: boolean;
  displayAsDropdownFilterItems?: string[];
  testId?: string;
};

export const Filter: <T>(props: FilterProps<T>) => React.JSX.Element = ({
  onCloseDrawer,
  isDrawerOpen,
  storedQueryParams,
  setPageNumber,
  queryParams,
  setQueryParams,
  setQueryParamsStore,
  filterData,
  refetchQuery,
  hasFilterBanner,
  headerText = 'Filter',
  isLicensePage = false,
  preselectedFilter = [],
  hasRangeFilter = false,
  displayAsDropdownFilterItems = [],
  testId = "filter"
}) => {
  const { setStartsAt, setEndsAt, startsAt, endsAt, setShouldApplyRange } =
    useTableStore();

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    storedQueryParams
      ? [
        ...storedQueryParams
          .split(',')
          .filter((filter) => !preselectedFilter.includes(filter)),
        ...preselectedFilter,
      ]
      : queryParams.filter
        ? [
          ...queryParams.filter
            .split(',')
            .filter((filter: string) => !preselectedFilter.includes(filter)),
          ...preselectedFilter,
        ]
        : [...preselectedFilter]
  );

  const [selectedVendor, setSelectedVendor] = useState('');

  const handleFilterClose = () => {
    onCloseDrawer();
    setSelectedFilters(queryParams.filter?.length ? queryParams.filter.split(",") : []);
  };

  useEffect(() => {
    if (isLicensePage && queryParams.filter) {
      const filters = queryParams.filter.split(',');
      if (filters.includes('Microsoft')) {
        setSelectedVendor('Microsoft');
      } else if (filters.includes('RingCentral')) {
        setSelectedVendor('RingCentral');
      }
    }
  }, [isLicensePage, queryParams.filter]);

  const handleCheckboxChange = (option: IOption, filter: FilterData) => {
    if (isLicensePage && filter.name === 'vendor') {
      if (selectedVendor === option.value) {
        setSelectedVendor('');
      } else {
        setSelectedVendor(option.value);
      }
    }
    if (selectedFilters.includes(option.value)) {
      // Remove the value from selectedFilters
      const updatedFilters = selectedFilters.filter(
        (filterValue) => filterValue !== option.value
      );
      setSelectedFilters(updatedFilters);
    } else {
      setSelectedFilters((prevSelectedFilters) => {
        let updatedFilters: string[];

        if (prevSelectedFilters.includes(option.value)) {
          // Remove the value from selectedFilters
          updatedFilters = prevSelectedFilters.filter(
            (filterValue) => filterValue !== option.value
          );
        } else {
          if (filter.singleSelect) {
            updatedFilters = prevSelectedFilters.filter(
              (filterValue) =>
                !filterData
                  .find((f) => f.name === filter.name)
                  ?.options.map((o) => o.value)
                  .includes(filterValue)
            );

            if (filter.name === 'vendor' && option.value === VENDORS[1]) {
              updatedFilters = updatedFilters.filter(
                (filterValue) => !filterValue.startsWith(`${VENDORS[0]} `)
              );
            } else if (
              filter.name === 'vendor' &&
              option.value === VENDORS[0]
            ) {
              updatedFilters = updatedFilters.filter(
                (filterValue) => !filterValue.startsWith(`${VENDORS[1]} `)
              );
            }

            updatedFilters.push(option.value);
          } else {
            // Multiple checkbox selection
            updatedFilters = [...prevSelectedFilters, option.value];
          }
        }

        return updatedFilters;
      });
    }
  };

  useEffect(() => {
    if (dayjs(endsAt).isBefore(startsAt)) {
      setEndsAt(startsAt);
    }
  }, [endsAt, setEndsAt, startsAt]);

  useEffect(() => {
    if (queryParams.from) {
      const fromParam = dayjs(queryParams.from, 'DD/MM/YYYY');

      if (fromParam.isValid()) {
        setStartsAt(fromParam.toDate());
      }
    }

    if (queryParams.to) {
      const toParam = dayjs(queryParams.to, 'DD/MM/YYYY');

      if (toParam.isValid()) {
        setEndsAt(toParam.toDate());
      }
    }
  }, [queryParams.to, queryParams.from, setStartsAt, setEndsAt]);
  const filterCount = queryParams.filter
    ? queryParams.filter
      .split(',')
      .filter((filter: string) => !preselectedFilter.includes(filter)).length
    : 0;

  const totalFilterCount = filterCount + (startsAt || endsAt ? 1 : 0);

  return (
    <Drawer
      testId={testId}
      heading={
        <div className="flex w-full flex-col">
          <p className={styles.filterLabel}>{headerText}</p>
          <div className="flex flex-row">
            <p className={styles.filterAppliedText}>
              <span className="mr-1">{totalFilterCount}</span>
              filters applied
            </p>
            {selectedFilters.length > 0 || startsAt || endsAt ? (
              <button
                type="button"
                className={styles.filterClearAllButton}
                onClick={() => {
                  if (preselectedFilter) {
                    setSelectedFilters([...preselectedFilter]);
                  } else {
                    setSelectedFilters([]);
                    setQueryParams({ filter: undefined }, 'pushIn');
                  }
                  setSelectedVendor('');
                  setQueryParams({ filter: undefined }, 'pushIn');
                  setQueryParamsStore && setQueryParamsStore('filter', '');

                  setShouldApplyRange(true);
                  setQueryParams({ from: undefined }, 'pushIn');
                  setQueryParams({ to: undefined }, 'pushIn');
                  setQueryParamsStore && setQueryParamsStore('from', '');
                  setQueryParamsStore && setQueryParamsStore('to', '');
                  setEndsAt(null);
                  setStartsAt(null);
                  refetchQuery();
                }}
              >
                Clear filters
              </button>
            ) : null}
          </div>
          {hasFilterBanner && (
            <Banner
              message={
                'Set up teams, groups and locations to see more filters here.'
              }
              variant="info"
              className="mb-6 mt-4"
            />
          )}
        </div>
      }
      hasHeaderBanner={hasFilterBanner}
      isOpen={isDrawerOpen}
      onClose={handleFilterClose}
      actionButtons={
        <>
          <Button
            testId='apply-filters-button'
            size="medium"
            disabled={
              !queryParams.from &&
              !queryParams.to &&
              !queryParams.filter &&
              (selectedFilters.length === 0 ||
                JSON.stringify(selectedFilters) ===
                JSON.stringify(preselectedFilter))
            }
            className="w-full"
            variant="primary"
            onClick={() => {
              setShouldApplyRange(true);
              setQueryParams(
                { filter: selectedFilters.join(',') || undefined },
                'pushIn'
              );
              setQueryParamsStore &&
                setQueryParamsStore('filter', selectedFilters.join(','));
              setPageNumber(1);
              onCloseDrawer();
              refetchQuery();
            }}
          >
            Apply filters
          </Button>
        </>
      }
    >
      <div
        className={cn(styles.filterContent, {
          'h-[calc(100vh-200px)]': !hasFilterBanner,
          'h-[calc(100vh-340px)]': hasFilterBanner,
        })}
      >
        {hasRangeFilter && (
          <div className="pb-4">
            <p className={styles.filterSectionLabel}>Date range</p>

            <div className="flex justify-between gap-x-2">
              <div className="md:min-w-[160px] md:max-w-[160px]">
                <p className="mb-2 font-semibold text-interfaceColor-100">
                  From
                </p>
                <DatePickerInput
                  initialValue={startsAt}
                  onChange={(value) => {
                    setShouldApplyRange(false);
                    setStartsAt(value);
                    setQueryParams({
                      from: dayjs(value).format('DD/MM/YYYY').toString(),
                    });
                    setQueryParamsStore &&
                      setQueryParamsStore(
                        'from',
                        dayjs(startsAt).format('DD/MM/YYYY').toString()
                      );
                    if (dayjs(endsAt).isBefore(startsAt)) {
                      setEndsAt(startsAt);
                    }
                  }}
                />
              </div>
              <div className="mr-1 md:min-w-[160px] md:max-w-[160px]">
                <p className="mb-2 font-semibold text-interfaceColor-100">To</p>
                <DatePickerInput
                  onChange={(value) => {
                    setShouldApplyRange(false);
                    setEndsAt(value);

                    setQueryParams({
                      to: dayjs(value).format('DD/MM/YYYY').toString(),
                    });
                    setQueryParamsStore &&
                      setQueryParamsStore(
                        'to',
                        dayjs(endsAt).format('DD/MM/YYYY').toString()
                      );
                  }}
                  initialValue={
                    dayjs(endsAt).isBefore(startsAt) ? startsAt : endsAt
                  }
                  minDate={startsAt || undefined}
                />
              </div>
            </div>
          </div>
        )}
        {filterData.map((item: FilterData) => (
          <div key={item.label} className="pb-4">
            <div>
              <p className={styles.filterSectionLabel}>{item.label}</p>
              {item.label !== 'Groups' ? (
                displayAsDropdownFilterItems.includes(item.label) ?
                  <FilterDropdown
                    handleCheckboxChange={handleCheckboxChange}
                    item={item}
                    selectedFilters={selectedFilters.filter(filterItem => {
                      let isFound = false;
                      item.options.forEach(option => {
                        if (option.value === filterItem) isFound = true;
                      })
                      return isFound;
                    }
                    )}
                  /> :
                  item.options.map((option: IOption) => (
                    <Checkbox
                      showFadeOut={true}
                      className="relative z-[20] mb-4 w-full"
                      labelClassName="whitespace-nowrap w-full overflow-hidden"
                      labelTitle={option.label}
                      key={option.value}
                      label={option.label}
                      name={option.value}
                      checked={selectedFilters.includes(option.value)}
                      disabled={
                        (isLicensePage &&
                          item.name === 'licensetype' &&
                          selectedVendor &&
                          option?.vendor &&
                          option?.vendor !== selectedVendor) ||
                        option.disabled
                      }
                      onChange={() => handleCheckboxChange(option, item)}
                    />
                  ))
              ) : (
                <TreeViewCheckbox
                  key={item.label}
                  selectedCheckboxes={selectedFilters || []}
                  data={item.options as unknown as Item[]}
                  onSelectionChange={(selected) => {
                    const selectedIDs = Object.keys(selected).filter(
                      (key) => selected[key]
                    );

                    setSelectedFilters((prevSelectedFilters) => {
                      const uniquePrevValues = prevSelectedFilters.filter(
                        (prevItem) =>
                          !selectedIDs.includes(prevItem) &&
                          !Object.keys(selected).includes(prevItem)
                      );

                      let updatedFilters = [...uniquePrevValues];

                      selectedIDs.forEach((item) => {
                        if (!updatedFilters.includes(item)) {
                          updatedFilters.push(item);
                        }
                      });

                      updatedFilters = updatedFilters.filter((id) =>
                        selectedIDs.includes(id)
                      );

                      return [
                        ...prevSelectedFilters.filter(
                          (prevItem) =>
                            !selectedIDs.includes(prevItem) &&
                            !Object.keys(selected).includes(prevItem)
                        ),
                        ...updatedFilters,
                      ];
                    });
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};
