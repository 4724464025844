import { Button, Checkbox, MinusIcon, Modal, PlusIcon } from '@components/ui';
import { GroupsResponse } from '@hooks/groups/types';
import { useUsersQuery } from '@hooks/users';
import { User, UserRoles, Permissions } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpsertGroupViewers } from '@hooks/groups';
import useAuthStore from '@store/authStore';
import { hasPermission } from '@utils/index';
import { showBadge, sortUsers } from './utils';
import { Variant } from '@components/ui/Toast';

export enum GroupVisibilityFlow {
  VisibleToAll = 0,
  LimitedVisibility = 1,
  ManageVisibility = 2,
  CreateGroup = 3,
}

export type ManageGroupVisibilityModalProps = {
  group: GroupsResponse | undefined;
  flow: GroupVisibilityFlow | null;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const ManageGroupVisibilityModal: React.FC<
  ManageGroupVisibilityModalProps
> = ({ group, flow, isModalOpen, handleModalClose, handleSuccess }) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { addToast } = useUIStore();
  const { groupData, setGroupData } = useUIStore();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [viewersIds, setViewersIds] = useState<string[]>([]);
  const query = useUsersQuery({ pageSize: 200, pageNumber: 1 });
  const updateGroupViewers = useUpsertGroupViewers(group?.id);
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const loggedInUserId = useAuthStore.getState().user?.id || '';
  let sortedUsers: User[] = [];
  const changeVisibilityHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  useEffect(() => {
    setSelectedIds([]);
    const ids: string[] = [];
    if (group) {
      group.groupViewers?.forEach((user) => {
        ids.push(user.userId);
      });
      setViewersIds(ids);

      if (group && group.id) {
        setSelectedIds(ids);
      }
    }
  }, [group]);

  const handleCheckboxChange = (id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prevIds) => prevIds.filter((itemId) => itemId !== id));
    } else {
      setSelectedIds((prevIds) => [...prevIds, id]);
    }
  };

  const handleSubmit = async () => {
    if (flow === GroupVisibilityFlow.ManageVisibility) {
      try {
        const difference = viewersIds.filter(
          (item) => !selectedIds.includes(item)
        );
        const data = {
          viewersToRemove: difference,
          viewersToAdd: selectedIds,
        };
        await updateGroupViewers.mutateAsync(data);
        addToast({
          variant: Variant.Success,
          message: 'Changes are saved',
          closeable: true,
        });
        setSelectedIds([]);
        setViewersIds([]);
        setIsAllSelected(false);
        sortedUsers = [];
        group = undefined;
        handleSuccess();
      } catch (error) {
        addToast({
          variant: Variant.Error,
          message: 'Failed to edit group visibility',
          closeable: true,
        });
      }
    } else {
      setGroupData({ ...groupData, hubUsersIds: selectedIds });
      setSelectedIds([]);
      setViewersIds([]);
      setIsAllSelected(false);
      sortedUsers = [];
      group = undefined;
      navigate(`/accounts/groups/assign-people`);
    }
  };

  const selectAllUsers = () => {
    const ids: string[] = [];
    sortedUsers.forEach((user) => {
      ids.push(user.id);
    });
    setSelectedIds(ids);
    setIsAllSelected(true);
  };

  const unselectAllUsers = () => {
    setSelectedIds([]);
    setIsAllSelected(false);
  };

  if (query.isFetched) {
    sortedUsers = sortUsers(query?.data?.items || [], loggedInUserId);
  }

  return (
    <Modal
      testId='who-can-see-group-modal'
      headerTxt={`Who can see this group?`}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
           testId='next-button'
            variant="primary"
            disabled={query.isLoading || !changeVisibilityHasPermission}
            onClick={handleSubmit}
          >
            {flow === GroupVisibilityFlow.CreateGroup
              ? 'Next: add people to group'
              : 'Save changes'}
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose} testId='cancel-button'>
            Cancel
          </Button>
        </div>
      }
      contentClassName="flex bg-white"
    >
      <div className="relative h-full max-h-full w-full md:h-[350px] md:max-h-[350px] ">
        <>
          <p className="mb-2 font-bold">
            Select the Hub users who can see this group
          </p>

          <div className="absolute h-full max-h-[calc(100%-100px)]  w-full overflow-y-auto md:max-h-[300px]">
            {sortedUsers.length > 0 && (
              <div>
                {sortedUsers.map((user) => (
                  <div className="mb-1" key={user.id}>
                    <div className="flex">
                      <Checkbox
                        defaultClassName="mt-2"
                        name={user.id}
                        disabled={
                          user.role === UserRoles.SUPERADMIN ||
                          (user.role === UserRoles.ADMIN &&
                            user.id === loggedInUserId) ||
                          user.isViewerInherited ||
                          (viewersIds.includes(user.id) && group && !group.id)
                        }
                        checked={
                          !!(
                            user.role === UserRoles.SUPERADMIN ||
                            (user.role === UserRoles.ADMIN &&
                              user.id === loggedInUserId) ||
                            selectedIds.includes(user.id) ||
                            (viewersIds.includes(user.id) && group && !group.id)
                          )
                        }
                        label={user.fullName}
                        labelClassName={'mt-2'}
                        onChange={() => handleCheckboxChange(user.id)}
                      />
                      <span className="mt-[9px] flex">{showBadge(user)}</span>
                    </div>
                    <div>
                      <span className="ml-[37px] text-interfaceColor-80">
                        {user.emailAddress}
                      </span>
                    </div>
                  </div>
                ))}
                <div className="fixed bottom-36 md:bottom-[6rem]">
                  {!isAllSelected && (
                    <Button
                      testId='select-all-users-button'
                      size="small"
                      variant="default"
                      hidden={query.isLoading}
                      className="mt-2 bg-white p-0 pl-0 hover:bg-white"
                      onClick={selectAllUsers}
                    >
                      <PlusIcon classNames="ml-1" />
                      <span className="ml-2 underline">
                        Select all Hub users
                      </span>
                    </Button>
                  )}

                  {isAllSelected && (
                    <Button
                      testId='uselect-all-users-button'
                      size="small"
                      variant="default"
                      hidden={query.isLoading}
                      className="mt-2 bg-white p-0 pl-0 hover:bg-white"
                      onClick={unselectAllUsers}
                    >
                      <MinusIcon classNames="ml-1" />
                      <span className="ml-2 underline">
                        Unselect all Hub users
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </Modal>
  );
};
