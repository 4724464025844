import {
  DEVICE_INVENTORY_SORT_BY,
  DEVICE_INVENTORY_TABLE_COLUMNS,
  DEFAULT_PAGE_SIZE,
  ONLINE_STATUS,
  VENDORS,
} from '@common/constants';
import { OverlayPage, TableWrapper } from '@components/partials';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import {
  formatDate,
  getFilterValues,
  getFirstMatchedFilterValue,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { createCellValue } from '../utils';
import { useLocation } from 'react-router-dom';
import {
  useDeviceFiltersQuery,
  useDeviceInventoryPreviewQuery,
  useDeviceInventoryTypesQuery,
} from '@hooks/utilisation';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import {
  DevicesFilterData,
  DevicesFiltersResponse,
} from '@hooks/utilisation/types';
import { Location } from '@hooks/locations/types';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { useIsDesktop } from '@hooks/utils';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { GroupsResponse } from '@hooks/groups/types';
import styles from './styles.module.css';

const pageSize = DEFAULT_PAGE_SIZE;

const DeviceInventoryAssignmentsPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'device'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );

  const { data: typesData, isFetched: typesDataFetched } =
    useDeviceInventoryTypesQuery();
  const { data: filterData } = useDeviceFiltersQuery();
  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
  };

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  const typesFilerOptions: IOption[] = [];
  const transformedFilterData: FilterData[] = [];
  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];

  if (filterData) {
    Object.keys(filterData).forEach((property) => {
      if (property === 'vendor') {
        const filterDataArray: FilterData = {
          label: getPropertyLabel(property),
          name: property.toLowerCase(),
          options: [],
          singleSelect: !!(property === 'vendor'),
        };

        const propertyData =
          filterData[property as keyof DevicesFiltersResponse];

        if ('value' in propertyData) {
          const item = propertyData as DevicesFilterData;

          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
          };

          filterDataArray.options.push(option);
        } else {
          const items = propertyData as unknown as DevicesFilterData[];

          items.forEach((item) => {
            const option = {
              label: item.value || item.vendor,
              value: item.value || item.vendor,
            };

            filterDataArray.options.push(option);
          });
        }

        transformedFilterData.push(filterDataArray);
      }
    });
  }

  if (typesDataFetched) {
    if (typesData) {
      typesData?.forEach((type) => {
        typesFilerOptions.push({ label: type, value: type });
      });

      transformedFilterData.push({
        label: 'Type',
        name: 'type',
        singleSelect: false,
        options: typesFilerOptions,
      });
    }

    transformedFilterData.push({
      label: 'Online status',
      name: 'onlineStatus',
      singleSelect: true,
      options: [
        { label: 'Online', value: 'Online' },
        { label: 'Offline', value: 'Offline' },
      ],
    });
  }

  if (locationDataFetched && groupsDataFetched) {
    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  // table
  const query = useDeviceInventoryPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    typeList:
      queryParams.filter && typesData
        ? queryParams.filter
            .split(',')
            .filter((filter) => typesData.includes(filter))
        : [],
    vendorList: getFilterValues(queryParams, VENDORS),
    onlineStatus: getFirstMatchedFilterValue(queryParams, ONLINE_STATUS),
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
  });
  const getTableData = useTableData(
    query,
    DEVICE_INVENTORY_TABLE_COLUMNS,
    createCellValue
  );
  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: API_ENDPOINTS.HARDWARE_INVENTORY_EXPORT,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      typeList:
        queryParams.filter && typesData
          ? queryParams.filter
              .split(',')
              .filter((filter) => typesData.includes(filter))
          : [],
      vendorList: getFilterValues(queryParams, VENDORS),
      onlineStatus: getFirstMatchedFilterValue(queryParams, ONLINE_STATUS),
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
    },
    filename: `Clevr360_Device_inventory_Assignments_${formatDate(
      new Date().toString()
    )}.csv`,
  });
  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <div>
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <RecommendationCard insightDescription={'device inventory'} />
          </>
        )}
      </div>
      <div className="mt-[15px]">
        <TableWrapper
          testId='device-inventory-table'
          search={search}
          setSearch={setSearch}
          searchPlaceholder={
            'Search by device, name, email address, serial number'
          }
          columns={DEVICE_INVENTORY_TABLE_COLUMNS}
          data={getTableData()}
          sortData={DEVICE_INVENTORY_SORT_BY}
          filterData={transformedFilterData}
          searchKey="search"
          query={query}
          refetchQuery={query.refetch}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          setPageNumber={setPageNumber}
          isCollapsable={true}
          sliceColumns={1}
          floatingFilterButton={false}
          isDownloadAvailable={true}
          hasFilterBanner={false}
          isLicensePage={true}
          searchCountStatsLabel="devices"
          filterHeaderText="Filter device inventory"
          handleDownload={handleDownloadClick}
          noResultTitle={
            search
              ? 'No results matching your search'
              : 'You have no devices configured'
          }
          noResultSubtitle={
            search
              ? 'Try a less specific search.'
              : 'Data will appear here once devices have been configured.'
          }
        />
      </div>
    </div>
  );
};

export default DeviceInventoryAssignmentsPage;
