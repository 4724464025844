import { Button, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useDeactivateService } from '@hooks/connectedServices';
import { ServiceIntergrationResponse } from '@hooks/connectedServices/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';
import { getVendorName } from '@utils/index';

export type DeactivateServiceModalProps = {
  service: ServiceIntergrationResponse | undefined;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const DeactivateServiceModal: React.FC<DeactivateServiceModalProps> = ({
  service,
  isModalOpen,
  handleModalClose,
  handleSuccess,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const { mutateAsync, isLoading } = useDeactivateService();

  const handleDeactivate = async () => {
    try {
      await mutateAsync(service?.connectionId ?? '0');
      addToast({
        variant: Variant.Success,
        message: `${getVendorName(service?.vendor)} has been deactivated.`,
        closeable: true,
      });
      handleSuccess();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Something went wrong, and your changes haven`t been saved.',
        closeable: true,
      });
    }
  };

  return (
    <Modal
      testId='deactivate-vendor-modal'
      headerTxt={`Are you sure you want to deactivate ${getVendorName(
        service?.vendor
      )}?`}
      isTruncatedHeader={false}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
            testId='confirm-deactivate-device-button'
            type="submit"
            variant="danger"
            disabled={isLoading}
            onClick={handleDeactivate}
          >
            Confirm: deactivate service
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose} testId='cancel-deactivate-device-button'>
            Cancel: keep service
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div>
        If you deactivate this service, you won`t be able to access up-to-date
        data about it in the Hub, and it won`t feed into new insights and
        analytics. You`ll still be able to see historic data from this service.
      </div>
    </Modal>
  );
};
