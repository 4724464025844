import { notificationsConfig, NotificationTypes } from '@common/constants';
import { NoResults, OverlayPage } from '@components/partials';
import { Banner, Loader } from '@components/ui';
import {
  useDismissNotification,
  useNotificationInfiniteScroll,
} from '@hooks/notifications';
import { Notification } from '@hooks/notifications/types';
import useUIStore, { NavSource } from '@store/uiStore';
import {
  formatDate,
  getBannerMessage,
  getVariant,
  groupAndSortNotifications,
  sortKeysByDate,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type GroupedNotifications = Record<string, Notification[]>;

export const NotificationsListPage: React.FC = () => {
  const navigate = useNavigate();
  const { setNavSource } = useUIStore();
  const {
    data: notificationsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
  } = useNotificationInfiniteScroll();

  const [sortedData, setSortedData] = useState<GroupedNotifications>({});
  const [dismissedNotifications, setDismissedNotifications] = useState<
    string[]
  >([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  const dismissNotificationMutation = useDismissNotification();

  const handleDismiss = (notificationId: string) => {
    dismissNotificationMutation.mutate(notificationId, {
      onSuccess: () => {
        setDismissedNotifications((prev) => [...prev, notificationId]);
      },

      onError: (error) => {
        console.error('Failed to dismiss notification:', error);
      },
    });
  };

  useEffect(() => {
    if (notificationsData?.pages) {
      const allNotifications = notificationsData.pages.flat();

      const groupedNotifications = groupAndSortNotifications(allNotifications);
      const sortedKeys = sortKeysByDate(Object.keys(groupedNotifications));
      const orderedGroupedNotifications: GroupedNotifications = {};
      sortedKeys.forEach((key) => {
        orderedGroupedNotifications[key] = groupedNotifications[key];
      });

      setSortedData(orderedGroupedNotifications);

      const lastPageLength =
        notificationsData.pages[notificationsData.pages.length - 1].length;
      setAllDataLoaded(lastPageLength < 20);
    }
  }, [notificationsData]);

  const haveResults =
    !isLoading && !isError && notificationsData?.pages?.[0]?.[0]?.id;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <OverlayPage
      allDataLoaded={allDataLoaded}
      onScrollToEnd={() => {
        if (!isFetchingNextPage && hasNextPage) {
          fetchNextPage();
        }
      }}
      headerTitle="All notifications"
      path="/"
      isFooterVisible={false}
      isLoading={isLoading || isFetchingNextPage}
    >
      {!haveResults ? (
        <NoResults
          headerTxt="No notifications"
          contentTxt="You`re all caught up!"
        />
      ) : (
        <>
          {Object.keys(sortedData)?.map((i, index) => (
            <div key={i}>
              <h3 className={`mb-3 ${index !== 0 ? 'mt-7' : ''} text-20`}>
                {formatDate(i)}
              </h3>
              {sortedData[i]
                .filter((item) => !dismissedNotifications.includes(item.id))
                .map((item: Notification) => (
                  <Banner
                    key={item.id}
                    className="mb-3"
                    message={getBannerMessage(
                      item.type,
                      item.severity,
                      item.value
                    )}
                    variant={getVariant(item.severity)}
                    closeable={false}
                    onClose={() => handleDismiss(item.id)}
                    ctaBtnLabel={`${
                      notificationsConfig.get(`${item.type}|${item.severity}`)
                        ?.btnLabel ?? ''
                    }`}
                    ctaBtnOnClick={() => {
                      const link = notificationsConfig.get(`${item.type}|${item.severity}`)?.link
                        ?? '';

                      if (item.type === NotificationTypes.TypeTwelve)
                      {
                        window.open(link, "_blank");
                      } else {
                        setNavSource(NavSource.NotificationPage);
                        navigate(link);
                      }
                    }}
                  />
                ))}
            </div>
          ))}
        </>
      )}
    </OverlayPage>
  );
};
