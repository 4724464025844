import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import React from 'react';
import cn from 'classnames';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type DatasetType = {
  data: number[];
  backgroundColor: string[];
};

type HorizontalBarChartPdfProps = {
  datasets: DatasetType[];
  labels: string[];
  options?: ChartOptions<'bar'>;
  classNames?: string;
  height?: number;
};

const HorizontalBarChartPdf: React.FC<HorizontalBarChartPdfProps> = ({
  datasets,
  labels,
  classNames = '',
  options,
  height = 100,
}) => {
  const sanitizedLabels = labels?.filter(l => l != null).map(l => String(l)) || [];
  const sanitizedDatasets = datasets?.map(dataset => ({
    ...dataset,
    data: dataset.data?.map(d => Number(d) || 0) || [],
    borderRadius: 2,
    barPercentage: 0.3,
    categoryPercentage: 1,
  })) || [];

  const data: ChartData<'bar', number[], string> = {
    labels: sanitizedLabels,
    datasets: sanitizedDatasets,
  };

  const defaultOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    devicePixelRatio: 2,
    elements: {
      bar: {
        borderWidth: 0,
        borderRadius: 4,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      }
    },
    layout: {
      padding: {
        left: 3,
        right: 16,
        top: 8,
        bottom: 8,
      }
    },
    scales: {
      x: {
        min: 0,
        max: 100,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: true,
          padding: 0,
          font: {
            size: 14,
            family: 'Roobert, -apple-system, BlinkMacSystemFont, sans-serif',
            weight: 'normal',
            lineHeight: '1.5',
          },
          color: '#111827',
          crossAlign: 'far',
          align: 'start'          
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        offset: true,
      },
    },
  };

  const chartOptions = options
    ? { ...defaultOptions, ...options }
    : defaultOptions;

  return (
    <div className="w-full  text-gray-800">
      <Bar 
        height={height} 
        data={data} 
        options={chartOptions}
      />
    </div>
  );
};

export default HorizontalBarChartPdf;