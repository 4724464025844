import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import closeIcon from '@assets/images/close_icon.png';
import { DEFAULT_ANIMATION_DURATION } from '@common/constants';
import { Button } from '@components/ui';
import styles from './styles.module.css';
import cn from 'classnames';
import { useIsMobile } from '@hooks/utils';

export interface IDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  heading?: React.ReactNode;
  actionButtons?: React.ReactNode;
  hasHeaderBanner?: boolean;
  testId?: string
}

export const Drawer: React.FC<IDrawerProps> = ({
  children,
  isOpen,
  onClose,
  heading,
  actionButtons,
  hasHeaderBanner = false,
  testId = "drawer"
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        nodeRef.current &&
        !nodeRef.current.contains(event.target as Node) &&
        !isMobile
      ) {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);
  return (
    <>
      {isOpen && <div className={styles.overlay}></div>}
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={DEFAULT_ANIMATION_DURATION}
        classNames={{
          enter: styles.drawerEnter,
          enterActive: styles.drawerEnterActive,
          exit: styles.drawerExit,
          exitActive: styles.drawerExitActive,
        }}
        unmountOnExit
        appear
        data-testid={testId}
      >
        <div ref={nodeRef} className={styles.wrapper}>
          <div
            className={cn(styles.headerWrapper, {
              '!h-[220px]': hasHeaderBanner,
            })}
          >
            {heading ? heading : <p className={styles.headerText}>Drawer</p>}

            <div className="absolute right-[32px] top-[32px]">
              <button className="h-[36px] w-[36px]" onClick={onClose}>
                <img src={closeIcon} alt="Close Drawer" />
              </button>
            </div>
          </div>

          <div className="px-8">{children && children}</div>

          <div className={styles.footerWrapper}>
            <div className={styles.actionBtns}>
              {actionButtons && actionButtons}
              <Button
                testId='drawer-close-button'
                onClick={onClose}
                size="medium"
                className="w-full"
                variant="outlineLight"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
