import React, { useState } from 'react';
import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

import styles from './styles.module.css';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { DashboardIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useMfaStatusSnapshotQuery,
  useNoLicencesSnapshotQuery,
  useSharedMailboxesSnapshotQuery,
} from '@hooks/security';
import { MicrosoftSecureScore } from '@pages/Dashboard/tiles/MicrosoftSecureScore';
import { ExternalTeamsAccess } from '@pages/Dashboard/tiles/ExternalTeamsAccess';
import { FailedLoginCountries } from '@pages/Dashboard/tiles/FailedLoginCountries';
import {
  CallForwardingDestinations,
  FailedLoginAccounts,
  MailboxesForwardingRules,
  OutDatedOperatingSystemsActivity,
  SuspiciousActivityTrend,
} from '@pages/Dashboard/tiles';
import { ExternalAppsMSData } from '@pages/Dashboard/tiles/ExternalAppsMSData';
import { FailedLoginTypes } from '@pages/Dashboard/tiles/FailedLoginTypes';
import { OutdatedBrowserActivity } from '@pages/Dashboard/tiles/OutdatedBrowserActivity';
import { DelegatedMailboxData } from '@pages/Dashboard/tiles/DelegatedMailboxData';
import useServicesStore from '@store/servicesStore';
import { isHelixDataVisible, isTraditionalCommsDataVisible } from '@utils/index';

const SecurityPage: React.FC = () => {
  const { helixStatus, traditionalCommsStatus } = useServicesStore();
  const isHelixVisible = isHelixDataVisible(helixStatus, 'securityTab');
  const isTraditionalCommsVisible = isTraditionalCommsDataVisible(traditionalCommsStatus);

  const [mfaStatus, setMfaStatus] = useState('All');

  const { data: mfaStatusData, isLoading: isMfaStatusLoading } =
    useMfaStatusSnapshotQuery({
      userType: mfaStatus === 'All' ? '' : mfaStatus,
    });

  const { data: signInEnabledData, isLoading: isSignInEnabledLoading } =
    useNoLicencesSnapshotQuery();

  const { data: sharedMailboxesData, isLoading: isSharedMailboxesLoading } =
    useSharedMailboxesSnapshotQuery();

  const handleMfaStatusDropdown = (value: string) => {
    setMfaStatus(value);
  };

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.MicrosoftMFAStatus:
          return {
            userType: mfaStatus === 'All' ? '' : mfaStatus,
          };
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const createSortMfaMenuItems = (): MenuItem[] => [
    {
      id: '1',
      label: 'All users',
      action: () => handleMfaStatusDropdown('All'),
    },
    {
      id: '2',
      label: 'Admin users',
      action: () => handleMfaStatusDropdown('Admin'),
    },
    {
      id: '3',
      label: 'Non-admin users',
      action: () => handleMfaStatusDropdown('Standard'),
    },
  ];

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          {isHelixVisible && <SuspiciousActivityTrend
            classNames="col-span-6"
            params={JSON.stringify({ days: '7' })}
          />}

          <MicrosoftSecureScore params="" />

          {isHelixVisible && <ExternalAppsMSData params={''} />}

          <ExternalTeamsAccess params="" />
        </div>
      </div>

      <h1 className={styles.header}>Login security</h1>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          {isHelixVisible && <>
            <FailedLoginAccounts
              params={JSON.stringify({ days: '30', mfaStatus: 'all' })}
            />
            <FailedLoginCountries params={JSON.stringify({ days: '30' })} />
            <FailedLoginTypes params={JSON.stringify({ days: '30' })} />
          </>}
          {mfaStatusData && (mfaStatusData.usersWithoutMfa !== 0 || mfaStatusData.usersWithMfa !== 0) && (
          <DoughnutChartTile
            contextData={createMenuItems(DashboardTiles.MicrosoftMFAStatus)}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
            sortData={createSortMfaMenuItems()}
            headerTitle={'MFA status'}
            linkPath={ANALYTICS_NAVIGATION_MAP['mfaStatus']}
            isLoading={isMfaStatusLoading}
            data={Object.values(mfaStatusData)}
            dataLabels={['Users without MFA', 'Users with MFA']}
            buttonLabel={'MFA data'}
          />
        )}
          <NumberTile
            contextData={createMenuItems(
              DashboardTiles.UsersWithSignInEnabledButNoLicenses
            )}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
            percentageLabel=""
            headerTitle={'Users with sign-in enabled but no licenses'}
            isLoading={isSignInEnabledLoading}
            data={signInEnabledData ? Object.values(signInEnabledData) : []}
            buttonLabel={'Unassigned user data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['signInEnabled']}
          />
        </div>
      </div>

      <h1 className={styles.header}>Mailboxes</h1>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          {isHelixVisible &&
            <MailboxesForwardingRules
              classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
              params={''}
              isSnapshot={false}
            />}
          <NumberTile
            contextData={createMenuItems(
              DashboardTiles.SharedMailboxesWithSignInEnabled
            )}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
            percentageLabel=""
            headerTitle={'Shared mailboxes with sign-in enabled'}
            isLoading={isSharedMailboxesLoading}
            data={sharedMailboxesData ? Object.values(sharedMailboxesData) : []}
            buttonLabel={'Shared mailbox data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['sharedMailboxes']}
          />
          <DelegatedMailboxData params={JSON.stringify({ days: '30' })} />
        </div>

        {isTraditionalCommsVisible && <>
          <h1 className={styles.header}>Calling</h1>
          <div className={styles.pageContainer}>
            <div className={styles.pageGrid}>
              <CallForwardingDestinations isSnapshot={false} params={""} />
            </div>
          </div>
        </>
        }
        {isHelixVisible && <>
          <h1 className={styles.header}>Outdated browsers and operating systems</h1>
          <div className={styles.pageContainer}>
            <div className={styles.pageGrid}>
              <OutdatedBrowserActivity
                classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
                params={JSON.stringify({
                  days: '30',
                  browserType: '',
                  accountType: '',
                })}
                isSnapshot={false}
              />
              <OutDatedOperatingSystemsActivity
                params={JSON.stringify({ days: '30' })}
              />
            </div>
          </div>
        </>}
      </div>
    </>
  );
};

export default SecurityPage;
