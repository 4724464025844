import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@components/ui';
import {
  useUpdateModalStatus,
  useUserOnboardingStatusModalsQuery,
} from '@hooks/users';
import { ModalsStatus, OnboardingModalStatus } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useAuthStore from '@store/authStore';

export type WelcomeModalProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  modalKey: string;
  btnText: string;
};

export const WelcomeModal: React.FC<WelcomeModalProps> = ({
  title,
  content,
  modalKey,
  btnText,
}) => {
  const setModalsStatus = useAuthStore((state) => state.setModalsStatus);
  const modalsStatusFromStore = useAuthStore((state) => state.modalsStatus);

  const modalIsOpenFromStore =
    modalsStatusFromStore[modalKey as keyof typeof modalsStatusFromStore];

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const updateModalStatus = useUpdateModalStatus();
  const { refetch } = useUserOnboardingStatusModalsQuery(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(!modalIsOpenFromStore);
    }, 500);

    return () => clearTimeout(timer);
  }, [modalIsOpenFromStore]);

  const handleModalClose = async () => {
    const updatedStatus: OnboardingModalStatus = {
      modalsStatus: { ...modalsStatusFromStore, [modalKey]: true },
    };

    updateModalStatus.mutate(updatedStatus, {
      onSuccess: async () => {
        const response = await refetch();
        if (response?.data) {
          setModalsStatus(
            response.data.modalsStatus as unknown as ModalsStatus
          );
        }
      },
    });
  };

  const modalSize = isTablet || isMobile ? 'small' : 'medium';
  const modalPosition = isMobile ? 'bottom' : 'default';

  return (
    <Modal 
      testId='welcome-modal'
      headerTxt={title}
      modalClassName="h-full mt-0"
      isOpen={isOpen}
      size={modalSize}
      position={modalPosition}
      showCloseBtn={true}
      onClose={handleModalClose}
      closeOnClickOutside={false}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button variant="primary" onClick={handleModalClose} testId='welcome-button'>
            {btnText}
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div>{content}</div>
    </Modal>
  );
};
