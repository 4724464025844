import { OverlayPage } from '@components/partials/OverlayPage';

import React, { useState } from 'react';

import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import enhancedSecurity from '@assets/images/enhanced-security-large.png';
import { Button } from '@components/ui/Button';
import { CheckCircleIcon, PlusIcon } from '@components/ui/Icons';
import { Modal } from '@components/ui';
import { useIsMobile } from '@hooks/utils';
import { useNavigate } from 'react-router-dom';

const EnhancedSecurityPage: React.FC = () => {
  const redirectPath = useNavSourceRedirect(
    '/settings/manage-connected-services'
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const permissions = [
    { name: 'Application.Read.All', description: 'Read all applications' },
    { name: 'AuditLog.Read.All', description: 'Read all audit log data' },
    { name: 'Device.Read.All', description: 'Read all devices' },
    { name: 'Directory.Read.All', description: 'Read directory data' },
    {
      name: 'IdentityRiskEvent.Read.All',
      description: 'Read all identity risk event information',
    },
    {
      name: 'IdentityRiskyUser.Read.All',
      description: 'Read all identity risky user information',
    },
    {
      name: 'MailboxSettings.Read',
      description: 'Read all user mailbox settings',
    },
    {
      name: 'Policy.Read.All',
      description: "Read your organization's policies",
    },
    { name: 'Reports.Read.All', description: 'Read all usage reports' },
    { name: 'SecurityAlert.Read.All', description: 'Read all security alerts' },
    {
      name: 'SecurityEvents.Read.All',
      description: 'Read all security events',
    },
    { name: 'User.Read.All', description: 'Read all users' },
    { name: 'User.Read (Delegated permission)', description: 'Sign in and read user profile' },
    { name: 'UserAuthenticationMethod.Read.All', description: 'Read all users\' authentication methods' },
  ];

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={``}
    >
      <div className="bg-white py-0 lg:bg-transparent lg:py-2">
        <div className="container mx-auto px-0 md:px-6 xl:px-16">
          <div className="relative h-auto rounded-[4px] bg-interfaceColor-90 shadow-inner xl:h-[505px]">
            <div className="flex h-full flex-col lg:flex-row">
              <div className="flex h-full flex-col justify-between gap-y-4 p-8 lg:w-1/2">
                <h2 className="max-w-[500px] text-32 leading-[32px] text-white lg:text-[60px] lg:leading-[60px]">
                  In-depth
                  <br /> assurance
                  <br /> insights at your
                  <br /> fingertips
                </h2>
                <p className="max-w-[500px] text-16 text-white">
                  Enhanced Assurance Insights give you more knowledge than ever
                  before about how safe your Microsoft estate is, and how to
                  make it safer for your organisation.
                </p>
                <Button
                  testId='get-enhanced-assurance-insights-button'
                  variant="primary"
                  className="flex w-full items-center lg:w-fit"
                  size="medium"
                  onClick={() =>
                    navigate(
                      '/settings/enhanced-security/technical-information'
                    )
                  }
                >
                  <PlusIcon
                    color="text-interfaceColor-100"
                    classNames="mr-2 mt-[2px]"
                    size="3"
                  />
                  <span className="text-14 text-interfaceColor-100 lg:text-16">
                    Get Enhanced Assurance Insights
                  </span>
                </Button>
              </div>
              <div className="lg:w-1/2">
                <img
                  src={enhancedSecurity}
                  alt="Assurance Insights"
                  className="relative top-auto h-auto w-full object-cover lg:-top-6 lg:w-[548px] xl:absolute xl:right-4 xl:h-[524px]"
                />
              </div>
            </div>
          </div>
          <div className="relative z-10 mx-auto -mt-44 block max-w-2xl bg-white px-4 py-4 shadow-sm lg:mt-12 lg:bg-transparent lg:px-0 lg:py-0 lg:shadow-none ">
            <h3 className="mb-1 text-24 leading-[26px] text-interfaceColor-100 lg:text-28 lg:leading-[38px]">
              Everything you need to know to stay safe
            </h3>
            <p className="mb-2 text-16 text-interfaceColor-100">
              Discover how this add-on can revolutionise your security strategy
              with a fresh wave of assurance insights tailored specifically for
              Clevr360 users:
            </p>
            <ul className="list-inside space-y-4">
              <li>
                <CheckCircleIcon
                  color={'text-interfaceColor-100'}
                  classNames="h-4 w-4 mr-2 align-middle inline -mt-[2px]"
                />
                <strong>Uncover suspicious user account activity:</strong> Gain
                deep insights into user behaviour, including suspicious travel
                patterns, intrusive app usage, MFA failures, and admin login
                activity.
              </li>
              <li>
                <CheckCircleIcon
                  color={'text-interfaceColor-100'}
                  classNames="h-4 w-4 mr-2 align-middle inline -mt-[2px]"
                />
                <strong>Manage external apps accessing Microsoft data:</strong>{' '}
                Track and control permissions granted to external apps during
                user registration ensuring your data remains secure.
              </li>
              <li>
                <CheckCircleIcon
                  color={'text-interfaceColor-100'}
                  classNames="h-4 w-4 mr-2 align-middle inline -mt-[2px]"
                />
                <strong>Monitor and mitigate failed Microsoft logins:</strong>{' '}
                Identify and address failed login attempts swiftly by
                pinpointing the user account, login location, and frequency of
                failed logins.
              </li>
              <li>
                <CheckCircleIcon
                  color={'text-interfaceColor-100'}
                  classNames="h-4 w-4 mr-2 align-middle inline -mt-[2px]"
                />
                <strong>Stay ahead of the curve with software updates:</strong>{' '}
                Keep your systems secure by identifying and addressing outdated
                operating systems and internet browsers promptly.
              </li>
              <li>
                <CheckCircleIcon
                  color={'text-interfaceColor-100'}
                  classNames="h-4 w-4 mr-2 align-middle inline -mt-[2px]"
                />
                <strong>Enhance mailbox security:</strong> Detect and prevent
                potential threats by identifying mailboxes with auto-forwarding
                enabled and monitoring delegated access across all mailboxes.
              </li>
            </ul>
            <p className="mb-6 mt-3 text-gray-700">
              Harness the power of Clevr360 to safeguard your
              organisation&apos;s digital assets and ensure peace of mind.
            </p>
            <h3 className="mb-1 text-28 text-interfaceColor-100">
              Get Enhanced Assurance Insights
            </h3>
            <p className="mb-6 text-gray-700">
              The activation process takes a couple of days as it requires a
              manual step by a Clevr360 engineer. Once configuration is
              completed you will be notified and sent a link for you to accept a
              set of read-only permissions within Microsoft365.{' '}
              <span
                onClick={() => setIsModalOpen(true)}
                className="cursor-pointer border-b border-b-interfaceColor-40"
              >
                See required permissions here
              </span>
              .
            </p>
            <Button
              testId='get-enhanced-assurance-insights-navigate-button'
              variant="primary"
              className="flex w-full items-center"
              size="medium"
              onClick={() =>
                navigate('/settings/enhanced-security/technical-information')
              }
            >
              <PlusIcon
                color="text-interfaceColor-100"
                classNames="mr-2 mt-[2px]"
                size="3"
              />
              <span className="text-interfaceColor-100">
                Get Enhanced Assurance Insights
              </span>
            </Button>
          </div>
        </div>
        <Modal
          testId='enhanced-assurance-insights-permissions-modal'
          headerTxt={'Enhanced Assurance Insights permissions'}
          isTruncatedHeader={false}
          isOpen={isModalOpen}
          modalClassName={'h-full mt-0'}
          size={'medium'}
          position={isMobile ? 'bottom' : 'default'}
          showCloseBtn={true}
          onClose={() => {
            setIsModalOpen(false);
          }}
          actionButtons={
            <div className="flex flex-col gap-2 md:flex-row"></div>
          }
          contentClassName="flex flex-column !items-start !justify-start bg-white"
        >
          <div className="w-full">
            <p className="mb-4">
              Once configuration is completed, you’ll be asked to accept these
              read-only application permissions.
            </p>
            <div className="max-h-[400px] overflow-y-scroll rounded-lg border border-interfaceColor-40 p-2 md:max-h-[300px]">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                {permissions.map((permission, index) => (
                  <div key={index}>
                    <h4 className="text-14 font-semibold text-interfaceColor-100">
                      {permission.name}
                    </h4>
                    <p className="text-14 text-interfaceColor-100">
                      {permission.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </OverlayPage>
  );
};

export default EnhancedSecurityPage;
