import { MAX_SUBGROUP_LEVEL } from '@common/constants';
import { Button, Checkbox, Modal, TreeView } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import {
  useGroupsQuery,
  useMoveGroup,
  useUpdateGroupMembers,
} from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useAuthStore from '@store/authStore';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import { isSingularOrPluralPeopleText } from '@utils/index';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type GroupTreeModalProps = {
  isModalOpen: boolean;
  handleGroupModalClose: () => void;
  group?: GroupsResponse | undefined;
  handleSuccess?: () => void;
  modalHeader?: string;
  buttonSubmitText?: string;
  selectedGroups?: string[];
  memberId?: string;
};

export const GroupTreeModal: React.FC<GroupTreeModalProps> = ({
  isModalOpen,
  handleGroupModalClose,
  group,
  handleSuccess,
  modalHeader,
  buttonSubmitText,
  selectedGroups,
  memberId,
}) => {
  const { addToast } = useUIStore();
  const { allSelectedItems } = useTableStore();
  const navigate = useNavigate();
  const [isCurrentGroupKept, setIsCurrentGroupKept] = useState(
    memberId !== '' || false
  );
  const [newSelectedGroupId, setNewSelectedGroupId] = useState('');
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const query = useGroupsQuery();
  const { reset } = useTableStore();
  const [isMoveGroupFlow, setIsMoveGroupFlow] = useState(false);
  const [idsToDisable, setIdsToDisable] = useState<string[]>([]);

  const onNodeSelect = (nodeId: string) => {
    setNewSelectedGroupId(nodeId);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setNewSelectedGroupId('');
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedGroups?.length) {
      setIdsToDisable(selectedGroups);
    }
  }, [selectedGroups]);

  useEffect(() => {
    setIsMoveGroupFlow(allSelectedItems.length === 0);
  }, [allSelectedItems]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCurrentGroupKept(event.target.checked);
  };

  const removeMembersFromCurrentGroupMutation = useUpdateGroupMembers(
    group?.id
  );

  const moveMembersToAnotherGroupMutation =
    useUpdateGroupMembers(newSelectedGroupId);

  const moveGroup = useMoveGroup(group?.id, newSelectedGroupId);

  const handleSubmit = async () => {
    if (isMoveGroupFlow && !memberId) {
      try {
        await moveGroup.mutateAsync({});
        addToast({
          variant: Variant.Success,
          message: 'Your group was successfully moved',
          closeable: true,
        });
        handleSuccess ? handleSuccess() : null;
        setIdsToDisable([]);
      } catch (error) {
        addToast({
          variant: Variant.Error,
          message: 'Something went wrong. Your changes haven’t been saved.',
          closeable: true,
        });
      }
      return;
    }

    if (isCurrentGroupKept) {
      try {
        await moveMembersToAnotherGroupMutation.mutateAsync({
          addedMemberIds: memberId ? [memberId as string] : allSelectedItems,
          removedMemberIds: [],
        });
        addToast({
          variant: Variant.Success,
          message: 'We’ve saved your changes',
          closeable: true,
        });

        if (memberId) {
          handleSuccess && handleSuccess();
        }
      } catch (error) {
        addToast({
          variant: Variant.Error,
          message: 'Failed to update members',
          closeable: true,
        });
      }
    } else {
      try {
        if (group) {
          await removeMembersFromCurrentGroupMutation.mutateAsync({
            addedMemberIds: [],
            removedMemberIds: allSelectedItems,
          });
        }
        await moveMembersToAnotherGroupMutation.mutateAsync({
          addedMemberIds: allSelectedItems,
          removedMemberIds: [],
        });
        addToast({
          variant: Variant.Success,
          message: 'We’ve saved your changes',
          closeable: true,
        });
      } catch (error) {
        addToast({
          variant: Variant.Warning,
          message: 'Failed to update members',
          closeable: true,
        });
      }
    }

    reset();

    if (!memberId) {
      navigate('/accounts/groups');
    }
  };

  const writeLevelText = (level: number) => {
    let levelLabel = ' ';
    const iterations = MAX_SUBGROUP_LEVEL - level;
    for (let i = 1; i <= iterations; i++) {
      if (i === iterations && iterations > 1) {
        levelLabel += ' or ';
      }
      levelLabel += `${i}${i < iterations - 1 ? ', ' : ''}`;
    }

    return levelLabel;
  };

  useEffect(() => {
    setIdsToDisable([]);
    
    if (group) {
      const nested = (subgroup: GroupsResponse) => {
        subgroup.subGroups.forEach((el: GroupsResponse) => {
          setIdsToDisable((prevIds) => [...prevIds, el.id]);
          if (el.subGroups && el.subGroups.length > 0) {
            nested(el);
          }
        });
      };

      setIdsToDisable((prevIds) => [...prevIds, group.id]);
      if (group.subGroups && group.subGroups.length > 0) {
        nested(group);
      }
    }
  }, [group]);

  return (
    <Modal
      testId='move-group-modal'
      headerTxt={
        isMoveGroupFlow && !modalHeader
          ? `Move ${group?.name}`
          : modalHeader ||
            `Select a group to move ${isSingularOrPluralPeopleText(
              allSelectedItems.length
            )} to`
      }
      isTruncatedHeader={!modalHeader}
      isOpen={isModalOpen}
      modalClassName={'h-full mt-0'}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={() => {
        handleGroupModalClose();
        setIdsToDisable([]);
      }}
      actionButtons={
        <div className="flex flex-col gap-2 md:flex-row">
          {isMoveGroupFlow && group?.deepestLevel === MAX_SUBGROUP_LEVEL ? (
            <>
              <Button variant="primary" onClick={handleGroupModalClose} testId='got-it-button'>
                Got it
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="primary"
                disabled={!newSelectedGroupId}
                onClick={handleSubmit}
              >
                {isMoveGroupFlow && !buttonSubmitText
                  ? `Move group`
                  : buttonSubmitText ||
                    `Move ${allSelectedItems.length} people`}
              </Button>
              <Button variant="outlineLight" onClick={handleGroupModalClose} testId='cancel-button'>
                Cancel
              </Button>
            </>
          )}
        </div>
      }
      contentClassName="flex flex-column !items-start !justify-start bg-white"
    >
      <div className="w-full">
        {group && isMoveGroupFlow && (
          <>
            {group?.deepestLevel === MAX_SUBGROUP_LEVEL ? (
              <>
                <p className="font-bold">
                  This group already has 5 levels of subgroups, so it can’t be
                  moved.
                </p>
                <p className="mb-1">
                  Before you can move this group, you’ll need to delete at least
                  one level of its subgroups, or move them out of this group.
                </p>
              </>
            ) : (
              <>
                <p className="font-bold">
                  Where do you want to move this group to?
                </p>
                <p className="mb-1">
                  Groups can only contain {MAX_SUBGROUP_LEVEL} levels of
                  subgroups. This group can be moved to groups in level{' '}
                  {writeLevelText(group?.deepestLevel || 0)}.
                </p>
              </>
            )}
          </>
        )}
        {isMoveGroupFlow && group?.deepestLevel === MAX_SUBGROUP_LEVEL ? (
          <></>
        ) : (
          <div className="max-h-[400px] overflow-y-scroll rounded-lg border border-interfaceColor-40 p-2 md:max-h-[300px]">
            <TreeView
              currentNode={group?.id}
              isSelectable={true}
              isSimpleTree={true}
              isMoveGroupFlow={isMoveGroupFlow}
              idsToDisable={idsToDisable}
              onNodeSelect={onNodeSelect}
              groupToMove={group}
              data={query.data || []}
            />
          </div>
        )}
        {group && !isMoveGroupFlow && (
          <div className="mt-4 flex flex-row items-center">
            <Checkbox
              label={`Keep these ${isSingularOrPluralPeopleText(
                allSelectedItems.length,
                false
              )} in ${group?.name}, as well as adding them to a new group`}
              name={'keep'}
              checked={isCurrentGroupKept}
              onChange={handleCheckboxChange}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
