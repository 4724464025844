import hamburgerIcon from '@assets/images/hamburger_icon.png';
import logo from '@assets/svg/logo.svg';
import { BellIcon, Button } from '@components/ui';
import { useIsDesktop, useIsTablet } from '@hooks/utils';
import { useIsMobile } from '@hooks/utils/isMobile';
import useUIStore from '@store/uiStore';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

export const Header: React.FC = () => {
  const { isSidebarOpen, toggleSidebar, unreadNotificationsCount } =
    useUIStore();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const { setHeaderHeight } = useUIStore();
  const headerElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (headerElementRef && headerElementRef.current) {
      setHeaderHeight(headerElementRef.current.clientHeight);
    }
  }, []);

  const handleSidebar = () => {
    toggleSidebar();
  };

  const showNotifications = () => {
    navigate('/notifications-list');
  };

  const notificationBtn = (
    <Button
      testId='notification-button'
      variant={'dark'}
      type="button"
      size="extraSmall"
      onClick={showNotifications}
      className="relative mr-3 h-[36px] w-[36px]"
    >
      <div className="relative">
        <BellIcon size="5" />
        {unreadNotificationsCount > 0 && (
          <span className="absolute right-[0px] top-[0px] flex h-[8px] w-[8px] items-center justify-center rounded-full bg-errorColor-100 lg:right-[-14px] lg:top-[-14px] lg:h-0 lg:w-0 lg:px-[10px] lg:py-[10px]">
            {isDesktop && (
              <span className="text-[9px] text-white">
                {unreadNotificationsCount}
              </span>
            )}
          </span>
        )}
      </div>
    </Button>
  );

  const menuBtn = (
    <Button
      testId='menu-button'
      className="h-[36px] w-[36px] md:w-auto"
      variant={'dark'}
      onClick={handleSidebar}
      type="button"
      size="extraSmall"
    >
      <div className="flex items-center gap-[8px] text-white">
        <span className="hidden md:block">Menu</span>
        <img className="h-3 w-3" src={hamburgerIcon} alt="Menu button" />
      </div>
    </Button>
  );

  const header = (
    <header
      ref={headerElementRef}
      className="flex w-full justify-between gap-[8px] bg-interfaceColor-5 p-5 md:ml-0 md:mr-0 md:pb-5 md:pl-6 md:pr-6 md:pt-5"
    >
      <>
        <img src={logo} alt="CloudClevr Logo" />
        <div className="flex">
          {notificationBtn}
          {menuBtn}
        </div>
      </>
    </header>
  );

  return (
    <>
      {isMobile && (
        <StickyBox className="z-[10] bg-interfaceColor-20" offsetTop={0}>
          {header}
        </StickyBox>
      )}

      {isDesktop && !isTablet && (
        <div className="absolute right-[15px] top-[35px]">
          {notificationBtn}
        </div>
      )}
      {isTablet && <>{header}</>}
    </>
  );
};
