import {
  DEFAULT_PAGE_SIZE,
  EXTERNAL_TEAMS_ACCESS_DETAILS_SORT_BY,
  EXTERNAL_TEAMS_ACCESS_DETAILS_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';

import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useExternalTeamsAccessDetailsPreviewQuery } from '@hooks/security';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import useUIStore from '@store/uiStore';
import { createCellValue } from './utils';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';

const pageSize = DEFAULT_PAGE_SIZE;

const ExternalTeamsAccessDetailsPage: React.FC = () => {
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });
  const { externalTeamsDetails } = useUIStore();

  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/assurance/external-teams-access'
  );

  // table
  const query = useExternalTeamsAccessDetailsPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    ids: externalTeamsDetails.ids || [],
  });
  const getTableData = useTableData(
    query,
    EXTERNAL_TEAMS_ACCESS_DETAILS_TABLE_COLUMNS,
    createCellValue
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.EXTERNAL_TEAMS_ACCESS_DETAILS_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      ids: externalTeamsDetails.ids || [],
    },
    filename: `Clevr360_External_user_${formatDate(new Date().toString())}.csv`,
  });
  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={externalTeamsDetails.email || ''}
      headerText="External user"
    >
      <>
        <TableWrapper
          testId='external-teams-access-details-table'
          search={search}
          setSearch={setSearch}
          searchPlaceholder={'Search by team name'}
          columns={EXTERNAL_TEAMS_ACCESS_DETAILS_TABLE_COLUMNS}
          data={getTableData()}
          sortData={EXTERNAL_TEAMS_ACCESS_DETAILS_SORT_BY}
          filterData={undefined}
          searchKey="search"
          query={query}
          refetchQuery={query.refetch}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          setPageNumber={setPageNumber}
          isCollapsable={true}
          sliceColumns={2}
          floatingFilterButton={false}
          hasFilterBanner={false}
          isLicensePage={true}
          searchCountStatsLabel="accessible teams"
          isDownloadAvailable={true}
          handleDownload={handleDownloadClick}
        />
      </>
    </OverlayPage>
  );
};

export default ExternalTeamsAccessDetailsPage;
