import { Button, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useResetUserMFA } from '@hooks/users';
import { User } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';

export type ResetMFAModalProps = {
  user: User | undefined;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const ResetMFAModal: React.FC<ResetMFAModalProps> = ({
  user,
  isModalOpen,
  handleModalClose,
  handleSuccess,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const { mutateAsync, isLoading } = useResetUserMFA(user?.id);

  const handleReset = async () => {
    try {
      await mutateAsync();
      addToast({
        variant: Variant.Success,
        message: `We’ve reset ${user?.fullName}'s MFA`,
        closeable: true,
      });
      handleSuccess();
    } catch (error: any) {
      addToast({
        variant: Variant.Error,
        message: error?.response?.data?.Reason || 'Something went wrong, and your changes haven’t been saved.',
        closeable: true,
      });
    }
  };

  return (
    <Modal
      testId='reset-mfa-for-user-modal'
      headerTxt={`Reset MFA for ${user?.fullName}?`}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      isTruncatedHeader={false}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button variant="danger" disabled={isLoading} onClick={handleReset} testId='reset-mfa-button'>
            Reset MFA
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose} testId='cancel-reset-mfa-button'>
            Cancel
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white mb-[-20px]"
    >
      <div>
        <p>
          If you reset {user?.fullName}’s MFA, they’ll need to set it up again next time they log in.
        </p>
      </div>
    </Modal>
  );
};
