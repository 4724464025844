import {
    CALL_QUEUES_SORT_BY,
    CALL_QUEUES_TABLE_COLUMNS,
    DEFAULT_PAGE_SIZE,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import styles from './styles.module.css';
import { PeopleSortBy } from '@hooks/people/types';
import { useCallQueuesFiltersQuery, useCallQueuesPreviewQuery } from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';
import { addSelectedDataToFilter, getVendorName, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { Banner, Modal } from '@components/ui';
import useModalStore from '@store/modal';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { useIsDesktop } from '@hooks/utils';
import { CallQueuesConfigured } from './tiles/CallQueuesConfigured';
import { CallQueues } from '@pages/Dashboard/tiles';
import useServicesStore from '@store/servicesStore';

const pageSize = DEFAULT_PAGE_SIZE;

const CallQueuesPage: React.FC = () => {
    const redirectPath = useNavSourceRedirect(
        '/insights-and-analytics/adoption'
    );
    const isDesktop = useIsDesktop();
    const {
        setIsDetailsModalOpen,
        setDataDetailsModal,
        isDetailsModalOpen,
        dataDetailsModal,
    } = useModalStore();
    const { serviceIntegrations } = useServicesStore();

    const serviceIntegration = serviceIntegrations?.find((integration) =>
        (integration.vendor === 3 || integration.vendor === 4) && integration.status === 0);

    const [search, setSearch] = useState('');
    const { pageNumber, setPageNumber } = usePagination();

    const [queryParams, setQueryParams] = useQueryParams({
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'name'),
        order: withDefault(StringParam, 'asc'),
        filter: withDefault(StringParam, ''),
    });

    const transformedFilterData: FilterData[] = [];

    const { data } = useCallQueuesFiltersQuery();

    if (data) {
        addSelectedDataToFilter(data, transformedFilterData,
            (name: string) => name === 'modes' ? 'Mode' : name);
    }

    const filterValues = queryParams.filter.split(',');

    // table
    const query = useCallQueuesPreviewQuery({
        pageSize,
        pageNumber,
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        Modes: queryParams.filter ? filterValues.filter((filter) => data?.modes.includes(filter)) : [],
    });

    const getTableData = useTableData(
        query,
        CALL_QUEUES_TABLE_COLUMNS,
        createCellValue
    );

    return (
        <OverlayPage
            isFooterVisible={false}
            contentClassNames={''}
            path={redirectPath}
            headerTitle={`Call queue data`}
        >
            <div className={styles.pageGrid}>
                {isDesktop && (
                    <>
                        <CallQueuesConfigured
                            isSnapshot={true}
                            classNames="col-span-1"
                            params={""}
                        />
                        <CallQueues
                            isSnapshot={true}
                            classNames="col-span-1"
                            params={""}
                            title='Empty call queues'
                        />
                    </>
                )}
            </div>
            <>
                {query.isFetched && !query.data?.items.length && !search &&
                    <Banner
                        closeable={true}
                        message={`No call queue data available within the selected data set. If changes have been made within your
                            ${serviceIntegration?.vendor ? getVendorName(serviceIntegration?.vendor) : ''} system${query.data?.lastUpdatedAt ?
                                 ` after ${query.data?.lastUpdatedAt}` : ''}, please request updated data via the CloudClevr support team.`}
                        className="mb-4 w-full md:w-[534px] lg:w-[704px]"
                    />
                }
                <div className="mt-2">
                    <TableWrapper
                        testId='call-queue-data-table'
                        search={search}
                        setSearch={setSearch}
                        searchPlaceholder={'Search by name, number, mode'}
                        columns={CALL_QUEUES_TABLE_COLUMNS}
                        data={getTableData()}
                        sortData={CALL_QUEUES_SORT_BY}
                        searchKey="search"
                        query={query}
                        filterData={transformedFilterData}
                        refetchQuery={query.refetch}
                        queryParams={queryParams}
                        setQueryParams={setQueryParams}
                        setPageNumber={setPageNumber}
                        isCollapsable={true}
                        floatingFilterButton={false}
                        hasFilterBanner={false}
                        isLicensePage={true}
                        sliceColumns={2}
                        searchCountStatsLabel="call queues"
                        isDownloadAvailable={false}
                        filterHeaderText="Filter hunt groups"
                    />
                </div>
                <Modal
                    testId='call-queues-modal'
                    headerTxt={dataDetailsModal.header}
                    isOpen={isDetailsModalOpen}
                    size={'medium'}
                    showCloseBtn={true}
                    onClose={() => {
                        setIsDetailsModalOpen(false);
                        setDataDetailsModal({ body: null, header: null });
                    }}
                    contentClassName="bg-white"
                    actionButtons={<></>}
                >
                    <div className="w-full">{dataDetailsModal.body}</div>
                </Modal></>
        </OverlayPage>
    );
};

export default CallQueuesPage;
