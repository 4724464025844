import { Button, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useDeleteGroup, useUpdateGroupMembers } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';

export type DeleteGroupModalProps = {
  group: GroupsResponse | undefined;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
  modalTitle?: string;
  modalBodyText?: string;
  modalRemoveBtnText?: string;
  modalCancelBtnText?: string;
  memberId?: string;
};

export const DeleteGroupModal: React.FC<DeleteGroupModalProps> = ({
  group,
  isModalOpen,
  handleModalClose,
  handleSuccess,
  modalTitle,
  modalBodyText,
  modalRemoveBtnText,
  modalCancelBtnText,
  memberId,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const { mutateAsync, isLoading } = useDeleteGroup();

  const removeMembersFromCurrentGroupMutation = useUpdateGroupMembers(
    group?.id
  );

  const handleDeleteGroup = async () => {
    if (memberId) {
      try {
        await removeMembersFromCurrentGroupMutation.mutateAsync({
          addedMemberIds: [],
          removedMemberIds: [memberId as string],
        });

        addToast({
          variant: Variant.Success,
          message: 'We’ve saved your changes',
          closeable: true,
        });
        handleSuccess();
      } catch (error) {
        addToast({
          variant: Variant.Warning,
          message: 'Failed to update members',
          closeable: true,
        });
      }
    } else {
      try {
        await mutateAsync(group?.id ?? '0');
        addToast({
          variant: Variant.Success,
          message: `Your group was deleted`,
          closeable: true,
        });
        handleSuccess();
      } catch (error) {
        addToast({
          variant: Variant.Error,
          message: 'Failed to delete group',
          closeable: true,
        });
      }
    }
  };

  return (
    <Modal
      testId='delete-group-modal'
      headerTxt={modalTitle || `Delete ${group?.name ?? ''}?`}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
           testId='delete-gruop-button'
            variant="danger"
            disabled={isLoading}
            onClick={handleDeleteGroup}
          >
            {modalRemoveBtnText || 'Confirm: delete group'}
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose} testId='cancel-button'>
            {modalCancelBtnText || 'Cancel: keep group'}
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div>
        <p>
          {modalBodyText ||
            'This group and all its subgroups will be deleted. All users assigned to this group and its subgroups will be unassigned, and you’ll no longer see insights and analytics related to it.'}
        </p>
        <p className="mt-2">This can’t be undone.</p>
      </div>
    </Modal>
  );
};
