import { EmailIcon, Modal, PhoneIcon } from '@components/ui';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useAuthStore from '@store/authStore';

export type GetInTouchModalProps = {
  isModalOpen: boolean;
  handleModalClose: () => void;
};

export const GetInTouchModal: React.FC<GetInTouchModalProps> = ({
  isModalOpen,
  handleModalClose,
}) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const authStore = useAuthStore();
  const activeOperatingCompany = authStore.user?.operatingCompany;

  return (
    <Modal
      testId='get-in-touch-modal'
      headerImageUrl={activeOperatingCompany?.logoUrl}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div className="">
        <div className="flex flex-col">
          <span className="mb-2 text-16">Drop our support team a line:</span>
          <span className="mb-3 flex flex-row items-center gap-x-2">
            <EmailIcon classNames="ml-1" />

            <a
              className="tex-16 border-b border-interfaceColor-30 font-light text-interfaceColor-100"
              href={`mailto:${activeOperatingCompany?.email}`}
            >
              {activeOperatingCompany?.email}
            </a>
          </span>
          <span className="flex flex-row items-center gap-x-2">
            <PhoneIcon classNames="ml-1" />

            <a
              className="tex-16 border-b border-interfaceColor-30 font-light text-interfaceColor-100"
              href={`tel:${activeOperatingCompany?.phone}`}
            >
              {activeOperatingCompany?.phone}
            </a>
          </span>
        </div>
      </div>
    </Modal>
  );
};
