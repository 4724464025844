import {
  DEFAULT_PAGE_SIZE,
  LOCATION_TABLE_COLUMNS,
  PEOPLE_FILTER_BY,
  PEOPLE_SORT_BY,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage } from '@components/partials/OverlayPage';
import { TableWrapper } from '@components/partials/TableWrapper';
import { useCreateLocation, useLocationsQuery } from '@hooks/locations';
import { LocationForm } from '@hooks/locations/types';
import { usePersonsQuery, useUnassignedLocationsQuery } from '@hooks/people';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import {
  hasPermission,
  isSingularOrPluralPeopleText,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { MoveLocationModal } from './Modals/MoveLocationModal';
import { useIsMobile } from '@hooks/utils';
import { UsersMoveIcon } from '@components/ui';
import { createCellValue } from './utils';
import { Variant } from '@components/ui/Toast';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';

const pageSize = DEFAULT_PAGE_SIZE;

export const UnassignedPeoplePage: React.FC = () => {
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const location = useLocation();
  const { addToast } = useUIStore();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });
  const selectedLocation: LocationForm = location.state?.formData;

  const unassignedQuery = useUnassignedLocationsQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: queryParams.filter,
  });

  const personQuery = usePersonsQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: queryParams.filter,
  });

  const query = !selectedLocation ? unassignedQuery : personQuery;
  const getTableData = useTableData(
    query,
    LOCATION_TABLE_COLUMNS,
    createCellValue
  );
  const locationsQuery = useLocationsQuery();
  const { allSelectedItems, reset } = useTableStore();
  const searchTag = !selectedLocation ? 'Location: Unassigned' : '';
  const headerTitle = !selectedLocation
    ? 'Unassigned people'
    : `Assign people to ${selectedLocation?.name}`;
  const showBulkAction = !selectedLocation ? true : false;
  const isFooterVisible = !selectedLocation ? false : true;
  const createLocation = useCreateLocation();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const checkboxHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  const [isMovingMemberLocationModalOpen, setIsMovingMemberLocationModalOpen] =
    useState(false);
  const handleLocationModalClose = () => {
    setIsMovingMemberLocationModalOpen(false);
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const handleCreateLocation = async () => {
    const data = {
      name: selectedLocation.name?.trim() || '',
      address: selectedLocation.address || '',
      membersToAdd: allSelectedItems,
    };

    try {
      await createLocation.mutateAsync(data);
      navigate(`/accounts/locations`);
      addToast({
        variant: Variant.Success,
        message: 'Your location’s been created',
        closeable: true,
      });
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Failed to create location',
        closeable: true,
      });
    }
  };

  return (
    <OverlayPage
      isFooterVisible={isFooterVisible}
      path="/accounts/locations"
      headerTitle={headerTitle}
      footerSaveBtnLabel="Create location"
      isCancelBtnVisible={!isMobile}
      footerText={
        allSelectedItems.length === 0
          ? '0 people selected'
          : `${isSingularOrPluralPeopleText(
              allSelectedItems.length
            )} people will be moved to ${selectedLocation?.name}`
      }
      handleOnSave={handleCreateLocation}
      isDisabled={createLocation.isLoading}
    >
      <TableWrapper
        testId='persons-table'
        search={search}
        setSearch={setSearch}
        columns={LOCATION_TABLE_COLUMNS}
        data={getTableData()}
        sortData={PEOPLE_SORT_BY}
        filterData={PEOPLE_FILTER_BY}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={false}
        sliceColumns={5}
        isDownloadAvailable={false}
        searchTag={searchTag}
        showCheckboxes={checkboxHasPermission}
        showBulkAction={showBulkAction}
        bulkContent={
          <>
            <UsersMoveIcon classNames="mr-1" />
            <span>
              Move {isSingularOrPluralPeopleText(allSelectedItems.length)}
            </span>
          </>
        }
        handleBulkAction={() => {
          setIsMovingMemberLocationModalOpen(true);
        }}
      />
      <MoveLocationModal
        items={(locationsQuery.data || []).map((item) => ({
          id: item.id,
          name: item.name,
        }))}
        isModalOpen={isMovingMemberLocationModalOpen}
        handleLocationModalClose={handleLocationModalClose}
      />
    </OverlayPage>
  );
};
