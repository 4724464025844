import {
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  LAST_DAYS_SORT_BY,
  TEAMS_GROUPS_ADOPTION_SORT_BY,
  TEAMS_GROUPS_TABLE_COLUMNS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { DashboardIcon } from '@components/ui';
import { Dropdown } from '@components/ui/Dropdown';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useCollabSnapshotQuery,
  useTeamsGroupsAdoptionPreviewQuery,
  useTeamsGroupsAdoptionSnapshotQuery,
  useTeamsGroupsTrendQuery,
} from '@hooks/adoption';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import {
  formatDate,
  getTrendData,
  getTrendLabels,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import {
  CollabSnapshotResponse,
  TeamsGroupsAdoptionSnapshotResponse,
} from '@hooks/adoption/types';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { TeamGroupType } from '..';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { Location } from '@hooks/locations/types';
import { GroupsResponse } from '@hooks/groups/types';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useUIStore from '@store/uiStore';

const pageSize = DEFAULT_PAGE_SIZE;
const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const getTeamsGroupsDataLabels = (
  data: TeamsGroupsAdoptionSnapshotResponse | undefined
) => {
  if (!data) {
    return [];
  }

  const props: (keyof TeamsGroupsAdoptionSnapshotResponse)[] = [
    'notInTeam',
    'inTeam',
  ];
  const labels = ['people not in a team', 'people in a team'];

  return props.map((i, index) => {
    return `${data[i] || 0} ${labels[index]}`;
  });
};

const getCollabDataLabels = (data: CollabSnapshotResponse | undefined) => {
  if (!data) {
    return [];
  }

  const props: (keyof CollabSnapshotResponse)[] = [
    'notCollaborate',
    'collaborate',
  ];
  const labels = ['people not collaborating', 'people collaborating'];

  return props.map((i, index) => {
    return `${data[i] || 0} ${labels[index]}`;
  });
};

const TeamsGroupsAdoptionPage: React.FC = () => {
  const isDesktop = useIsDesktop();

  const [search, setSearch] = useState('');
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/adoption');
  const { pageNumber, setPageNumber } = usePagination();
  const isMobile = useIsMobile();
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[1].label || ''
  );
  const [days, setDays] = useState('30');
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);

  const { navigationTilePathOptions } = useUIStore();

  const teamGroupDefaultSelection =
    navigationTilePathOptions === TeamGroupType.Collaboration
      ? 'collaborating'
      : 'inTeam';
  const [teamsGroupsCompareType, setTeamsGroupsCompareType] = useState<string>(
    teamGroupDefaultSelection
  );

  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const FILTERS = ['msteams'];
  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  if (locationDataFetched && groupsDataFetched) {
    transformedFilterData.push({
      label: 'People who’ve never collaborated',
      name: 'collaboration',
      options: [{ label: 'Never collaborated in MS Teams', value: 'msteams' }],
    });

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
  };

  // doughnut
  const {
    data: teamsGroupsAdoptionData,
    isLoading: isTeamsGroupsAdoptionLoading,
  } = useTeamsGroupsAdoptionSnapshotQuery({
    days,
  });

  // doughnut
  const { data: collabData, isLoading: isCollabDataLoading } =
    useCollabSnapshotQuery({
      days,
    });

  // line chart
  const {
    data: teamsGroupsTrendData,
    isLoading: isTeamsGroupsTrendDataLoading,
  } = useTeamsGroupsTrendQuery({
    days,
    dataType: teamsGroupsCompareType,
  });

  const labels = getTrendLabels(teamsGroupsTrendData?.trend || [], +days);

  const compareAgainstTrendData = getTrendData(
    teamsGroupsTrendData?.compareAgainst || [],
    +days
  );

  const trendData = getTrendData(teamsGroupsTrendData?.trend || [], +days);

  // table
  const query = useTeamsGroupsAdoptionPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    collaboration: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
      : [],
  });
  const getTableData = useTableData(
    query,
    TEAMS_GROUPS_TABLE_COLUMNS,
    createCellValue
  );

  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.TeamsAndGroupsAdoptionSnapshot:
          return {
            days,
            vendorType: '',
          };
        case DashboardTiles.TeamsAndGroupsAdoptionOverTime:
          return {
            days,
            dataType: teamsGroupsCompareType,
          };
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const teamsGroupsCompareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Collaboration',
      value: 'collaborating',
      action: () => handleTeamsGroupsCompareDropdown('collaborating'),
    },
    {
      id: '2',
      label: 'Adoption',
      value: 'inTeam',
      action: () => handleTeamsGroupsCompareDropdown('inTeam'),
    },
  ];

  const handleTeamsGroupsCompareDropdown = (value: string) => {
    setTeamsGroupsCompareType(value);
  };

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.TEAMS_GROUPS_ADOPTION}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
      collaboration: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => FILTERS.includes(filter))
        : [],
    },
    filename: `Clevr360_Teams_&_groups_adoption_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Microsoft Teams adoption`}
    >
      <>
        {!isMobile && (
          <div className="absolute left-[40px] top-[113px]">
            <Dropdown>
              <Dropdown.TextHeader
                classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                label={daysSelectedLabel}
                handleOpen={handleIsDaysDropdownOpen}
              ></Dropdown.TextHeader>
              <Dropdown.List
                className="!z-[999] text-16"
                open={isDaysDropdownOpen}
                align="left"
              >
                {floatingSortDropdownData.map((item, index) => {
                  return (
                    <Dropdown.DefaultItem
                      key={index}
                      item={item}
                      currentIndex={index}
                      selectedIndex={daysSelectedIndex}
                      onChange={() =>
                        handleDropdownDaysOnChange(item.value, index)
                      }
                    ></Dropdown.DefaultItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown>
          </div>
        )}
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <DoughnutChartTile
                classNames={
                  'col-span-3 lg:col-auto lg:row-start-1 min-w-[300px]'
                }
                contextData={[]}
                headerTitle={'Microsoft Teams adoption'}
                isLoading={isTeamsGroupsAdoptionLoading}
                showBracketLabelValue={false}
                data={
                  teamsGroupsAdoptionData
                    ? Object.values(teamsGroupsAdoptionData)
                    : []
                }
                dataLabels={getTeamsGroupsDataLabels(teamsGroupsAdoptionData)}
              />

              <DoughnutChartTile
                classNames={
                  'col-span-3 lg:col-auto lg:row-start-1 min-w-[300px]'
                }
                contextData={[]}
                headerTitle={'Teams & groups collaboration'}
                isLoading={isCollabDataLoading}
                showBracketLabelValue={false}
                data={collabData ? Object.values(collabData) : []}
                dataLabels={getCollabDataLabels(collabData)}
              />

              <LineChartTile
                classNames={styles.lineChartTile}
                sortData={teamsGroupsCompareMenuItems}
                initialSelectedItem={teamsGroupsCompareMenuItems.find(
                  (item) => item.value === teamsGroupsCompareType
                )}
                showCompareDropdown={false}
                legendLabels={[`Last ${days} days`, 'Previous period']}
                contextData={createMenuItems(
                  DashboardTiles.TeamsAndGroupsAdoptionOverTime
                )}
                tooltipLabel={[`Last ${days} days`, 'Previous period']}
                headerTitle={'Teams & groups adoption over time'}
                isLoading={isTeamsGroupsTrendDataLoading}
                labels={labels}
                data={[trendData, compareAgainstTrendData]}
                compareLabel=""
                buttonLabel=""
                dataSetTypes={[LineChartTypes.Dots, LineChartTypes.Line]}
                dataSetColors={['#00CF6C', '#3F454B']}
              />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            testId='ms-teams-adoption-table'
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by name'}
            columns={TEAMS_GROUPS_TABLE_COLUMNS}
            data={getTableData()}
            sortData={TEAMS_GROUPS_ADOPTION_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={1}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="people"
            filterHeaderText="Filter teams & groups adoption"
            floatingFilterButton={true}
            floatingFilterButtonLeft={!isMobile ? '190px' : ''}
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default TeamsGroupsAdoptionPage;
