import React, { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import worldGeoJSON from '@assets/geo/geo.json';
import { LegendRange } from '@hooks/utils/transformData';
import { ArrowLongRightIcon } from '../Icons';
import { Button } from '@components/ui';
import { useNavigate } from 'react-router-dom';

type MapProps = {
  rawData: { [key: string]: number } | null;
  legendRanges: LegendRange[];
  label: string;
  actionPath?: string;
};

const Map: React.FC<MapProps> = ({
  rawData,
  legendRanges,
  label,
  actionPath,
}) => {
  const navigate = useNavigate();
  const [tooltipContent, setTooltipContent] = useState<string>('');
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [fixedTooltip, setFixedTooltip] = useState<{
    countryName: string;
    countryEvents: number;
    position: { x: number; y: number };
  } | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
      if (!target.closest('.country') && !target.closest('.fixed-tooltip')) {
        setFixedTooltip(null);
        setSelectedCountry(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getColor = (count: number) => {
    for (const range of legendRanges) {
      if (count >= range.min && count <= range.max) {
        return range.color;
      }
    }
    return '#ffffff';
  };

  const getCountryColor = (countryName: string) => {
    const count = rawData ? rawData[countryName] : 0;
    return getColor(count);
  };

  const handleMouseEnter = (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    countryName: string,
    count: number
  ) => {
    if (!fixedTooltip) {
      setTooltipContent(`
        <div>
          <strong>${countryName}</strong><br />
          ${count.toFixed(0)} events<br />
        </div>
      `);
      const { clientX: x, clientY: y } = event;
      setTooltipPosition({ x, y });
      setIsTooltipVisible(true);
    }
  };

  const handleMouseMove = (
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    if (!fixedTooltip) {
      const { clientX: x, clientY: y } = event;
      setTooltipPosition({ x, y });
    }
  };

  const handleMouseLeave = () => {
    if (!fixedTooltip) {
      setIsTooltipVisible(false);
      setTooltipContent('');
    }
  };

  const handleCountryClick = (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    countryName: string,
    countryEvents: number
  ) => {
    const { clientX: x, clientY: y } = event;
    if (fixedTooltip && fixedTooltip.countryName === countryName) {
      setFixedTooltip(null);
      setSelectedCountry(null);
    } else {
      setFixedTooltip({ countryName, countryEvents, position: { x, y } });
      setSelectedCountry(countryName);
    }
  };

  return (
    <>
      <div>
        {rawData && (
          <ComposableMap
            projection="geoMercator"
            projectionConfig={{
              scale: 110,
              rotate: [-11, 0, 0],
              center: [0, 30]
            }}
            width={800}
            height={400}          >
            <Geographies geography={worldGeoJSON}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const countryName = geo.properties.name;
                  const count = rawData[countryName] || 0;
                  const isSelected = countryName === selectedCountry;

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={
                        isSelected ? '#C6CCD0' : getCountryColor(countryName)
                      }
                      stroke="#e0e5e8"
                      className="country"
                      onMouseEnter={(event) =>
                        handleMouseEnter(event, countryName, count)
                      }
                      onMouseMove={handleMouseMove}
                      onMouseLeave={handleMouseLeave}
                      onClick={(event) =>
                        handleCountryClick(event, countryName, count)
                      }
                      style={{
                        default: { outline: 'none', cursor: 'pointer' },
                        hover: {
                          fill: rawData[countryName]
                            ? getCountryColor(countryName)
                            : '#E0E5E9',
                          outline: 'none',
                        },
                        pressed: {
                          fill: rawData[countryName]
                            ? getCountryColor(countryName)
                            : '#E0E5E9',
                          outline: 'none',
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        )}

        {isTooltipVisible && !fixedTooltip && (
          <div
            className="tooltip absolute rounded-lg bg-gray-800 px-3 py-2 text-white shadow-lg"
            style={{
              top: `${tooltipPosition.y + 10}px`,
              left: `${tooltipPosition.x + 10}px`,
              transform: `translate(${tooltipPosition.x + 200 > window.innerWidth ? '-210px' : '0'
                }, ${tooltipPosition.y + 100 > window.innerHeight ? '-110px' : '0'
                })`,
            }}
            dangerouslySetInnerHTML={{ __html: tooltipContent }}
          />
        )}
        {fixedTooltip && (
          <div
            className="fixed-tooltip absolute min-w-[120px] rounded-lg bg-[#292b2f] px-3 py-2 text-white shadow-lg"
            style={{
              top: `${fixedTooltip.position.y + 10}px`,
              left: `${fixedTooltip.position.x + 10}px`,
              transform: `translate(${fixedTooltip.position.x + 200 > window.innerWidth
                ? '-210px'
                : '0'
                }, ${fixedTooltip.position.y + 100 > window.innerHeight
                  ? '-110px'
                  : '0'
                })`,
            }}
          >
            <div className={`relative flex w-full items-center justify-between gap-x-6 ${actionPath ? 'min-w-[120px] ' : 'min-w-[200px] '}`}>
              <div>
                <strong className="text-12 font-semibold">
                  {fixedTooltip.countryName}
                </strong>
                <br />
                <p>
                  {fixedTooltip.countryEvents.toFixed(0)} {label}
                </p>
              </div>
              {actionPath && <Button
                onClick={() => {
                  setFixedTooltip(null);
                  navigate(actionPath);
                }}
                testId='map-data-button'
                variant="successOutline"
                className="!border-[#00EC7B] !text-[#00EC7B] hover:border-[#00EC7B] hover:bg-transparent hover:text-[#00EC7B]"
                size={'small'}
              >
                <span>Data</span>
                <ArrowLongRightIcon
                  color="text-primaryColor-100"
                  classNames="ml-2"
                  size="5"
                />
              </Button>}
            </div>
          </div>
        )}
      </div>
      <div className="relative">
        {(legendRanges.length > 0 && legendRanges[legendRanges.length - 1].max != -Infinity &&
          <div className="absolute bottom-4 left-4 rounded-lg bg-white p-4 shadow-md">
            {legendRanges.map((range, index) => (
              <div key={index} className="mb-2 flex items-center">
                <div
                  className="mr-2 h-4 w-4 rounded border border-interfaceColor-40"
                  style={{ backgroundColor: range.color }}
                ></div>
                <span className="text-11">
                  {rangeCaption(range.min, range.max, label)}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};


function rangeCaption(min: number, max: number, label: string): string {
  if (min < max) {
    return `${min}-${max} ${label}`;
  }
  return `${min} ${label}`;
}
export default Map;
