import { Button, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useDeleteUser } from '@hooks/users';
import { User } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';

export type RemoveUserModalProps = {
  user: User | undefined;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const RemoveUserModal: React.FC<RemoveUserModalProps> = ({
  user,
  isModalOpen,
  handleModalClose,
  handleSuccess,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const { mutateAsync, isLoading } = useDeleteUser(user?.id);

  const handleRemove = async () => {
    try {
      await mutateAsync();
      addToast({
        variant: Variant.Success,
        message: `We’ve removed ${user?.fullName} from the Hub`,
        closeable: true,
      });
      handleSuccess();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Something went wrong, and your changes haven’t been saved.',
        closeable: true,
      });
    }
  };

  return (
    <Modal
      testId='remove-user-modal'
      headerTxt={`Remove ${user?.fullName}`}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button variant="danger" disabled={isLoading} onClick={handleRemove} testId='remove-user-button'>
            Confirm: remove user
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose} testId='cancel-remove-user-button'>
            Cancel: keep user
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div>
        <p>
          {user?.fullName} will instantly lose access to the Hub, and all their
          saved data and settings will be removed. They’ll only regain access if
          they’re added as a new user.
        </p>
        <p className="mb-5 mt-2">This can’t be undone.</p>
      </div>
    </Modal>
  );
};
