import {
  DEFAULT_PAGE_SIZE,
  GROUP_TABLE_COLUMNS,
  PEOPLE_FILTER_BY,
  PEOPLE_SORT_BY,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials/';
import { UsersMoveIcon } from '@components/ui';
import { useMembersQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import useTableStore from '@store/tableStore';
import {
  isSingularOrPluralPeopleText,
  useTableData,
} from '@utils/index';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { GroupTreeModal } from './Modals/GroupTreeModal';
import { createCellValue } from './utils';
import { UserRoles } from '@hooks/users/types';
import useAuthStore from '@store/authStore';

const pageSize = DEFAULT_PAGE_SIZE;

export const MembersPage: React.FC = () => {
  const { groupId } = useParams();
  const location = useLocation();
  const group: GroupsResponse = location.state?.node;
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const loggedInUserId = useAuthStore.getState().user?.id || '';

  const query = useMembersQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: queryParams.filter,
    groupId,
  });
  const getTableData = useTableData(
    query,
    GROUP_TABLE_COLUMNS,
    createCellValue
  );

  const { allSelectedItems } = useTableStore();
  const [isMovingMemberGroupModalOpen, setIsMovingMemberGroupModalOpen] =
    useState(false);
  const handleGroupModalClose = () => {
    setIsMovingMemberGroupModalOpen(false);
  };

  const canChangeMembers = () => {
    if (userRole === UserRoles.SUPERADMIN || userRole === UserRoles.SUPPORT) {
      return true;
    } else if (userRole === UserRoles.ADMIN) {
      const ids: string[] = [];
      if (group.groupViewers) {
        group.groupViewers.forEach((user) => {
          ids.push(user.userId);
        });
      }

      return ids.includes(loggedInUserId);
    } else {
      return false;
    }
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      path="/accounts/groups"
      headerTitle={`People in ${group.name}`}
      headerText="Select people to move them to another group."
    >
      <TableWrapper
        testId='members-table'
        search={search}
        setSearch={setSearch}
        columns={GROUP_TABLE_COLUMNS}
        data={getTableData()}
        sortData={PEOPLE_SORT_BY}
        filterData={PEOPLE_FILTER_BY}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={false}
        sliceColumns={5}
        isDownloadAvailable={false}
        searchTag={`Group: ${group.name}`}
        showCheckboxes={canChangeMembers()}
        showBulkAction={true}
        bulkContent={
          <>
            <UsersMoveIcon classNames="mr-1" />
            <span>
              Move {isSingularOrPluralPeopleText(allSelectedItems.length)}
            </span>
          </>
        }
        handleBulkAction={() => {
          setIsMovingMemberGroupModalOpen(true);
        }}
      />
      <GroupTreeModal
        group={group}
        isModalOpen={isMovingMemberGroupModalOpen}
        handleGroupModalClose={handleGroupModalClose}
      />
    </OverlayPage>
  );
};
