import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useFailedLoginCountriesSnapshotQuery } from '@hooks/failedLoginData';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
};

export const FailedLoginCountries: React.FC<TilesProps> = ({
  id,
  params,
  classNames,
  isSnapshot,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.FailedLoginCountries,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: failedLogins,
    isLoading: isFailedLoginsLoading,
    refetch,
  } = useFailedLoginCountriesSnapshotQuery(parameters);

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      days: updatedParams.days,
    });
  }, [params]);

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const getContextMenu = (): MenuItem[] => {
    if (!isSnapshot) {
      return isOnDashboardPage ? removeMenuItem : addMenuItem;
    } else {
      return [];
    }
  };  

  return (
    parameters && (
      failedLogins && failedLogins.snapshot && Object.keys(failedLogins.snapshot).length > 0 ? (
        <DoughnutChartTile
          dropdownMenuItemClasses={'!w-[300px]'}
          initialCompareSelectedItem={compareMenuItems.find(
            (item) => item.value === parameters.days
          )}
          contextData={getContextMenu()}
          classNames={
            classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
          }
          sortData={!isSnapshot ? compareMenuItems : []}
          headerTitle={'Failed MS logins: Countries'}
          isLoading={isFailedLoginsLoading}
          data={
            failedLogins && failedLogins.snapshot
              ? Object.values(failedLogins.snapshot).map((item) => +item)
              : []
          }
          buttonLabel={!isSnapshot ? 'Failed logins data' : ''}
          linkPath={ANALYTICS_NAVIGATION_MAP['failedLoginCountriesData']}
          dataLabels={
            failedLogins
              ? Object.keys(failedLogins.snapshot).map((item) => item + ':')
              : []
          }
          handleButtonClick={handleButtonClick()}
          showMessageIfEmpty={false}
        />
      ) : (
          <DoughnutChartTile
          dropdownMenuItemClasses={'!w-[300px]'}
          initialCompareSelectedItem={compareMenuItems.find(
            (item) => item.value === parameters.days
          )}
          contextData={getContextMenu()}
          classNames={
            classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
          }
          sortData={!isSnapshot ? compareMenuItems : []}
          headerTitle={'Failed MS logins: Countries'}
          isLoading={isFailedLoginsLoading}
          data={
            []           
          }
          buttonLabel={!isSnapshot ? 'Failed logins data' : ''}
          linkPath={''}
          dataLabels={
            failedLogins
              ? Object.keys(failedLogins.snapshot).map((item) => item + ':')
              : []
          }
          showMessageIfEmpty={true}
          emptyMessageTextAndSubtext={{
            Text: 'No failed logins',
            SubText: `There are no failed logins during the last ${parameters.days} days`,
          }}
        />
      )
    )
  );
};