
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSecurityReportPreviewQuery } from '@hooks/reports';
import { SecurityV2ReportSecureScoreResponse } from '@hooks/reports/types';
import styles from '@pages/InsightsAndAnalytics/Security/MsSecureScore/styles.module.css';
import { LineChartTile, LineChartTypes, LineChartTickFormat } from "@pages/InsightsAndAnalytics/tiles/LineChartTile";
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { Table, LineChartIconUp, LineChartIcon, MinusIcon } from '@components/ui';
import { DashboardTiles, REPORTS_FAILED_LOGINS_TABLE_COLUMNS } from '@common/constants';
import { formatDate, useTableData } from '@utils/index';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { createCellValue } from '@pages/InsightsAndAnalytics/Security/FailedLoginData/Accounts/utils';
import { TableWrapper } from '@components/partials';

type Change = "up" | "down" | "stayed the same";

export const ReportLoginActivityPage: React.FC = () => {
    const { reportId } = useParams<{ reportId: string }>();
    const location = useLocation();
    const selectedTab = location.pathname.split('/').pop();

    const query = useSecurityReportPreviewQuery<SecurityV2ReportSecureScoreResponse>({ reportId, selectedTab });
    const items = query?.data?.items || [];

    const usersWithMFA = items.find((item) => item.type === DashboardTiles.UsersWithMFAEnforced)?.parameters;
    const tableData = items.find((item) => item.type === DashboardTiles.Table)?.parameters;
    const failedLoginTypes = items.find((item) => item.type === DashboardTiles.FailedLoginTypes)?.parameters;
    const failedCountries = items.find((item) => item.type === DashboardTiles.FailedLoginCountries)?.parameters;
    const failedMSLogins = items.find((item) => item.type === DashboardTiles.FailedLoginAccounts)?.parameters;

    const failedLoginsInfo = items.find((item) => item.type === DashboardTiles.FailedLoginActivityInfo)?.parameters;

    const firstOfThePeriod = usersWithMFA?.data[0][0];
    const lastOfThePeriod = usersWithMFA?.data[0][usersWithMFA?.data[0]?.length - 1];

    const mfaChange: Change = (firstOfThePeriod < lastOfThePeriod
        ? "up"
        : firstOfThePeriod > lastOfThePeriod
            ? "down"
            : "stayed the same"
    ) as Change;

    const getSubtitleText = (change: string) => {
        if (change === "up")
            return "increased"
        if (change === "down")
            return "decreased"
        if (change === "stayed the same")
            return "remained the same"
        return ""
    }

    const labels = usersWithMFA?.labels.map((item: string) => formatDate(item));

    return (
        <>
            <div className='flex flex-col items-start mt-[20px] gap-4'>
                <div className='text-24 md:text-28 flex flex-row items-start'>
                    <p>Login security</p>
                    <div className="flex flex-row items-start ml-4 mt-2">
                        {mfaChange === "up" && (
                            <LineChartIconUp color="text-successColor-100" size="4" />
                        )}
                        {mfaChange === "down" && (
                            <LineChartIcon size="4" color="text-errorColor-100" />
                        )}
                        {mfaChange === "stayed the same" && (
                            <MinusIcon size="4" color="text-interfaceColor-100" withoutCircle={true} classNames='mt-[2px]' />
                        )}

                        <span
                            className={`ml-2 flex items-center text-xs ${mfaChange === "up"
                                ? 'text-successColor-100'
                                : mfaChange === "down"
                                    ? 'text-errorColor-100'
                                    : 'text-interfaceColor-80'
                                }`}
                        >
                            {mfaChange === "up"
                                ? "More secure"
                                : mfaChange === "down"
                                    ? "Less secure" :
                                    mfaChange === 'stayed the same' ?
                                        "The same" : ""
                            }
                        </span>
                    </div>
                </div>
                <div className='text-16 md:text-18 w-[70%] mb-[5px]'>
                    <p>
                        {!failedLoginsInfo?.currentPercentageMFA ?
                            `Here is a breakdown of any failed login activity over the reporting period, and a view of users with Microsoft MFA that's enforced. 
                        Enforced Microsoft MFA is trending ${mfaChange === 'stayed the same' ? 'the same' : mfaChange} over the reporting period.`
                            :
                            `There were ${failedMSLogins?.failedLoginCount?.isPercentIncreased ? 'more' : 'less'} 
                        failed logins this month than last month. MFA adoption is also trending ${mfaChange === 'stayed the same' ? 'the same' : mfaChange},
                         which may have caused a higher number of genuine login attempts to fail.
                        ${tableData?.data?.length < 10 ? `The 10 accounts with the most failed login attempts accounted for 
                        ${failedMSLogins?.failedLoginCount?.percent}% of the total number of failed login attempts.` : ''}`}
                    </p>
                </div>
            </div>
            <div className={styles.pageGrid}>
                {usersWithMFA != null &&
                    <LineChartTile
                        classNames={'col-span-6 lg:col-span-6 xl:col-span-6'}
                        sortData={[]}
                        tooltipLabel={'users'}
                        showCompareDropdown={false}
                        showAgainstDropdown={false}
                        contextData={[]}
                        headerTitle={'Users with Microsoft MFA enforced'}
                        isLoading={false}
                        labels={labels || undefined}
                        data={usersWithMFA?.data}
                        compareLabel=""
                        dropdownMenuItemClasses={'!w-[300px]'}
                        dataSetTypes={[LineChartTypes.Dots]}
                        dataSetColors={['#00CF6C']}
                        showYTicks={true}
                        showDateRange={true}
                        showXTicks={true}
                        isDotted={true}
                        chartHeight={150}
                        xTickFormat={LineChartTickFormat.Date}
                        yTickFormat={LineChartTickFormat.Number}
                    />}
            </div>
            <div className={styles.pageGrid}>
                {failedMSLogins != null &&
                    <NumberTile
                        dropdownMenuItemClasses={'!w-[300px]'}
                        classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
                        key={'count key'}
                        sortData={undefined}
                        contextData={[]}
                        percentageLabel={""}
                        headerTitle={failedMSLogins?.failedLoginCount?.label}
                        isLoading={false}
                        data={[failedMSLogins?.failedLoginCount?.total, failedMSLogins?.failedLoginCount?.percent]}
                        isPercentIncreased={
                            failedMSLogins?.failedLoginCount?.isPercentIncreased
                        }
                        bottomLabel={failedMSLogins?.failedLoginCount?.totalOverallText}
                        isArrowColorReversed={true}
                    />}

                {failedCountries != null &&
                    <DoughnutChartTile
                        classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
                        headerTitle={'Failed MS logins: Countries'}
                        dropdownMenuItemClasses={'!w-[300px]'}
                        data={failedCountries?.data || []}
                        dataLabels={failedCountries?.legends || []}
                        isLoading={false}
                        showBracketLabelValue={false}
                        contextData={[]}
                        key={'countries key'}

                    />}
                {failedLoginTypes != null &&
                    <DoughnutChartTile
                        classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
                        headerTitle={'Failed MS logins: Types'}
                        dropdownMenuItemClasses={'!w-[300px]'}
                        data={failedLoginTypes?.data || []}
                        dataLabels={failedLoginTypes?.legends || []}
                        isLoading={false}
                        showBracketLabelValue={false}
                        key={'login key'}
                        contextData={[]}
                    />}
            </div>
            <div className="mt-2 col-span-6 lg:col-span-6 xl:col-span-6">
                <div className='bg-white md:mx-auto lg:rounded-[4px] lg:shadow-shadow-02'>
                    <div className='text-24 p-4'>
                        <span>Accounts responsible for the most failed login attempts</span>
                    </div>
                    <TableWrapper
                        testId='reports-failed-login-table'
                        search=''
                        columns={REPORTS_FAILED_LOGINS_TABLE_COLUMNS}
                        data={useTableData(
                            { isFetched: true, data: { items: tableData?.data } },
                            REPORTS_FAILED_LOGINS_TABLE_COLUMNS,
                            createCellValue,
                            true
                        )()
                        }
                        query={query}
                        setPageNumber={() => undefined}
                        isCollapsable={true}
                        displayNoResults={!tableData?.data?.length}
                        noResultTitle={'There were no failed logins within the reporting period.'}
                        noResultSubtitle={''}
                        isFirstColumnSticky={false}
                        isDownloadAvailable={false}
                        disableSearch={true}
                        disableCountStats={true}
                    />
                </div>
            </div>
        </>
    );
};

