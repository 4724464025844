import {
  DEFAULT_PAGE_SIZE,
  SECURE_SCORE_SORT_BY,
  SECURE_SCORE_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { PeopleSortBy } from '@hooks/people/types';
import { useSecureScorePreviewQuery } from '@hooks/security';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { SecureScoreApps } from '@pages/Dashboard/tiles/SecureScoreApps';
import { SecureScoreChangesOverTime } from '@pages/Dashboard/tiles/SecureScoreChangesOverTime';
import { SecureScoreDevice } from '@pages/Dashboard/tiles/SecureScoreDevice';
import { SecureScoreIdentity } from '@pages/Dashboard/tiles/SecureScoreIdentity';
import { SecureScoreInfrastructure } from '@pages/Dashboard/tiles/SecureScoreInfrastructure';
import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { SecureScoreComparison } from '@pages/Dashboard/tiles';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;

const MsSecureScorePage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/assurance');
  const CATEGORY_FILTERS = ['Apps', 'Device', 'Identity', 'Infrastructure'];
  const STATUS_FILTERS = ['true'];
  const transformedFilterData: FilterData[] = [];

  transformedFilterData.push({
    label: 'Resources accessible',
    name: 'category',
    options: [
      { label: 'Apps', value: 'Apps' },
      { label: 'Device', value: 'Device' },
      {
        label: 'Identity',
        value: 'Identity',
      },
      {
        label: 'Infrastructure',
        value: 'Infrastructure',
      },
    ],
  });

  transformedFilterData.push({
    label: 'Status',
    name: 'status',
    singleSelect: true,
    options: [{ label: 'Include completed actions', value: 'true' }],
  });

  // table
  const query = useSecureScorePreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    category: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => CATEGORY_FILTERS.includes(filter))
      : [],
    secureScoreStatus: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => STATUS_FILTERS.includes(filter))
      : [],
  });

  const getTableData = useTableData(
    query,
    SECURE_SCORE_TABLE_COLUMNS,
    createCellValue,
    false
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.SECURE_SCORE_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      category: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => CATEGORY_FILTERS.includes(filter))
        : [],
      secureScoreStatus: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => STATUS_FILTERS.includes(filter))
        : [],
    },
    filename: `Clevr360_Microsoft_Secure_Score_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Microsoft Secure Score`}
    >
      <>
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <SecureScoreChangesOverTime
                params=""
                classNames="col-span-6 lg:col-span-3 xl:col-span-2"
              />
              <SecureScoreComparison
                params=""
                classNames="col-span-6 lg:col-span-3 xl:col-span-2"
              />
              <SecureScoreIdentity
                params=""
                classNames="col-span-6 lg:col-span-3 xl:col-span-1"
              />
              <SecureScoreDevice
                params=""
                classNames="col-span-6 lg:col-span-3 xl:col-span-1"
              />
              <SecureScoreApps
                params=""
                classNames="col-span-6 lg:col-span-3 xl:col-span-1"
              />
              <SecureScoreInfrastructure
                params=""
                classNames="col-span-6 lg:col-span-3 xl:col-span-1"
              />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            testId='secure-score-table'
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by improvement action or category'}
            columns={SECURE_SCORE_TABLE_COLUMNS}
            data={getTableData()}
            sortData={SECURE_SCORE_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={2}
            floatingFilterButton={false}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="security actions"
            filterHeaderText="Filter Secure Score"
            isFirstColumnSticky={false}
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default MsSecureScorePage;
