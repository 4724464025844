import {
  DEFAULT_PAGE_SIZE,
  GROUP_TABLE_COLUMNS,
  PEOPLE_FILTER_BY,
  PEOPLE_SORT_BY,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage } from '@components/partials/OverlayPage';
import { TableWrapper } from '@components/partials/TableWrapper';
import { Loader } from '@components/ui/Loader';
import { useUpdateGroupMembers } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { usePersonsQuery } from '@hooks/people';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { usePagination } from '@hooks/utils/pagination';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useTableData } from '../../../utils';
import { createCellValue } from './utils';
import { Variant } from '@components/ui/Toast';
import { UserRoles } from '@hooks/users/types';
import useAuthStore from '@store/authStore';

const pageSize = DEFAULT_PAGE_SIZE;

export const AddRemoveMembers: React.FC = () => {
  const { addToast } = useUIStore();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const location = useLocation();
  const group: GroupsResponse = location.state?.node;
  const isMobile = useIsMobile();

  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  const personsQuery = usePersonsQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: queryParams.filter,
  });
  const getTableData = useTableData(
    personsQuery,
    GROUP_TABLE_COLUMNS,
    createCellValue
  );

  const { reset, newlySelectedItems, newlyUnselectedItems } = useTableStore();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const loggedInUserId = useAuthStore.getState().user?.id || '';

  const canChangeMembers = () => {
    if (userRole === UserRoles.SUPERADMIN || userRole === UserRoles.SUPPORT) {
      return true;
    } else if (userRole === UserRoles.ADMIN) {
      const ids: string[] = [];
      if (group.groupViewers) {
        group.groupViewers.forEach((user) => {
          ids.push(user.userId);
        });
      }

      return ids.includes(loggedInUserId);
    } else {
      return false;
    }
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const updateGroupMembersMutation = useUpdateGroupMembers(groupId);

  const handleUpdateMembers = async () => {
    // tmp fix
    if (!canChangeMembers()) {
      return;
    }

    const data = {
      addedMemberIds: newlySelectedItems,
      removedMemberIds: newlyUnselectedItems,
    };

    try {
      await updateGroupMembersMutation.mutateAsync(data);
      addToast({
        variant: Variant.Success,
        message: 'We’ve saved your changes',
        closeable: true,
      });
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Failed to update members',
        closeable: true,
      });
    }

    navigate('/accounts/groups');
  };

  const getFooterText = (): string => {
    let newSelectedText = '';
    let removeSelectedText = '';

    if (newlySelectedItems.length) {
      newSelectedText = `${newlySelectedItems.length} will be moved to ${group.name}.`;
    }

    if (newlyUnselectedItems.length) {
      removeSelectedText = `${newlyUnselectedItems.length} will be removed.`;
    }

    return `${newSelectedText} ${removeSelectedText}`;
  };

  return (
    <OverlayPage
      footerText={getFooterText()}
      isFooterVisible={true}
      isCancelBtnVisible={!isMobile}
      handleOnSave={handleUpdateMembers}
      path="/accounts/groups"
      headerTitle={`People in ${group.name}`}
      headerText="Select people to move them to another group."
    >
      {!personsQuery.isFetched ||
      !personsQuery.data ||
      personsQuery.isLoading ? (
        <Loader />
      ) : (
        <TableWrapper
          testId='people-table'
          search={search}
          setSearch={setSearch}
          columns={GROUP_TABLE_COLUMNS}
          data={getTableData()}
          sortData={PEOPLE_SORT_BY}
          filterData={PEOPLE_FILTER_BY}
          searchKey="search"
          query={personsQuery}
          refetchQuery={personsQuery.refetch}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          setPageNumber={setPageNumber}
          isCollapsable={false}
          sliceColumns={5}
          isDownloadAvailable={false}
          searchTag={`Group: ${group.name}`}
          showCheckboxes={canChangeMembers()}
          filterByData={group.id}
          filterByKey={'groups'}
        />
      )}
    </OverlayPage>
  );
};
