import closeIcon from '@assets/images/close_icon.png';

import { DEFAULT_ANIMATION_DURATION } from '@common/constants';
import { Button } from '@components/ui';
import { useIsMobile } from '@hooks/utils/isMobile';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { CSSTransition } from 'react-transition-group';
import styles from './styles.module.css';
import useAuthStore from '@store/authStore';
import { debounce } from '@utils/index';

type OverlayPageProps = {
  headerTitle: string;
  headerText?: string;
  children: React.ReactNode;
  headerBtnLabel?: string;
  isFooterVisible?: boolean;
  isCancelBtnVisible?: boolean;
  footerText?: React.ReactNode | string;
  path?: string;
  handleOnSave?: () => void;
  handleOnClose?: () => void;
  isDisabled?: boolean;
  overlayClassNames?: string;
  contentClassNames?: string;
  footerCancelBtnLabel?: string;
  footerSaveBtnLabel?: string;
  onScrollToEnd?: () => void;
  allDataLoaded?: boolean;
  isLoading?: boolean;
  hasScroll?: boolean;
  hideCloseOverlay?: boolean;
};

export const OverlayPage: React.FC<OverlayPageProps> = ({
  headerTitle,
  headerText,
  children,
  headerBtnLabel = '',
  isFooterVisible = true,
  isCancelBtnVisible = true,
  footerText,
  path,
  isDisabled,
  handleOnSave,
  handleOnClose,
  overlayClassNames,
  contentClassNames,
  footerSaveBtnLabel = 'Save',
  footerCancelBtnLabel = 'Cancel',
  onScrollToEnd,
  allDataLoaded,
  isLoading,
  hasScroll = false,
  hideCloseOverlay = false,
}) => {
  const { isTermsAndCondtitionsGranted, selectedProfile } = useAuthStore();
  const nodeRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [overlayOpen, setOverlayOpen] = useState(false);
  const {
    isOverlayFixed,
    setIsOverlayFixed,
    setOverlayHeaderHeight,
    setIsAtStickyPositions,
    setNavSource,
    setExternalTeamsDetails,
  } = useUIStore();
  const headerElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.body.style.overflow =
      overlayOpen && !hasScroll ? 'hidden' : 'auto';
  }, [overlayOpen, hasScroll]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOverlayOpen(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const { reset } = useTableStore();
  const closeOverlay = useCallback(() => {
    setOverlayOpen(false);
    setIsOverlayFixed(true);
    reset();
    setIsAtStickyPositions(false);
    setNavSource(null);
    setOverlayHeaderHeight(0);
    setExternalTeamsDetails({ email: '', ids: [] });
    handleOnClose?.();
    document.body.style.overflow = 'auto';
    debounce(() => { 
      path ? navigate(path) : navigate(-1);
     }, DEFAULT_ANIMATION_DURATION)();
  }, [
    handleOnClose,
    navigate,
    path,
    reset,
    setExternalTeamsDetails,
    setIsAtStickyPositions,
    setIsOverlayFixed,
    setNavSource,
    setOverlayHeaderHeight,
  ]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeOverlay();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [closeOverlay]);

  const handleClick = () => {
    handleOnSave?.();
  };

  const handleScroll = useCallback(() => {
    const container = nodeRef.current as unknown as HTMLDivElement;

    if (
      onScrollToEnd &&
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + 40 &&
      !allDataLoaded &&
      !isLoading
    ) {
      onScrollToEnd?.();
    }
  }, [allDataLoaded, isLoading, onScrollToEnd]);

  useEffect(() => {
    const container = nodeRef.current as unknown as HTMLDivElement;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [onScrollToEnd, allDataLoaded, handleScroll]);

  const overlayStyles = {
    enter: styles.overlayEnter,
    enterActive: styles.overlayEnterActive,
    exit: styles.overlayExit,
    exitActive: styles.overlayExitActive,
  };

  return (
    <CSSTransition
      nodeRef={nodeRef}
      timeout={DEFAULT_ANIMATION_DURATION}
      onEnter={() => {
        headerElementRef?.current &&
          setOverlayHeaderHeight(headerElementRef.current.clientHeight);
      }}
      classNames={overlayStyles}
      unmountOnExit
      appear
      in={overlayOpen}
      id="overlay-wrapper"
    >
      <div
        ref={nodeRef}
        onScroll={handleScroll}
        className={`${
          isOverlayFixed ? styles.overlayWrapper : 'bg-interfaceColor-5'
        }`}
      >
        <StickyBox offsetTop={0} className="z-20 md:ml-0 md:mr-0">
          <div
            ref={headerElementRef}
            className={`${styles.overlay} ${overlayClassNames}`}
          >
            <div className={styles.headerWrapper} data-testid="header-title">
              {headerTitle}
            </div>
            {!hideCloseOverlay && (
              <div className={styles.closeBtn}>
                <button
                  type="button"
                  onClick={closeOverlay}
                  data-testid="close-btn"
                >
                  <img src={closeIcon} alt="Close Overlay Page" />
                </button>
              </div>
            )}

            {headerText && (
              <div className={styles.headerText} data-testid="header-text">
                {headerText}
              </div>
            )}

            <div>
              {headerBtnLabel && (
                <Button
                  testId='header-button'
                  className={styles.headerBtnLabel}
                  variant="primary"
                  size={isMobile ? 'small' : 'medium'}
                >
                  {headerBtnLabel}
                </Button>
              )}
            </div>
          </div>
        </StickyBox>

        <div
          className={`${styles.content} ${
            contentClassNames ? contentClassNames : ''
          }`}
          data-testid="content-text"
        >
          {children}
        </div>

        {isFooterVisible && (
          <div className={styles.footerWrapper}>
            <div className={styles.footerContent}>
              <p data-testid="footer-text" className={styles.footerText}>
                {footerText}
              </p>

              <div className="flex items-center">
                {isCancelBtnVisible && (
                  <Button
                    testId='footer-cancel-button'
                    className={styles.footerBtn}
                    variant="outlineDark"
                    size={isMobile ? 'small' : 'medium'}
                    onClick={closeOverlay}
                  >
                    {footerCancelBtnLabel}
                  </Button>
                )}
                <Button
                  testId='footer-save-button'
                  disabled={isDisabled}
                  onClick={handleClick}
                  variant="primary"
                  size={isMobile ? 'small' : 'medium'}
                >
                  {footerSaveBtnLabel}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </CSSTransition>
  );
};
