import cn from 'classnames';
import {
  CloseIcon,
  WarningIcon,
  CheckCircleIcon,
  WarningCircleIcon,
} from '@components/ui/Icons';
import styles from './styles.module.css';
import { Button } from '../Button';

type BannerProps = {
  message: string | React.ReactNode | undefined | null;
  title?: string;
  variant?: 'info' | 'warning' | 'error' | 'success';
  closeable?: boolean;
  onClose?: () => void;
  className?: string;
  actionButtons?: React.ReactNode;
  ctaBtnLabel?: string;
  ctaBtnOnClick?: () => void;
};

const variantClasses = {
  info: styles.info,
  warning: styles.warning,
  error: styles.error,
  success: styles.success,
};

export const Banner: React.FC<BannerProps> = ({
  message = '',
  closeable = false,
  variant = 'info',
  className,
  onClose,
  title = '',
  actionButtons,
  ctaBtnLabel,
  ctaBtnOnClick,
}) => {
  const getIconByVariant = (variant: BannerProps['variant']) => {
    switch (variant) {
      case 'warning':
        return (
          <WarningCircleIcon
            classNames={styles.icons}
            color="text-warningColor-150"
          />
        );
      case 'info':
        return <WarningCircleIcon classNames={styles.icons} />;
      case 'error':
        return <WarningIcon classNames={styles.icons} />;
      case 'success':
        return <CheckCircleIcon classNames={styles.icons} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={cn(styles.wrapper, variantClasses[variant], className)}
      role="alert"
    >
      <div className="flex flex-col items-start justify-between gap-y-3 xl:flex-row xl:gap-y-0">
        {getIconByVariant(variant)}

        {title ? (
          <div className="ml-6 flex flex-col">
            <p data-testid="banner-title" className="text-20">
              {title}
            </p>
            <p data-testid="banner-message" className="text-16">
              {message}
            </p>
          </div>
        ) : (
          <p data-testid="banner-message" className="ml-6 pr-3 text-16 md:pr-2">
            {message}
          </p>
        )}

        {ctaBtnLabel && (
          <Button
            testId='banner-button'
            variant="outlineLight"
            size="extraSmall"
            className={cn('ml-6 mt-3 md:mt-0', { 'md:mr-5': closeable })}
            onClick={ctaBtnOnClick}
          >
            {ctaBtnLabel}
          </Button>
        )}

        {closeable && (
          <button
            data-testid="banner-close-button"
            data-dismiss="alert"
            aria-label="Close"
            onClick={onClose}
            title="Close alert"
            className={styles.alertBtn}
          >
            <span aria-hidden="true">
              <CloseIcon classNames="h-3 w-3" />
            </span>
          </button>
        )}
      </div>
      <div data-testid="banner-actionBtn">{actionButtons && actionButtons}</div>
    </div>
  );
};
