import {
  DEFAULT_PAGE_SIZE,
  PERSON_LICENSES_TABLE_COLUMNS,
} from '@common/constants';
import { OverlayPage, TableWrapper } from '@components/partials';

import { usePagination } from '@hooks/utils/pagination';
import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { Card } from '@components/ui/Card';
import {
  Button,
  EmailIcon,
  MinusIcon,
  PlusIcon,
  UsersLocationMoveIcon,
} from '@components/ui';
import { DeleteGroupModal } from '@pages/PeopleAndGroups/Groups/Modals/DeleteGroupModal';
import { GroupsResponse } from '@hooks/groups/types';
import { useParams } from 'react-router';
import { usePersonLicensesPreviewQuery, usePersonQuery } from '@hooks/people';
import { MoveLocationModal } from '@pages/PeopleAndGroups/Locations/Modals/MoveLocationModal';
import { useLocationsQuery } from '@hooks/locations';
import { GroupTreeModal } from '@pages/PeopleAndGroups/Groups/Modals/GroupTreeModal';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';

const pageSize = DEFAULT_PAGE_SIZE;

const PersonPreviewPage: React.FC = () => {
  const [isMovingMemberLocationModalOpen, setIsMovingMemberLocationModalOpen] =
    useState(false);
  const handleLocationModalClose = () => {
    setIsMovingMemberLocationModalOpen(false);
  };
  const { pageNumber, setPageNumber } = usePagination();
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);
  const [isMoveGroupModalOpen, setIsMoveGroupModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<
    GroupsResponse | undefined
  >(undefined);

  const { personId } = useParams<{ personId: string }>();

  const { data, refetch } = usePersonQuery({
    personId: personId,
  });

  // table
  const query = usePersonLicensesPreviewQuery({
    pageSize,
    pageNumber,
    assignedTo: data?.emailAddress,
  });

  const getTableData = useTableData(
    query,
    PERSON_LICENSES_TABLE_COLUMNS,
    createCellValue
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.PERSON_LICENSES}/export`,
    params: {
      pageSize,
      pageNumber,
      assignedTo: data?.emailAddress,
    },
    filename: `Clevr360_${data?.fullName}_licenses_${formatDate(
      new Date().toString()
    )}.csv`,
  });
  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  const locationsQuery = useLocationsQuery();

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      headerTitle={data?.fullName || ''}
    >
      <>
        <div className={styles.pageGrid}>
          <div className="col-span-4 lg:col-span-2 xl:col-span-2">
            <h2 className="mb-3 text-20 font-normal text-interfaceColor-100">
              Details
            </h2>
            <Card classNames="!w-full mb-4 h-auto md:h-[125px]">
              <Card.Content>
                <div className="flex flex-col">
                  <span className="mb-2 text-16 font-semibold text-interfaceColor-100">
                    Member since {formatDate(data?.userSince || '')}
                  </span>
                  <span className="mb-3 flex flex-row items-center gap-x-2">
                    <EmailIcon color="text-interfaceColor-100" />
                    <a
                      href={`mailto:${data?.emailAddress}`}
                      className="tex-16  border-b border-b-interfaceColor-40 font-light text-interfaceColor-100"
                    >
                      {data?.emailAddress || '-'}
                    </a>
                  </span>
                </div>
              </Card.Content>
            </Card>
          </div>
          <div className="col-span-4 lg:col-span-2 xl:col-span-2">
            <h2 className="mb-3 text-20 font-normal text-interfaceColor-100">
              Location
            </h2>
            <Card classNames="!w-full mb-4 h-auto md:h-[125px]">
              <Card.Content>
                <div className="flex flex-col">
                  <span className="mb-0 text-16 font-semibold text-interfaceColor-100">
                    {data?.location?.name || '-'}
                  </span>
                  <span className="mb-2 text-16 font-normal text-interfaceColor-80">
                    {data?.location?.address || ''}
                  </span>
                </div>
              </Card.Content>
              <Card.Footer>
                <Button
                  testId='move-locations-button'
                  variant="outlineLight"
                  type="button"
                  size="small"
                  onClick={() => {
                    setIsMovingMemberLocationModalOpen(true);
                  }}
                >
                  <div className="flex items-center gap-x-1">
                    <UsersLocationMoveIcon classNames="mr-[2px]" />
                    Move locations
                  </div>
                </Button>
              </Card.Footer>
            </Card>
          </div>

          <div className="col-span-4">
            <h2 className="mb-3 text-20 font-normal text-interfaceColor-100">
              Groups
            </h2>

            {data?.groups && (
              <div className="grid grid-cols-4 gap-x-8">
                {data?.groups.map((item) => {
                  return (
                    <div
                      key={item.name}
                      className="col-span-4 lg:col-span-2 xl:col-span-1"
                    >
                      <Card classNames="!w-full mb-4 h-auto md:h-[160px] flex justify-between">
                        <Card.Content classNames="mb-2 md:mb-1">
                          <span className="text-16 font-semibold text-interfaceColor-100">
                            {item.name}
                          </span>
                          <br />
                          {item.parentGroupName
                            ? `Subgroup of ${item.parentGroupName}`
                            : null}
                        </Card.Content>
                        <Card.Footer>
                          <Button
                            testId='remove-button'
                            variant="outlineLight"
                            type="button"
                            size="small"
                            onClick={() => {
                              setSelectedGroup(item);
                              setIsDeleteGroupModalOpen(true);
                            }}
                          >
                            <div className="flex items-center gap-x-1">
                              <MinusIcon size="5" classNames="mt-1" />
                              Remove
                            </div>
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                  );
                })}
                <div
                  onClick={() => setIsMoveGroupModalOpen(true)}
                  className="col-span-4 cursor-pointer  lg:col-span-2 xl:col-span-1"
                >
                  <Card classNames="!w-full mb-4 h-auto md:h-[160px] flex !justify-center !items-start !bg-interfaceColor-5 hover:border hover:border-primaryBrandColor-100 transition-border duration-75">
                    <Card.Content classNames="flex flex-col items-start gap-y-2 justify-center">
                      <PlusIcon color="text-primaryBrandColor-150" size="7" />
                      <span>Add {data?.fullName} to another group</span>
                    </Card.Content>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-2">
          <TableWrapper
            testId='persons-table'
            columns={PERSON_LICENSES_TABLE_COLUMNS}
            data={getTableData()}
            query={query}
            refetchQuery={query.refetch}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={1}
            floatingFilterButton={false}
            hasFilterBanner={false}
            isLicensePage={true}
            isSimpleTable={true}
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
      <DeleteGroupModal
        group={selectedGroup}
        modalTitle={`Remove from ${selectedGroup?.name}`}
        modalCancelBtnText={'Cancel'}
        modalRemoveBtnText={'Confirm: remove from group'}
        modalBodyText={`You’re removing ${data?.fullName} from all insights, analytics and reports related to ${selectedGroup?.name}. This can’t be undone, but you can add them back into the group.`}
        isModalOpen={isDeleteGroupModalOpen}
        handleModalClose={() => {
          setIsDeleteGroupModalOpen(false);
          setSelectedGroup(undefined);
        }}
        handleSuccess={() => {
          setIsDeleteGroupModalOpen(false);
          setSelectedGroup(undefined);
          refetch();
        }}
        memberId={data?.id}
      />
      <MoveLocationModal
        headerText={`Select a location to move ${data?.fullName} to`}
        items={(locationsQuery.data || []).map((item) => ({
          id: item.id,
          name: item.name,
        }))}
        selectedLocation={data?.location}
        isModalOpen={isMovingMemberLocationModalOpen}
        handleLocationModalClose={handleLocationModalClose}
        isListView={true}
        onSubmit={(value) => {
          if (value) {
            setIsMovingMemberLocationModalOpen(false);
            refetch();
          }
        }}
        memberId={data?.id}
      />
      <GroupTreeModal
        selectedGroups={data?.groups.map((item) => item.id)}
        modalHeader={`Select a group to add ${data?.fullName} to`}
        isModalOpen={isMoveGroupModalOpen}
        handleGroupModalClose={() => {
          setIsMoveGroupModalOpen(false);
        }}
        buttonSubmitText={'Add to Group'}
        handleSuccess={() => {
          setIsMoveGroupModalOpen(false);
          refetch();
        }}
        memberId={data?.id}
      />
    </OverlayPage>
  );
};

export default PersonPreviewPage;
