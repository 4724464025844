import { Banner, Button, Modal, RadioButton } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useCreateUser, useUpdateUserRole } from '@hooks/users';
import { User, UserRoles } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useAuthStore from '@store/authStore';
import useUIStore from '@store/uiStore';
import { useEffect, useState } from 'react';

export type AccessLevelModalProps = {
  user: User | undefined;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
  superAdminCount: number;
};

export const AccessLevelModal: React.FC<AccessLevelModalProps> = ({
  user,
  isModalOpen,
  handleModalClose,
  handleSuccess,
  superAdminCount,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [userRole, setUserRole] = useState<string>(
    user?.role || UserRoles.STANDARD
  );
  const [isLastSuperAdmin, setIsLastSuperAdmin] = useState(false);
  const currentUserRole = useAuthStore.getState().role;

  useEffect(() => {
    setUserRole(user?.role || UserRoles.STANDARD);
    setIsLastSuperAdmin(
      superAdminCount === 1 && user?.role === UserRoles.SUPERADMIN
    );
  }, [isModalOpen]);

  const { mutateAsync, isLoading } = useUpdateUserRole(user?.id);
  const createUser = useCreateUser();

  const handleUpdateRole = async () => {
    if (user?.id) {
      try {
        await mutateAsync({ roleName: userRole as UserRoles });
        addToast({
          variant: Variant.Success,
          message: `We’ve updated ${user?.fullName}’s access level`,
          closeable: true,
        });
        handleSuccess();
      } catch (error) {
        addToast({
          variant: Variant.Error,
          message: 'Something went wrong, and your changes haven’t been saved.',
          closeable: true,
        });
      }
    } else {
      if (user && !user.id) {
        const data: Partial<User> = {
          lastName: user.lastName,
          firstName: user.firstName,
          phone: user?.phone,
          emailAddress: user.emailAddress,
          role: userRole,
        };

        try {
          await createUser.mutateAsync(data);
          addToast({
            variant: Variant.Success,
            message: `We’ve invited ${user.firstName} ${user.lastName} to the Hub.`,
            closeable: true,
          });
          handleSuccess();
        } catch (error) {
          addToast({
            variant: Variant.Error,
            message:
              'Something went wrong, and your changes haven’t been saved.',
            closeable: true,
          });
        }
      }
    }
  };

  return (
    <Modal
      testId='set-access-level-modal'
      headerTxt="Set access level"
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
            testId='save-button'
            type="submit"
            variant="primary"
            disabled={isLoading || isLastSuperAdmin}
            onClick={handleUpdateRole}
          >
            Save changes
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose} testId='cancel-button'>
            Cancel
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div>
        <Banner
          variant="info"
          message={
            isLastSuperAdmin
              ? 'Because this user is your only super admin, you won’t be able to change their access level.'
              : 'To keep your Clevr360 secure, only give new users the access level they need in order to do their job.'
          }
        />
        <p className="mt-5 text-16 font-semibold leading-6 text-interfaceColor-100">
          Access level
        </p>
        <div className="mt-2">
          <RadioButton
            name={UserRoles.STANDARD}
            value={UserRoles.STANDARD}
            label="Standard"
            disabled={isLastSuperAdmin}
            checked={userRole === UserRoles.STANDARD}
            onChange={() => setUserRole(UserRoles.STANDARD)}
          ></RadioButton>
          <span className="ml-[37px] inline-block text-14 text-interfaceColor-80">
            Standard users can view information in Clevr360 and subscribe to
            email updates.
          </span>
        </div>
        <div className="mb-2 mt-4">
          <RadioButton
            name={UserRoles.ADMIN}
            value={UserRoles.ADMIN}
            label="Admin"
            disabled={isLastSuperAdmin}
            checked={userRole === UserRoles.ADMIN}
            onChange={() => setUserRole(UserRoles.ADMIN)}
          ></RadioButton>
          <span className="ml-[37px] inline-block text-14 text-interfaceColor-80">
            Admin users can add, edit, and remove other Clevr360 users, unlock
            blocked accounts, and create or edit groups and locations.
          </span>
        </div>
        {(currentUserRole === UserRoles.SUPERADMIN ||
          currentUserRole === UserRoles.SUPPORT) && (
          <div className="mb-2 mt-4">
            <RadioButton
              name={UserRoles.SUPERADMIN}
              value={UserRoles.SUPERADMIN}
              label="Super admin"
              disabled={isLastSuperAdmin}
              checked={userRole === UserRoles.SUPERADMIN}
              onChange={() => setUserRole(UserRoles.SUPERADMIN)}
            ></RadioButton>
            <span className="ml-[37px] inline-block text-14 text-interfaceColor-80">
              Super admin users can do everything admin users can do. They can
              also change other Clevr360 users&apos; admin privileges, add,
              edit, and remove connected services.
            </span>
          </div>
        )}
      </div>
    </Modal>
  );
};
