import {
  DEFAULT_PAGE_SIZE,
  DEVICE_ADOPTION_SORT_BY,
  DEVICE_ADOPTION_TABLE_COLUMNS,
  VENDORS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { useDeviceAdoptionPreviewQuery } from '@hooks/adoption';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import { formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { createCellValue } from './utils';
import { useDeviceInventoryTypesQuery } from '@hooks/utilisation';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { useLicencesFiltersQuery } from '@hooks/licenses';
import {
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { Location } from '@hooks/locations/types';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import styles from './styles.module.css';
import { useIsDesktop } from '@hooks/utils';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';

const pageSize = DEFAULT_PAGE_SIZE;

const DeviceAdoptionPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/adoption');

  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  // filters data
  const { data: typesData, isFetched: typesDataFetched } =
    useDeviceInventoryTypesQuery();
  const { data: filterData, isFetched: filterDataFetched } =
    useLicencesFiltersQuery('true');
  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  // table
  const query = useDeviceAdoptionPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    typeList:
      queryParams.filter && typesData
        ? queryParams.filter
            .split(',')
            .filter((filter) => typesData.includes(filter))
        : [],
  });
  const getTableData = useTableData(
    query,
    DEVICE_ADOPTION_TABLE_COLUMNS,
    createCellValue
  );

  const typesFilerOptions: IOption[] = [];
  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
  };

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  if (
    filterDataFetched &&
    locationDataFetched &&
    groupsDataFetched &&
    typesDataFetched
  ) {
    if (filterData) {
      Object.keys(filterData).forEach((property) => {
        if (property == 'vendor') {
          const filterDataArray: FilterData = {
            label: getPropertyLabel(property),
            name: property.toLowerCase(),
            options: [],
            singleSelect: !!(property === 'vendor'),
          };

          const propertyData =
            filterData[property as keyof LicensesFiltersResponse];

          if ('value' in propertyData) {
            const item = propertyData as LicensesFilterData;

            const option = {
              label: item.value || item.vendor,
              value: item.value || item.vendor,
              count: item.count,
            };

            filterDataArray.options.push(option);
          } else {
            const items = propertyData as unknown as LicensesFilterData[];

            items.forEach((item) => {
              const option = {
                label: item.value || item.vendor,
                value: item.value || item.vendor,
              };

              filterDataArray.options.push(option);
            });
          }
          transformedFilterData.push(filterDataArray);
        }
      });
    }

    if (typesData) {
      typesData?.forEach((type) => {
        typesFilerOptions.push({ label: type, value: type });
      });

      transformedFilterData.push({
        label: 'Type',
        name: 'type',
        singleSelect: false,
        options: typesFilerOptions,
      });
    }

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv, isLoading: isLoadingDownload } = useDownloadCsv(
    {
      shouldDownload,
      endpoint: API_ENDPOINTS.DEVICE_ADOPTION_EXPORT,
      params: {
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        locationList: queryParams.filter
          ? queryParams.filter
              .split(',')
              .filter((filter) => locationIds.includes(filter))
          : [],
        groupList: queryParams.filter
          ? queryParams.filter
              .split(',')
              .filter((filter) => groupIds.includes(filter))
          : [],
        vendorList: queryParams.filter
          ? queryParams.filter
              .split(',')
              .filter((filter) => VENDORS.includes(filter as any))
          : [],
        typeList:
          queryParams.filter && typesData
            ? queryParams.filter
                .split(',')
                .filter((filter) => typesData.includes(filter))
            : [],
      },
      filename: `Clevr360_Device_adoption_${formatDate(
        new Date().toString()
      )}.csv`,
    }
  );

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Device adoption`}
    >
      <>
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <RecommendationCard insightDescription={'device adoption'} />
            </>
          )}
        </div>
        <div className="mt-[45px]">
          <TableWrapper
            testId='device-adoption-table'
            search={search}
            setSearch={setSearch}
            searchPlaceholder={
              'Search by product, manufacturer, name, email address'
            }
            columns={DEVICE_ADOPTION_TABLE_COLUMNS}
            data={getTableData()}
            sortData={DEVICE_ADOPTION_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={1}
            floatingFilterButton={true}
            isDownloadAvailable={true}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="devices"
            filterHeaderText="Filter device adoption"
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default DeviceAdoptionPage;
