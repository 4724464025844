import {
  DEFAULT_PAGE_SIZE,
  LAST_DAYS_SORT_BY,
  USER_SERVICE_ADOPTION_SORT_BY,
  USER_SERVICE_ADOPTION_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { Dropdown } from '@components/ui/Dropdown';
import { useUserServiceAdoptionPreviewQuery } from '@hooks/adoption';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { useCreateCellValue } from './utils';
import { UserServiceAdoptionTrend } from '@pages/Dashboard/tiles/UserServiceAdoptionTrend';
import useTableStore from '@store/tableStore';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;
const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const UserServiceAdoptionPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [days, setDays] = useState('30');
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[1].label || ''
  );
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/adoption');
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  const FILTERS = ['microsoft', 'ringcentral'];

  const transformedFilterData: FilterData[] = [];

  transformedFilterData.push({
    label: 'Vendor',
    name: 'filter',
    singleSelect: true,
    options: [
      { label: 'Microsoft', value: 'microsoft' },
      {
        label: 'RingCentral',
        value: 'ringcentral',
      },
    ],
  });

  // table
  const query = useUserServiceAdoptionPreviewQuery({
    days,
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    userServiceAdoptionFilter: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
      : [],
  });

  const getTableData = useTableData(
    query,
    USER_SERVICE_ADOPTION_TABLE_COLUMNS,
    useCreateCellValue
  );

  const { setExpandedRows, setExpandedRowId } = useTableStore();
  useEffect(() => {
    if (query.data?.items) {
      setExpandedRowId(-1, false);
      setExpandedRows(
        query.data.items.map((service) => {
          return {
            ...service,
            vendors: service.vendors.filter(
              (vendor) => vendor.vendor !== 'All'
            ),
          };
        })
      );
    }
  }, [query.data, setExpandedRows]);

  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
    query.refetch();
  };

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.USER_SERVICE_ADOPTION_ACTIVITY}/export`,
    params: {
      days,
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      userServiceAdoptionFilter: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => FILTERS.includes(filter))
        : [],
    },
    filename: `Clevr360_Service_adoption_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Service adoption`}
    >
      <>
        {!isMobile && (
          <div className="absolute left-[40px] top-[113px]">
            <Dropdown>
              <Dropdown.TextHeader
                classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                label={daysSelectedLabel}
                handleOpen={handleIsDaysDropdownOpen}
              ></Dropdown.TextHeader>
              <Dropdown.List
                className="!z-[999] text-16"
                open={isDaysDropdownOpen}
                align="left"
              >
                {floatingSortDropdownData.map((item, index) => {
                  return (
                    <Dropdown.DefaultItem
                      key={index}
                      item={item}
                      currentIndex={index}
                      selectedIndex={daysSelectedIndex}
                      onChange={() =>
                        handleDropdownDaysOnChange(item.value, index)
                      }
                    ></Dropdown.DefaultItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown>
          </div>
        )}
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <UserServiceAdoptionTrend
                classNames="col-span-6 md:col-span-6 xl:col-span-2"
                params={JSON.stringify({ days, dataType: 'messages' })}
              />
              <RecommendationCard insightDescription={'service adoption'} />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            testId='service-adoption-table'
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by service or vendor'}
            columns={USER_SERVICE_ADOPTION_TABLE_COLUMNS}
            data={getTableData()}
            sortData={USER_SERVICE_ADOPTION_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={1}
            floatingFilterButton={true}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="services"
            floatingFilterButtonLeft={!isMobile ? '190px' : ''}
            filterHeaderText="Filter service adoption"
            customTotalCount={true}
            hasExpandedItems={true}
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default UserServiceAdoptionPage;
