import { NoResults } from '@components/partials';
import { Banner, Button, Loader, TreeView } from '@components/ui';
import { ArrowRightIcon, PlusIcon } from '@components/ui/Icons';
import { useGroupsQuery } from '@hooks/groups';
import { useUnassignedGroupsInfoQuery } from '@hooks/people';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateEditGroupModal } from './Modals/CreateEditGroupModal';
import { DeleteGroupModal } from './Modals/DeleteGroupModal';
import { GroupsResponse } from '@hooks/groups/types';
import { useQueryClient } from 'react-query';
import { GroupTreeModal } from './Modals/GroupTreeModal';
import {
  GroupVisibilityFlow,
  ManageGroupVisibilityModal,
} from './Modals/ManageGroupVisibilityModal';
import { ViewGroupVisibilityModal } from './Modals/ViewGroupVisibilityModal';
import { useUsersQuery } from '@hooks/users';
import { UserRoles, Permissions } from '@hooks/users/types';
import { hasPermission } from '@utils/index';
import useAuthStore from '@store/authStore';
import useUIStore from '@store/uiStore';

const GroupPage: React.FC = () => {
  const query = useGroupsQuery();
  const hubUsersQuery = useUsersQuery({ pageSize: 200, pageNumber: 1 });
  const navigate = useNavigate();
  const { setGroupData } = useUIStore();
  const unassignedGroupQuery = useUnassignedGroupsInfoQuery();
  const [unassignedBannerVisible, setUnassignedBannerVisible] = useState(true);
  const [isCreateEditGroupModalOpen, setIsCreateEditGroupModalOpen] =
    useState(false);
  const [groupForModals, setGroupForModals] = useState<
    GroupsResponse | undefined
  >(undefined);
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);
  const [isMoveGroupModalOpen, setIsMoveGroupModalOpen] = useState(false);
  const [isGroupVisibilityModalOpen, setIsGroupVisibilityModalOpen] =
    useState(false);
  const [isViewGroupVisibilityModalOpen, setIsViewGroupVisibilityModalOpen] =
    useState(false);
  const [groupVisibilityFlow, setGroupVisibilityFlow] =
    useState<GroupVisibilityFlow | null>(null);
  const queryClient = useQueryClient();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const createGroupBtnHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  const onCreateFirstGroup = () => {
    setGroupForModals({
      id: '',
      name: '',
      parentId: null,
      memberCount: 0,
      subGroups: [],
    });
    setIsCreateEditGroupModalOpen(true);
  };

  const createFirstGroupBtn = () => {
    if (createGroupBtnHasPermission) {
      return (
        <Button variant="primary" className="mt-6" onClick={onCreateFirstGroup} testId='create-first-group-button'>
          <div className="flex items-center justify-center">
            <p className="px-2">Create your first group</p>
          </div>
        </Button>
      );
    }
  };

  if (query.isLoading || hubUsersQuery.isLoading) {
    return <Loader />;
  }

  const haveResults =
    !query.isLoading &&
    !hubUsersQuery.isLoading &&
    !query.isError &&
    !!query?.data?.length;
  const unassignedPersonCount = unassignedGroupQuery.data?.unassignedPersons;

  return (
    <div
      className={`relative h-full w-full snap-x overflow-auto overflow-x-auto py-2 md:contents md:w-full md:overflow-x-hidden md:overflow-y-hidden`}
    >
      {unassignedBannerVisible && !!unassignedPersonCount && haveResults && (
        <div className={'w-full pb-6 xl:w-[700px]'}>
          <Banner
            title={`${unassignedPersonCount} ${
              unassignedPersonCount && unassignedPersonCount > 1
                ? 'people aren’t'
                : 'person isn’t'
            } in any groups`}
            message="Add people to groups to enable better insights, analytics and reporting."
            closeable={true}
            onClose={() => setUnassignedBannerVisible(false)}
            actionButtons={
              <Button
                testId='view-unassigned-people-button'
                size="medium"
                variant="dark"
                className="ml-5 mt-2"
                onClick={() =>
                  navigate('/accounts/groups/unassigned-people')
                }
              >
                View unassigned people
                <ArrowRightIcon
                  classNames="ml-2"
                  size="3"
                  color="text-primaryBrandColor-150"
                />
              </Button>
            }
          />
        </div>
      )}

      {haveResults && (
        <>
          <TreeView
            data={query.data || []}
            hubUsersQuery={hubUsersQuery.data || []}
            setIsDeleteGroupModalOpen={setIsDeleteGroupModalOpen}
            setGroupForModals={setGroupForModals}
            setIsCreateEditGroupModalOpen={setIsCreateEditGroupModalOpen}
            setIsMoveGroupModalOpen={setIsMoveGroupModalOpen}
            setIsGroupVisibilityModalOpen={setIsGroupVisibilityModalOpen}
            setIsViewGroupVisibilityModalOpen={
              setIsViewGroupVisibilityModalOpen
            }
            setGroupVisibilityFlow={setGroupVisibilityFlow}
          />
          {createGroupBtnHasPermission && (
            <Button
             testId='add-group-button'
              variant="primary"
              onClick={onCreateFirstGroup}
              className="mb-28 w-full md:w-auto"
            >
              <div className="flex items-center justify-center">
                <PlusIcon /> <p className="px-2">Add a group</p>
              </div>
            </Button>
          )}
        </>
      )}

      {!haveResults && (
        <NoResults
          headerTxt="You haven't created any groups yet."
          contentTxt="Create groups that represent the structure of your business and assign people to them to get better reports and insights."
          actionButtons={createFirstGroupBtn()}
        />
      )}

      <CreateEditGroupModal
        group={groupForModals}
        groupsData={query.data || []}
        isModalOpen={isCreateEditGroupModalOpen}
        handleEditGroupModalClose={() => {
          setIsCreateEditGroupModalOpen(false);
          setGroupData({
            name: undefined,
            parentId: undefined,
            hubUsersIds: undefined,
          });
        }}
        handleSuccess={() => {
          setIsCreateEditGroupModalOpen(false);
          if (!groupForModals?.id) {
            setGroupVisibilityFlow(GroupVisibilityFlow.CreateGroup);
            setIsGroupVisibilityModalOpen(true);
          }
          queryClient.invalidateQueries('groups');
        }}
      />

      <DeleteGroupModal
        group={groupForModals}
        isModalOpen={isDeleteGroupModalOpen}
        handleModalClose={() => {
          setIsDeleteGroupModalOpen(false);
        }}
        handleSuccess={() => {
          setIsDeleteGroupModalOpen(false);
          queryClient.invalidateQueries('groups');
        }}
      />

      <GroupTreeModal
        group={groupForModals}
        isModalOpen={isMoveGroupModalOpen}
        handleGroupModalClose={() => {
          setIsMoveGroupModalOpen(false);
        }}
        handleSuccess={() => {
          setIsMoveGroupModalOpen(false);
          queryClient.invalidateQueries('groups');
        }}
      />

      <ManageGroupVisibilityModal
        group={groupForModals}
        flow={groupVisibilityFlow}
        isModalOpen={isGroupVisibilityModalOpen}
        handleModalClose={() => {
          setIsGroupVisibilityModalOpen(false);
          setGroupData({
            name: undefined,
            parentId: undefined,
            hubUsersIds: undefined,
          });
        }}
        handleSuccess={() => {
          setIsGroupVisibilityModalOpen(false);
          queryClient.invalidateQueries('groups');
        }}
      />

      <ViewGroupVisibilityModal
        group={groupForModals}
        usersData={hubUsersQuery.data?.items || []}
        isModalOpen={isViewGroupVisibilityModalOpen}
        handleModalClose={() => {
          setIsViewGroupVisibilityModalOpen(false);
        }}
        handleSuccess={() => {
          setIsViewGroupVisibilityModalOpen(false);
          setGroupVisibilityFlow(GroupVisibilityFlow.ManageVisibility);
          setIsGroupVisibilityModalOpen(true);
        }}
      />
    </div>
  );
};

export default GroupPage;
