import { Card } from '@components/partials';
import { Pagination } from '@components/partials/Pagination';
import { Button, Checkbox, Column } from '@components/ui';
import { Person } from '@hooks/people/types';
import useTableStore from '@store/tableStore';
import { UseQueryResult } from 'react-query';
import styles from './styles.module.css';
import { DropdownData } from '@components/ui/Dropdown';

type MobileTableViewProps<T extends object> = {
  showCheckboxes: boolean;
  showBulkAction: boolean;
  bulkContent?: React.ReactElement;
  currentPage: number;
  totalPages: number;
  setPageNumber: (pageNumber: number) => void;
  query: UseQueryResult<any>;
  data: any[];
  headerKeyCard?: string;
  isCollapsable?: boolean;
  sliceColumns?: number;
  handleSelect: (selected: Person | null, all?: boolean) => void;
  handleBulkAction?: () => void;
  setDropDownTableRow?: (id: string) => void;
  setDropDownAction?: React.Dispatch<React.SetStateAction<string | undefined>>;
  dropDownData?: DropdownData[];
  showDropDownMobile?: boolean;
  columns: Column<T>[];
};

export const MobileTableView = <T extends object>({
  showCheckboxes,
  showBulkAction,
  bulkContent,
  currentPage,
  totalPages,
  setPageNumber,
  query,
  data,
  headerKeyCard,
  isCollapsable,
  sliceColumns,
  handleSelect,
  handleBulkAction,
  setDropDownTableRow,
  setDropDownAction,
  dropDownData,
  showDropDownMobile,
  columns,
}: MobileTableViewProps<T>) => {
  const {
    newlySelectedItems,
    selectedCountPerPage,
    totalItemsPerPage,
    allSelectedItems,
  } = useTableStore();
  const rawData = (query && query.data?.items) || [];

  return (
    <div className={styles.cardWrapper}>
      {data?.map((item: any, index: number) => (
        <Card
          headerKey={headerKeyCard}
          classNames="mb-4"
          columns={columns}
          item={{
            ...item,
          }}
          rawItem={rawData[index]}
          key={`card${index}`}
          isCollapsable={isCollapsable}
          sliceColumns={sliceColumns}
          allSelectedItems={allSelectedItems}
          handleSelect={handleSelect}
          showCheckboxes={showCheckboxes}
          setDropDownTableRow={setDropDownTableRow}
          setDropDownAction={setDropDownAction}
          dropDownData={dropDownData}
          showDropDownMobile={showDropDownMobile}
        />
      ))}
      {showCheckboxes && (
        <div className={styles.showCheckboxesWrapper}>
          <Checkbox
            defaultClassName={
              selectedCountPerPage[currentPage] &&
              selectedCountPerPage[currentPage] !==
                totalItemsPerPage[currentPage]
                ? 'ccCheckboxDefaultSelected'
                : ''
            }
            checked={
              selectedCountPerPage[currentPage] &&
              selectedCountPerPage[currentPage] ===
                totalItemsPerPage[currentPage]
                ? true
                : false
            }
            name={'all'}
            onChange={() => handleSelect(null, true)}
          />
          {newlySelectedItems.length > 0 && showBulkAction && (
            <Button
              testId='bulk-action-button'
              variant="outlineLight"
              onClick={handleBulkAction}
              size="small"
            >
              {bulkContent ? bulkContent : 'Bulk Action'}
            </Button>
          )}
        </div>
      )}

      {!query.isLoading && (
        <div className={styles.paginationWrapper}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setPageNumber={setPageNumber}
          />
        </div>
      )}
    </div>
  );
};
