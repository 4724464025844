import React from 'react';
import styles from '.././styles.module.css';
import { KeyRecommendationsBox } from '../components/KeyRecommendationBox';
import { useSecurityReportPreviewQuery } from '@hooks/reports';
import { useLocation, useParams } from 'react-router-dom';
import { SecurityReportKeyRecommendation } from '@hooks/reports/types';
import { Modal } from '@components/ui';
import useModalStore from '@store/modal';

export const ReportKeyRecommendationsPage: React.FC = () => {

    const { reportId } = useParams<{ reportId: string }>();
    const location = useLocation();
    const selectedTab = location.pathname.split('/').pop() || 'key-recommendations';
    const {
        setIsDetailsModalOpen,
        setDataDetailsModal,
        isDetailsModalOpen,
        dataDetailsModal,
    } = useModalStore();

    const query = useSecurityReportPreviewQuery<SecurityReportKeyRecommendation>({ reportId, selectedTab });
    const items = query?.data?.items || [];

    return (
        <>
            <div className='flex flex-col items-start mt-[20px] gap-4'>
                <div className='text-24 md:text-28'><p>Key Recommendations</p> </div>
                <div className='text-16 md:text-18'>
                    <p>
                        {items.length ? `If you don't do anything else this month, change these things to keep your organisation safe and secure.` :
                            `When this report was generated, there are no recommendations found.`}
                    </p>
                </div>
            </div>
            <div className={styles.pageGrid}>
                {items.map((item) => {
                    return (
                        <KeyRecommendationsBox
                            key={item.recommendationName}
                            recommendationName={item.recommendationName}
                            recommendationSeverity={item.recommendationSeverity}
                            analystNote={item.analystNote}
                            description={item.description}
                            affectedService={item.affectedService}
                            uKCyberEssentialsReference={item.uKCyberEssentialsReference}
                            relevantAccounts={item.relevantAccounts}
                        />
                    );
                })}
            </div>
            <Modal
                testId='key-recommendations-modal'
                headerTxt={dataDetailsModal.header}
                isOpen={isDetailsModalOpen}
                size={'large'}
                showCloseBtn={true}
                onClose={() => {
                    setIsDetailsModalOpen(false);
                    setDataDetailsModal({ body: null, header: null });
                }}
                contentClassName="bg-white"
                actionButtons={<></>}
            >
                <div className="w-full">{dataDetailsModal.body}</div>
            </Modal>
        </>
    );
};
