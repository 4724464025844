import React from 'react';
import styles from './styles.module.css';
import { WarningCircleIcon } from '@components/ui';
import cn from 'classnames';
import useModalStore from '@store/modal';

type Props = {
  recommendationName: string;
  recommendationSeverity: string;
  analystNote: string;
  description: string;
  affectedService: string;
  uKCyberEssentialsReference: string;
  relevantAccounts: string;
};

export const KeyRecommendationsBox: React.FC<Props> = ({
  recommendationName,
  recommendationSeverity,
  analystNote,
  description,
  affectedService,
  uKCyberEssentialsReference,
  relevantAccounts,
}) => {
  const modalStore = useModalStore.getState();  
  const getIconClassByVariant = (severity: string) => {
    switch (severity) {
      case 'High':
        return 'bg-red-100';
      default:
        return 'bg-interfaceColor-100';
    }
  };

  const handleOnClick = () => {
    modalStore.setIsDetailsModalOpen(true);
    modalStore.setDataDetailsModal({
      header: (
        <div className="flex flex-col">
          <div className={cn(
            'flex h-[32px] w-fit items-center px-[14px] py-0 text-16 leading-[32px]',
            `permission-level-${recommendationSeverity}`
          )}>
            <span>{recommendationSeverity} impact</span>
          </div>
        </div>
      ),
      body: (
        <div className="mt-[-20px]">
          <div className="flex w-full pb-3">
            <span className="text-36 text-interfaceColor-100">
              {recommendationName}
            </span>
          </div>
          <div className="flex flex-row flex-wrap items-center gap-x-4 gap-y-0">
            {uKCyberEssentialsReference && (
              <div>
                <span className="text-16 font-semibold text-interfaceColor-150 mr-2">
                  Cyber essentials reference:
                </span>
                <span className="text-16 text-interfaceColor-150">
                {uKCyberEssentialsReference}
                </span>
              </div>
            )}
            {description && (
              <span className="text-16 text-interfaceColor-100">
                {description}
              </span>
            )}
          </div>

          {affectedService && (
            <div className="flex flex-col">
              <span className="mb-3 mt-2 text-20 text-interfaceColor-100">
                Affected service
              </span>
              <div className="flex max-h-72 flex-row flex-wrap items-center gap-2 overflow-y-auto">
                {affectedService?.split(';').map((item) => (
                  <span
                    key={item}
                    className="bg-interfaceColor-15 px-3 py-2 text-16"
                  >
                    {item}
                  </span>
                ))}
              </div>
            </div>
          )}

          {relevantAccounts && (
            <div className="flex flex-col">
              <span className="mb-3 mt-2 text-20 text-interfaceColor-100">
                Affected accounts
              </span>
              <div className="flex max-h-72 flex-row flex-wrap items-center gap-2 overflow-y-auto">
                {relevantAccounts?.split(';').map((item) => (
                  <span
                    key={item}
                    className="bg-interfaceColor-15 px-3 py-2 text-16"
                  >
                    {item}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      ),
    });
  };

  return (
    <div className={styles.box}>
      <div className={cn(styles.severity, styles[recommendationSeverity])}>
        <span>
          <WarningCircleIcon
            classNames={styles.icons}
            color={getIconClassByVariant(recommendationSeverity)}
          />
        </span>
        {recommendationSeverity} impact
      </div>
      
      <div className={styles.name}>
        <span>{recommendationName}</span>
      </div>

      <div className={styles.buttonWrapper}>
        <button
          onClick={handleOnClick}
          type="button"
          className="inline-flex h-9 w-[105px] flex-shrink-0 items-center justify-center rounded-[24px] bg-interfaceColor-90
                     px-4 py-2 text-16 font-normal leading-6 tracking-[0.01em] text-white transition duration-300 ease-in-out
                      hover:bg-interfaceColor-150 hover:text-interfaceColor-10 focus:shadow-focus md:h-10 md:w-auto lg:w-auto"
        >
          <p>More info</p>
        </button>
      </div>
    </div>
  );
};